import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import ReactDOM from "react-dom";
import styled, { css } from "styled-components";
import { Icon } from "@iconify/react";

const CustomSelectContainer = styled.div`
  position: relative;
  width: 100%;
`;

const CustomSelect = styled.div`
  font-weight: 500;
  color: #515c70;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: ${({ radiusborder }) => (radiusborder ? radiusborder : "5px")};
  padding: 10px;
  padding-right: 30px;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  height: 38px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  background-color: ${({ disabled }) => (disabled ? "#e8ecee" : "#fff")};

  &:focus {
    outline: none;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `}
`;

const ArrowIcon = styled(Icon)`
  font-size: 30px;
  position: absolute;
  right: 5px;
`;

const OptionsContainer = styled.div`
  font-size: 15px;
  position: absolute;
  left: 0;
  width: 100%;
  max-height: 278px;
  overflow-y: ${({ hasScroll }) => (hasScroll ? "auto" : "hidden")};
  overflow-x: hidden;
  background-color: #fff;
  box-shadow: 0px 3px 5px #bebebe;
  border-radius: 5px;
  white-space: nowrap;
  z-index: 9999;

  ::-webkit-scrollbar {
    width: 2px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 2px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const Option = styled.div`
  color: #5f5f5f;
  padding: 5px;
  cursor: pointer;

  &:hover,
  &.selected {
    background-color: #e7e7e7;
  }
`;

const CustomSelectComponent = forwardRef(
  (
    {
      options = [
        { label: "ExampleValue1", value: "ExampleValue1" },
        { label: "ExampleValue2", value: "ExampleValue2" },
      ],
      onSelect,
      placeholder,
      value = null,
      height,
      hasScroll = true,
      onKeyDown,
      radiusborder,
      disabled = false,
      holdSelect = false,
      handleOptionButton,
      autoChange = null,
      ...props
    },
    ref
  ) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedOptionIndex, setSelectedOptionIndex] = useState(0);
    const [optionChangedByMouse, setOptionChangedByMouse] = useState(false);

    const selectContainerRef = useRef(null);
    const selectRef = useRef(null);
    const optionsContainerRef = useRef(null);

    useImperativeHandle(ref, () => ({
      focus: () => {
        if (!disabled) {
          selectRef.current.focus();
        }
      },
    }));

    useEffect(() => {
      const handleArrowKeys = (event) => {
        if (!disabled) {
          setOptionChangedByMouse(false);
          if (event.key === "ArrowDown") {
            event.preventDefault();
            setSelectedOptionIndex((prevIndex) =>
              prevIndex < options.length - 1 ? prevIndex + 1 : prevIndex
            );
          } else if (event.key === "ArrowUp") {
            event.preventDefault();
            setSelectedOptionIndex((prevIndex) =>
              prevIndex > 0 ? prevIndex - 1 : prevIndex
            );
          }
        }
      };

      if (isOpen) {
        document.addEventListener("keydown", handleArrowKeys);
      }

      return () => {
        document.removeEventListener("keydown", handleArrowKeys);
      };
    }, [isOpen, options, disabled]);

    useEffect(() => {
      if (options.value !== null) {
        const foundOption =
          options && options.find((option) => option.value === value);
        if (foundOption) {
          setSelectedOption(foundOption);
        }
      }
    }, [options, value]);

    useEffect(() => {
      if (autoChange) {
        setSelectedOption(autoChange);
      }
    }, [autoChange]);

    const toggleOptions = () => {
      if (!disabled) {
        setIsOpen(!isOpen);
        /*
        if (!isOpen && !holdSelect) {
          setSelectedOptionIndex(0);
        }
        */
      }
    };

    const handleSelectOption = (option) => {
      if (!disabled) {
        setSelectedOption(option);
        setIsOpen(false);
        if (onSelect) {
          onSelect(option);
        } else {
          console.log(option);
        }
      }
    };

    const handleClickOutside = (event) => {
      if (
        selectContainerRef.current &&
        !selectContainerRef.current.contains(event.target) &&
        optionsContainerRef.current &&
        !optionsContainerRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    useEffect(() => {
      const handleArrowKeys = (event) => {
        if (!disabled) {
          setOptionChangedByMouse(false);
          if (event.key === "ArrowDown") {
            setSelectedOptionIndex((prevIndex) =>
              prevIndex < options.length - 1 ? prevIndex + 1 : prevIndex
            );
          } else if (event.key === "ArrowUp") {
            setSelectedOptionIndex((prevIndex) =>
              prevIndex > 0 ? prevIndex - 1 : prevIndex
            );
          }
        }
      };

      if (isOpen) {
        document.addEventListener("keydown", handleArrowKeys);
      }

      return () => {
        document.removeEventListener("keydown", handleArrowKeys);
      };
    }, [isOpen, options, disabled]);

    useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);

    useEffect(() => {
      if (isOpen && !optionChangedByMouse) {
        const optionElement = document.querySelector(
          `#option-${selectedOptionIndex}`
        );
        if (optionElement) {
          const container = optionsContainerRef.current;
          const scrollOffset = optionElement.offsetTop - container.offsetTop;
          container.scrollTop = scrollOffset;
        }
      }
    }, [isOpen, selectedOptionIndex, optionChangedByMouse]);

    const handleFocus = () => {
      if (!disabled) {
        setIsOpen(true);
        if (!holdSelect) {
          setSelectedOptionIndex(0);
        }
      }
    };

    const handleKeyDownInternal = (event) => {
      if (!disabled) {
        if (event.key === "Enter") {
          event.preventDefault();
          if (options[0].value === null) {
            handleSelectOption(options[selectedOptionIndex + 1]);
          } else {
            handleSelectOption(options[selectedOptionIndex]);
          }
        }
        if (onKeyDown) {
          onKeyDown(event);
        }
      }
    };

    return (
      <CustomSelectContainer ref={selectContainerRef}>
        <CustomSelect
          ref={selectRef}
          onMouseDown={toggleOptions}
          onFocus={handleFocus}
          onBlur={() => {
            if (!optionChangedByMouse) {
              setIsOpen(false);
            }
          }}
          onKeyDown={handleKeyDownInternal}
          style={{ height: height, fontSize: "15px" }}
          tabIndex={0}
          radiusborder={radiusborder}
          disabled={disabled}
        >
          {selectedOption && selectedOption !== null
            ? selectedOption.label
            : placeholder}
          <ArrowIcon icon="iconamoon:arrow-down-2-light" />
        </CustomSelect>
        {isOpen &&
          !disabled &&
          ReactDOM.createPortal(
            <OptionsContainer
              id="options-container"
              hasScroll={hasScroll}
              ref={optionsContainerRef}
              style={{
                top: selectRef.current
                  ? selectRef.current.getBoundingClientRect().bottom +
                    window.scrollY
                  : 0,
                left: selectRef.current
                  ? selectRef.current.getBoundingClientRect().left +
                    window.scrollX
                  : 0,
                width: selectRef.current
                  ? selectRef.current.getBoundingClientRect().width
                  : "100%",
              }}
            >
              {options
                .filter((option) => option.value !== null)
                .map((option, index) => (
                  <Option
                    id={`option-${index}`}
                    title={option.label}
                    key={option.value}
                    onMouseMove={() => {
                      setSelectedOptionIndex(index);
                      setOptionChangedByMouse(true);
                    }}
                    className={index === selectedOptionIndex ? "selected" : ""}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span
                        style={{ width: "100%" }}
                        onClick={() => handleSelectOption(option)}
                      >
                        {option.label}
                      </span>
                      {handleOptionButton && (
                        <button
                          style={{
                            backgroundColor: "transparent",
                            border: "none",
                          }}
                          onClick={() => handleOptionButton(option.value)}
                        >
                          <Icon
                            icon="ic:baseline-delete"
                            color="#E79900"
                            height="22"
                          />
                        </button>
                      )}
                    </div>
                  </Option>
                ))}
            </OptionsContainer>,
            document.body
          )}
      </CustomSelectContainer>
    );
  }
);

export default CustomSelectComponent;
