import React from "react";
import { Page, View, Text } from "@react-pdf/renderer";

import { stylesPdf } from "../../../../../components/ComponenteDeRelatorio/Pdf/StyledPdfRelatorio";
import { PdfHeader } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfHeader";
import { PdfColunas } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfColunas";
import { PdfInfos } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfInfos";
import { PdfRodape } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfRodape";
import { FormatValueToLocaleString } from "../../../../../utils/FormatacaoDeDados/FormatValueToLocaleString";

export const RenderPdfEsfera = (
  dados,
  opcoes,
  currentClient,
  userAuthData,
  dataClientInfos,
  currentYear,
  customData,
  despesaPdf = false
) => {
  console.log(dados);

  const optionEsfera = opcoes.find(
    (option) => option.opcao === "Natureza por Esfera"
  );

  // const codigosSectionRelatorio = ["1.0.0.0.00.0.0", "2.0.0.0.00.0.0"];
  const codigoSectionRelatorio = "3.0.00.00.00";
  const colNames = ["código", "especificações", "fiscal", "seguridade"];
  const styledCol = [
    { flexBasis: "130px", flexShrink: 1, flexGrow: 1, textAlign: "center" },
    { flexBasis: "100%", flexShrink: 1, flexGrow: 1, textAlign: "center" },
    {
      flexBasis: "160px",
      flexShrink: 1,
      flexGrow: 1,
      textAlign: "right",
      paddingRight: 2,
    },
    {
      flexBasis: "160px",
      flexShrink: 1,
      flexGrow: 1,
      textAlign: "right",
    },
  ];

  const normalizeString = (str) => {
    return str.endsWith(".") ? str.slice(0, -1) : str;
  };

  const renderReceitas = (natureza, key) => {
    // const naturezaSection = codigosSectionRelatorio.includes(natureza.codigo);
    // const fonteRecurso = natureza.codigo < codigosSectionRelatorio[0].length;

    const seccoes = natureza.codigo
      .split(".")
      .filter((secao) => parseInt(secao) !== 0);

    const fonteRecursoStyleBold =
      natureza.codigo < codigoSectionRelatorio.length;
    const despesaStyleBold = seccoes.length === 1;

    return (
      <React.Fragment key={key}>
        <View
          style={[
            stylesPdf.colunas,
            {
              gap: 0,
              backgroundColor: despesaStyleBold ? "#a6a6a6" : "transparent",
              fontWeight:
                despesaStyleBold | fonteRecursoStyleBold ? "bold" : "normal",
            },
          ]}
        >
          {optionEsfera?.checkBoxes[0].selected && (
            <PdfInfos
              pdfInfo={natureza.codigo || ""}
              styledCol={[
                styledCol[0],
                {
                  padding: "2px 0",
                  textAlign: "right",
                },
              ]}
            />
          )}
          {optionEsfera?.checkBoxes[1].selected && (
            <PdfInfos
              pdfInfo={normalizeString(natureza.especificacao) || ""}
              styledCol={[
                styledCol[1],
                { padding: "2px 0", textAlign: "left" },
              ]}
            />
          )}
          {optionEsfera?.checkBoxes[2].selected && (
            <PdfInfos
              pdfInfo={
                natureza.fiscal !== null && natureza.fiscal
                  ? FormatValueToLocaleString(parseFloat(natureza.fiscal))
                  : ""
              }
              styledCol={[
                styledCol[2],
                {
                  padding: "2px 0",
                  textAlign: "right",
                },
              ]}
            />
          )}
          {optionEsfera?.checkBoxes[3].selected && (
            <PdfInfos
              pdfInfo={
                natureza.seguridade !== null && natureza.seguridade
                  ? FormatValueToLocaleString(parseFloat(natureza.seguridade))
                  : ""
              }
              styledCol={[
                styledCol[3],
                {
                  padding: "2px 0",
                  textAlign: "right",
                },
              ]}
            />
          )}
        </View>
      </React.Fragment>
    );
  };

  const nameRelatorio = despesaPdf
    ? "Natureza da Despesa por Esfera"
    : "Natureza da Receita por Esfera";
  const nameAnexo = despesaPdf ? "Anexo II" : "vazio";

  return (
    <Page orientation="portrait" size={"A4"} style={stylesPdf.page}>
      <PdfHeader
        currentClient={currentClient}
        plusText={nameAnexo}
        nameRelatorio={nameRelatorio}
        anoRelatorio={currentYear}
        dataClientInfos={dataClientInfos}
        customData={customData}
      />
      <View
        fixed
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          fontWeight: "bold",
          fontSize: "9px",
          padding: "0 4px",
        }}
      >
        <Text>Lei Federal 4.320/64</Text>
        <Text>R$ 1,00</Text>
      </View>
      <View style={[stylesPdf.colunas]} fixed>
        {optionEsfera?.checkBoxes?.map(
          (each, index) =>
            each.selected && (
              <PdfColunas
                key={index}
                colName={colNames[index].toUpperCase()}
                styledCol={styledCol[index]}
                hideBar
              />
            )
        )}
      </View>
      <View style={stylesPdf.divRelatorio}>
        <View style={{ margin: "0 -3px" }}>
          {dados.data.naturezas.map((natureza, naturezaIndex) =>
            renderReceitas(natureza, naturezaIndex)
          )}
          <View style={{ marginVertical: 5 }} />
          {!despesaPdf && dados.data.naturezasDeducao.length > 0 && (
            <>
              <View
                wrap={false}
                style={[
                  stylesPdf.barTitleInfos,
                  {
                    gap: 0,
                    marginBottom: 10,
                    fontSize: 9,
                    backgroundColor: "#a6a6a6",
                    justifyContent: "space-between",
                    paddingVertical: 2,
                  },
                ]}
              >
                <View>
                  <Text style={[stylesPdf.textInfosRelatorio]}>
                    Contribuição para Formação do FUNDEB - DEDUÇÃO
                  </Text>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    flex: 1,
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <Text
                    style={[
                      stylesPdf.textInfosRelatorio,
                      {
                        color: "#000",
                        paddingRight: 8,
                      },
                    ]}
                  >
                    {dados.totalReceitasDeducao.fiscal
                      ? FormatValueToLocaleString(
                          dados.totalReceitasDeducao.fiscal
                        )
                      : ""}
                  </Text>
                  <Text
                    style={[
                      stylesPdf.textInfosRelatorio,
                      {
                        color: "#000",
                        paddingRight: 8,
                        width: 90,
                        textAlign: "right",
                      },
                    ]}
                  >
                    {dados.totalReceitasDeducao.seguridade
                      ? FormatValueToLocaleString(
                          dados.totalReceitasDeducao.seguridade
                        )
                      : ""}
                  </Text>
                </View>
              </View>
              {dados.data.naturezasDeducao.map((natureza, indexNatDeducao) =>
                renderReceitas(natureza, indexNatDeducao)
              )}
              <View style={{ marginVertical: 5 }} />
            </>
          )}

          <View
            wrap={false}
            style={[
              stylesPdf.divInferiorColuna,
              {
                fontWeight: "bold",
                fontSize: 10,
                textTransform: "uppercase",
                color: "white",
                flexDirection: "row",
                justifyContent: "space-between",
              },
            ]}
          >
            <Text>TOTAL</Text>
            <View style={{ flexDirection: "row" }}>
              <Text>
                {dados.totalGeralReceitaEsfera.fiscal
                  ? FormatValueToLocaleString(
                      dados.totalGeralReceitaEsfera.fiscal
                    )
                  : ""}
              </Text>
              <Text
                style={{
                  width: "90px",
                  textAlign: "right",
                }}
              >
                {dados.totalGeralReceitaEsfera.seguridade
                  ? FormatValueToLocaleString(
                      dados.totalGeralReceitaEsfera.seguridade
                    )
                  : ""}
              </Text>
            </View>
          </View>
        </View>
      </View>
      <PdfRodape currentClient={currentClient} userAuthData={userAuthData} />
    </Page>
  );
};
