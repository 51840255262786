import React from "react";
import { Page, View, Text } from "@react-pdf/renderer";

import { stylesPdf } from "../../../../../components/ComponenteDeRelatorio/Pdf/StyledPdfRelatorio";
import { PdfHeader } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfHeader";
import { PdfColunas } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfColunas";
import { PdfInfos } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfInfos";
import { PdfRodape } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfRodape";
import { FormatValueToLocaleString } from "../../../../../utils/FormatacaoDeDados/FormatValueToLocaleString";

export const RenderPdfDespesaOrgaoUnidade = (
  dados,
  opcoes,
  currentClient,
  userAuthData,
  dataClientInfos,
  currentYear,
  customData
) => {
  console.log(dados);
  console.log(opcoes);

  // const colNames = [
  //   "Código",
  //   "Especificações",
  //   "Desdobramento",
  //   "Grupo da Natureza",
  //   "Categoria Econômica",
  //   "totais",
  // ];
  const styledCol = [
    { flexBasis: "180px", flexShrink: 1, flexGrow: 1 },
    { flexBasis: "100%", flexShrink: 1, flexGrow: 1 },
    {
      flexBasis: "180px",
      flexShrink: 1,
      flexGrow: 1,
      textAlign: "center",
    },
    {
      flexBasis: "180px",
      flexShrink: 1,
      flexGrow: 1,
      textAlign: "center",
    },
    {
      flexBasis: "180px",
      flexShrink: 1,
      flexGrow: 1,
      textAlign: "center",
    },
    {
      flexBasis: "180px",
      flexShrink: 1,
      flexGrow: 1,
      textAlign: "right",
    },
  ];

  const optionDespesaClassificacao = opcoes.find(
    (option) => option.opcao === "Programa de Trabalho"
  );

  const renderReceitas = (
    codigo,
    titulo,
    unidadeDespesas,
    styledBold = false
  ) => {
    return (
      <View
        style={[
          stylesPdf.colunas,
          {
            gap: 0,
            fontWeight: styledBold ? "bold" : "normal",
            margin: "0 -3px",
          },
        ]}
      >
        {optionDespesaClassificacao?.checkBoxes[0].selected && (
          <PdfInfos
            pdfInfo={codigo}
            styledCol={[
              styledCol[0],
              {
                padding: "2px 0",
              },
            ]}
          />
        )}
        {optionDespesaClassificacao?.checkBoxes[1].selected && (
          <PdfInfos
            pdfInfo={titulo}
            styledCol={[styledCol[1], { padding: "2px 0" }]}
          />
        )}

        {optionDespesaClassificacao?.checkBoxes[2].selected && (
          <PdfInfos
            pdfInfo={
              unidadeDespesas.totalEspeciais
                ? FormatValueToLocaleString(unidadeDespesas.totalEspeciais)
                : ""
            }
            styledCol={[
              styledCol[2],
              {
                padding: "2px 0",
                textAlign: "right",
              },
            ]}
          />
        )}
        {optionDespesaClassificacao?.checkBoxes[3].selected && (
          <PdfInfos
            pdfInfo={
              unidadeDespesas.totalProjetos
                ? FormatValueToLocaleString(unidadeDespesas.totalProjetos)
                : ""
            }
            styledCol={[
              styledCol[3],
              {
                padding: "2px 0",
                textAlign: "right",
              },
            ]}
          />
        )}
        {optionDespesaClassificacao?.checkBoxes[4].selected && (
          <PdfInfos
            pdfInfo={
              unidadeDespesas.totalAtividades
                ? FormatValueToLocaleString(unidadeDespesas.totalAtividades)
                : ""
            }
            styledCol={[
              styledCol[4],
              {
                padding: "2px 0",
                textAlign: "right",
              },
            ]}
          />
        )}
        {optionDespesaClassificacao?.checkBoxes[5].selected && (
          <PdfInfos
            pdfInfo={
              unidadeDespesas.total
                ? FormatValueToLocaleString(unidadeDespesas.total)
                : ""
            }
            styledCol={[
              styledCol[5],
              {
                padding: "2px 0",
                textAlign: "right",
              },
            ]}
          />
        )}
      </View>
    );
  };

  const renderUnidade = (codigo, titulo, unidade = true) => {
    return (
      <View
        fixed
        style={[
          stylesPdf.colunas,
          {
            gap: 0,
            backgroundColor: unidade ? "#D0CECE" : "#a6a6a6",
            fontWeight: "bold",
            margin: "0 -3px",
          },
        ]}
      >
        {optionDespesaClassificacao?.checkBoxes[0].selected && (
          <PdfInfos
            pdfInfo={codigo}
            styledCol={[
              styledCol[0],
              {
                padding: "2px 0",
                textAlign: "right",
              },
            ]}
          />
        )}
        {optionDespesaClassificacao?.checkBoxes[1].selected && (
          <PdfInfos
            pdfInfo={titulo}
            styledCol={[
              styledCol[1],
              { padding: "2px 0", textTransform: "capitalize" },
            ]}
          />
        )}
        {optionDespesaClassificacao?.checkBoxes[2].selected && (
          <PdfInfos
            pdfInfo={""}
            styledCol={[
              styledCol[2],
              {
                padding: "2px 0",
                textAlign: "right",
              },
            ]}
          />
        )}
        {optionDespesaClassificacao?.checkBoxes[3].selected && (
          <PdfInfos
            pdfInfo={""}
            styledCol={[
              styledCol[3],
              {
                padding: "2px 0",
                textAlign: "center",
              },
            ]}
          />
        )}
        {optionDespesaClassificacao?.checkBoxes[4].selected && (
          <PdfInfos
            pdfInfo={""}
            styledCol={[
              styledCol[4],
              {
                padding: "2px 0",
                textAlign: "right",
              },
            ]}
          />
        )}
        {optionDespesaClassificacao?.checkBoxes[5].selected && (
          <PdfInfos
            pdfInfo={""}
            styledCol={[
              styledCol[4],
              {
                padding: "2px 0",
                textAlign: "right",
              },
            ]}
          />
        )}
      </View>
    );
  };

  const renderTotal = (total, customName = "TOTAL", jump = 10) => {
    console.log("TOTAAL: ", total);
    return (
      <>
        <View
          style={[
            stylesPdf.divInferiorColuna,
            {
              fontWeight: "bold",
              fontSize: 10,
              textTransform: "uppercase",
              color: "white",
              flexDirection: "row",
              justifyContent: "space-between",
              marginBottom: jump,
              margin: "0",
              paddingHorizontal: 0,
              paddingLeft: 10,
            },
          ]}
        >
          <Text>{customName}</Text>
          <View style={{ flexDirection: "row" }}>
            <Text
              style={{
                width: 70,
                textAlign: "right",
                paddingRight: 8,
              }}
            >
              {total.totalEspeciaisUnidade
                ? FormatValueToLocaleString(total.totalEspeciaisUnidade)
                : ""}
            </Text>
            <Text
              style={{
                width: 70,
                textAlign: "right",
                paddingRight: 8,
              }}
            >
              {total.totalProjetosUnidade
                ? FormatValueToLocaleString(total.totalProjetosUnidade)
                : ""}
            </Text>
            <Text
              style={{
                width: 70,
                textAlign: "right",
                paddingRight: 8,
              }}
            >
              {total.totalAtividadesUnidade
                ? FormatValueToLocaleString(total.totalAtividadesUnidade)
                : ""}
            </Text>
            <Text
              style={{
                width: 70,
                textAlign: "right",
                paddingRight: 8,
              }}
            >
              {total.totalDaUnidade
                ? FormatValueToLocaleString(total.totalDaUnidade)
                : ""}
            </Text>
          </View>
        </View>
        <View />
      </>
    );
  };

  return (
    <Page orientation="portrait" size={"A4"} style={[stylesPdf.page]}>
      <PdfHeader
        currentClient={currentClient}
        nameRelatorio={"Órgão e Unidade Orçamentária "}
        plusText={"Anexo VI"}
        nameProjeto={"Programa de Trabalho"}
        anoRelatorio={currentYear}
        dataClientInfos={dataClientInfos}
        customData={customData}
      />
      <View
        fixed
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          fontWeight: "bold",
          fontSize: "9px",
          padding: "0 4px",
        }}
      >
        <Text>Lei Federal 4.320/64</Text>
        <Text>R$ 1,00</Text>
      </View>
      <View style={[stylesPdf.colunas]} fixed>
        {optionDespesaClassificacao?.checkBoxes?.map((option, index) => (
          <PdfColunas
            key={index}
            colName={option.label.toUpperCase()}
            styledCol={styledCol[index]}
            hideBar
          />
        ))}
      </View>
      <React.Fragment>
        <React.Fragment>
          {dados.dataDespesa.map((unidade, unidadeIndex) => (
            <React.Fragment key={unidadeIndex}>
              {Object.keys(unidade.infoUnidade.unidades).map(
                (unidadeGestoraKey, indexUnidadeGestora) => {
                  const unidadeOrcamentaria =
                    unidade.infoUnidade.unidades[unidadeGestoraKey];
                  return (
                    <View
                      key={indexUnidadeGestora}
                      break={indexUnidadeGestora > 0}
                    >
                      {renderUnidade(
                        unidade.infoUnidade.idUnidade,
                        unidade.infoUnidade.unidadeGestora,
                        false
                      )}
                      {renderUnidade(
                        unidadeOrcamentaria.codigoUnidadeOrcamentaria,
                        unidadeOrcamentaria.tituloUnidadeOrcamentaria
                      )}
                      {Object.keys(unidadeOrcamentaria.relatorio).map(
                        (funcaoKey, indexFuncao) => {
                          const funcao =
                            unidadeOrcamentaria.relatorio[funcaoKey];
                          const codigoFuncao = funcao.codigoFuncao;
                          return (
                            <React.Fragment key={indexFuncao}>
                              {renderReceitas(
                                codigoFuncao,
                                funcao.tituloFuncao,
                                funcao
                              )}
                              {Object.keys(funcao.subFuncoes).map(
                                (subFuncaoKey, indexSubFuncao) => {
                                  const subFuncao =
                                    funcao.subFuncoes[subFuncaoKey];
                                  const codigoSubFuncao = `${codigoFuncao}.${subFuncao.codigoSubFuncao}`;
                                  return (
                                    <React.Fragment key={indexSubFuncao}>
                                      {renderReceitas(
                                        codigoSubFuncao,
                                        subFuncao.tituloSubFuncao,
                                        subFuncao
                                      )}
                                      {Object.keys(subFuncao.programas).map(
                                        (programaKey, indexPrograma) => {
                                          const programa =
                                            subFuncao.programas[programaKey];
                                          const codigoPrograma = `${codigoSubFuncao}.${programa.codigoPrograma}`;
                                          return (
                                            <React.Fragment key={indexPrograma}>
                                              {renderReceitas(
                                                codigoPrograma,
                                                programa.tituloPrograma,
                                                programa,
                                                true
                                              )}
                                            </React.Fragment>
                                          );
                                        }
                                      )}
                                    </React.Fragment>
                                  );
                                }
                              )}
                            </React.Fragment>
                          );
                        }
                      )}
                      {renderTotal(unidadeOrcamentaria, "SUBTOTAL")}
                    </View>
                  );
                }
              )}
              {renderTotal(unidade.infoUnidade.totalGeralUnidade, "TOTAL", 0)}
            </React.Fragment>
          ))}

          {dados.dataDespesa.length > 1 && renderTotal(dados.total)}
        </React.Fragment>
      </React.Fragment>
      <PdfRodape currentClient={currentClient} userAuthData={userAuthData} />
    </Page>
  );
};
