import api from "../../../../../../utils/api";

export const getDespesaDemonstrativo = async (idsEntidades, ano = 2024) => {
  const idsUrl = idsEntidades.ids.join(",");

  try {
    const response = await api.get(
      `qdd/relatorio/agregado/${idsUrl}/${ano}?porAcao=1`
    );

    return formattedAllDespesas(response.data);
  } catch (error) {
    console.error("Erro na requisição:", error);
    return {
      error: "error",
      name: "Anexo VII - Programa de Trabalho - Demonstrativo de Funções, Subfunções, Programas, Ações por Especiais, Projetos e Atividades",
    };
  }
};

const formattedAllDespesas = (dataQddRelatorioOrgaoUnidade) => {
  const dadosFormatados = Object.keys(dataQddRelatorioOrgaoUnidade).map(
    (key) => {
      const unidade = dataQddRelatorioOrgaoUnidade[key];
      return {
        infoUnidade: {
          idUnidade: unidade.clienteInfo[0].id,
          tipoNaturezaJuridica: unidade.clienteInfo[0].tipoNaturezaJuridica,
          unidadeGestora: unidade.clienteInfo[0].pessoaJuridica.nomeFantasia,
          totalGeralUnidade: {
            totalProjetosCliente: unidade.totalProjetosCliente,
            totalAtividadesCliente: unidade.totalAtividadesCliente,
            totalEspeciaisCliente: unidade.totalEspeciaisCliente,
          },
          unidades: unidade.relatorio,
        },
      };
    }
  );

  dadosFormatados.sort((a, b) => {
    const tipoA = parseInt(a.infoUnidade.tipoNaturezaJuridica[0]);
    const tipoB = parseInt(b.infoUnidade.tipoNaturezaJuridica[0]);
    return tipoA - tipoB;
  });

  return {
    dataDespesa: dadosFormatados,
    total: 0,
  };
};
