import api from "../../../../../../utils/api";
import { FormatValueToLocaleString } from "../../../../../../utils/FormatacaoDeDados/FormatValueToLocaleString";
import { getUrl } from "../getUrlConsolidado";

const calcularTotalUnidadeGestora = (data) => {
  let somaTotal = 0;

  data.forEach((cliente) => {
    somaTotal += cliente.infoCliente.totalUnidadeGestora;
  });
  return somaTotal;
};

const calcularSomaCategoriaEconomica = (data) => {
  let somaTotal = 0;

  data.forEach((unidade) => {
    unidade.infoUnidade.despesas.forEach((despesa) => {
      if (despesa.catEcon !== null && !isNaN(parseFloat(despesa.catEcon))) {
        somaTotal += parseFloat(despesa.catEcon);
      }
    });
  });

  return somaTotal;
};

const calcularTotalUnidade = (despesas) => {
  const totalUnidade = despesas.reduce((acc, despesa) => {
    const despesaScetion =
      despesa.codigo.split(".").filter((secao) => parseInt(secao) !== 0)
        .length === 1;
    if (!despesaScetion) return acc;

    return (acc += parseFloat(despesa.valor));
  }, 0);
  return totalUnidade;
};
const orderFonts = (naturezas) => {
  return naturezas.map((natureza) => {
    if (natureza.fontes && natureza.fontes.length > 0) {
      natureza.fontes.sort((a, b) => a.conta.localeCompare(b.conta));
    }
    return natureza;
  });
};

export const getDespesaCatEconOrgao = async (
  clientes,
  entidadesGestoras,
  ano = 2024
) => {
  // const entidadeGestora = entidadesGestorasDoCliente
  //   .flatMap((entidade) => entidade.checkBoxes)
  //   .filter((entidade) => idsEntidades.ids.includes(entidade.dados.clienteId));

  // const idsUrl = idsEntidades.ids.join(",");
  const url = getUrl(clientes, entidadesGestoras);
  try {
    const response = await api.get(
      `naturezaDaDespesa/relatorio/unidades/${url.idsUrl}/${ano}?${url.filterConsolidado}`
    );
    console.log("AAAAAAAAAAAAAH: ", response.data);

    return processAllData(response.data, entidadesGestoras);
    // return processAllData(response.data);
  } catch (error) {
    console.error("Erro na requisição:", error);
    return {
      error: "error",
      name: "Anexo II - Despesa Segundo as Categorias Econômicas - Órgão",
    };
  }
};

const processItem = (natureza) => {
  const seccoes = natureza.codigo
    .split(".")
    .filter((secao) => parseInt(secao) !== 0);

  const resultado = [
    {
      codigo: natureza.codigo,
      especificacao: natureza.nome,
      desdobramento: seccoes.length > 2 ? natureza.valor : null,
      grupoNatureza: seccoes.length === 2 ? natureza.valor : null,
      catEcon: seccoes.length < 2 ? natureza.valor : null,
    },
  ];

  if (natureza.fontes.length) {
    const recursos = natureza.fontes.map((recurso) => {
      return {
        codigo: recurso.conta,
        especificacao: recurso.tituloFonte,
        desdobramento: recurso.valor,
        grupoNatureza: null,
        catEcon: null,
      };
    });
    resultado.push(...recursos);
  }

  return resultado;
};

export const processAllData = (
  dataQddRelatorioCatEconOrgao,
  entidadesGestoras
) => {
  const unidades = Object.keys(
    dataQddRelatorioCatEconOrgao.contasAtualizadas?.relatorios || {}
  ).map((key) => {
    const unidadeData =
      dataQddRelatorioCatEconOrgao.contasAtualizadas.relatorios[key];

    return {
      infoUnidade: {
        id: unidadeData.unidadeInfo.unidadeGestoraId,
        codigo: unidadeData.unidadeInfo.codigo,
        especificacao: unidadeData.unidadeInfo.titulo,
        totalUnidade: calcularTotalUnidade(unidadeData.naturezasConsolidadas),
        despesas: orderFonts(unidadeData.naturezasConsolidadas).flatMap(
          (natureza) => processItem(natureza)
        ),
      },
    };
  });

  const dataUnidadesGestoras = entidadesGestoras[0].checkBoxes
    .map((entidade) => {
      const filterEntidades = unidades.filter(
        (data) =>
          entidade.dados.clienteId === data.infoUnidade.id &&
          data.infoUnidade.despesas.length
      );
      return {
        infoCliente: {
          codigo: entidade.dados.clienteId,
          titulo: entidade.dados.nomeFantasia,
          tipoNaturezaJuridica: entidade.dados.tipoNaturezaJuridica,
          totalUnidadeGestora: calcularSomaCategoriaEconomica(filterEntidades),
          unidades: filterEntidades,
        },
      };
    })
    .filter((entidade) => entidade.infoCliente.unidades.length);

  dataUnidadesGestoras.sort((a, b) => {
    const tipoA = parseInt(a.infoCliente.tipoNaturezaJuridica[0]);
    const tipoB = parseInt(b.infoCliente.tipoNaturezaJuridica[0]);
    return tipoA - tipoB;
  });

  dataUnidadesGestoras.forEach((cliente) => {
    cliente.infoCliente.unidades.sort((a, b) => {
      const codigoA = parseFloat(a.infoUnidade.codigo.replace(".", ""));
      const codigoB = parseFloat(b.infoUnidade.codigo.replace(".", ""));
      return codigoA - codigoB;
    });
  });

  const totalReceitas = calcularTotalUnidadeGestora(dataUnidadesGestoras);

  return {
    data: dataUnidadesGestoras,
    sum: FormatValueToLocaleString(totalReceitas),
  };
};
