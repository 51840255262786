import { Image, Text, View } from "@react-pdf/renderer";
import { stylesPdf } from "./StyledPdfRelatorio";
import defaultImg64 from "../../../utils/RelatorioDocx/defaultImg64";

export const PdfHeader = ({
  currentClient,
  nameRelatorio,
  anoRelatorio,
  dataClientInfos = null,
  plusText,
  customRelatorioType = "Lei Orçamentária Anual",
  customData,
  nameProjeto = "",
  ignoreConsolidacao = false,
}) => {
  return (
    <View
      style={[
        stylesPdf.divExternaCabecalhoRelatorio,
        { paddingBottom: plusText ? 0 : "4px" },
      ]}
      fixed
    >
      <View style={stylesPdf.divInternaCabecalho}>
        <View style={stylesPdf.divImageCabecalho}>
          <Image
            style={stylesPdf.img}
            src={
              dataClientInfos
                ? dataClientInfos[0]?.brasao || defaultImg64
                : defaultImg64
            }
          />
        </View>
        <View style={stylesPdf.divInfosCabecalho}>
          <View>
            <Text style={stylesPdf.titleCabecalho}>
              {/* {`MUNICÍPIO DE ${currentClient.endereco.municipio} - ${currentClient.endereco.estado}`} */}
              {customData?.name ? customData?.name : currentClient.nomeFantasia}
            </Text>
            <Text>
              {!plusText ? "Relatório De Verificação" : customRelatorioType}
              {customData?.consolidacao &&
                !ignoreConsolidacao &&
                " - Consolidação Geral"}
            </Text>
            {plusText && (
              <Text
                style={{
                  marginTop: "1.8px",
                  fontWeight: "bold",
                  color: plusText === "vazio" ? "#FFF" : "#000",
                }}
              >
                {plusText}
              </Text>
            )}
            {nameProjeto && <Text>{nameProjeto}</Text>}
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: plusText ? "space-between" : "flex-start",
                margin: `${plusText ? "1.2px" : "2px"} 4px ${
                  plusText ? "1px" : 0
                } 0`,
              }}
            >
              <Text>{nameRelatorio}</Text>
              {plusText && <Text>Anual {anoRelatorio}</Text>}
            </View>
          </View>
          {!plusText && (
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Text></Text>
              <Text>Anual {anoRelatorio}</Text>
            </View>
          )}
        </View>
      </View>
    </View>
  );
};
