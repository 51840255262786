// import React from "react";
// import { Page, View, Text } from "@react-pdf/renderer";
// import { stylesPdf } from "../../../../../components/ComponenteDeRelatorio/Pdf/StyledPdfRelatorio";
// import { PdfHeader } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfHeader";
// import { PdfColunas } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfColunas";
// import { PdfInfos } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfInfos";
// import { PdfRodape } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfRodape";
// import { formatValue } from "../utils/formatValue";

// export const RenderPdfQDDAplicacao = (
//   dados,
//   opcoes,
//   currentClient,
//   userAuthData,
//   dataClientInfos,
//   currentYear,
//   customData
// ) => {
//   const colNames = ["CÓDIGO", "ABC", "ESPECIFICAÇÕES", "ESFERA", "TOTAL"];
//   const styledCol = [
//     { flexBasis: "85px", flexShrink: 1, flexGrow: 1,textAlign: "center" },
//     { flexBasis: "70px", flexShrink: 1, flexGrow: 1,textAlign: 'right' },
//     { flexBasis: "90%", flexShrink: 1, flexGrow: 1,textAlign: "center" },
//     { flexBasis: "105px", flexShrink: 1, flexGrow: 1,textAlign: "center" },
//     { flexBasis: "140px", flexShrink: 1, flexGrow: 1,textAlign: "right" },
//   ];

//   const bolderRegex = [
//     /^\d{2}\.\d{3}$/, //'xx.xxx'
//     /^\d{4}$/, //'xxxx'
//     /^\d\.\d\.\d{2}$/, //'x.x.xx'
//   ];

//   const titleRegex = [
//     /^\d{2}\.\d{3}$/, //'xx.xxx'
//   ];

//   const regexTest = (str, regex) => {
//     return regex.some((regex) => regex.test(str));
//   };

//   const renderTotal = (textTotalType, total) => {
//     return (
//       <>
//         <View
//           wrap={false}
//           style={[
//             stylesPdf.divInferiorColuna,
//             {
//               fontWeight: "bold",
//               fontSize: 10,
//               textTransform: "uppercase",
//               color: "white",
//               flexDirection: "row",
//               justifyContent: "space-between",
//               marginBottom: 10,
//             },
//           ]}
//         >
//           <Text>{textTotalType}</Text>
//           <Text>{total}</Text>
//         </View>
//         <View break />
//       </>
//     );
//   };

//   return (
//     <Page orientation="portrait" size={"A4"} style={stylesPdf.page}>
//       <PdfHeader
//         currentClient={currentClient}
//         nameRelatorio={
//           "Quadro Detalhado da Despesa - QDD por Modalidade de Aplicação"
//         }
//         plusText={" "}
//         anoRelatorio={currentYear}
//         dataClientInfos={dataClientInfos}
//         customData={customData}
//       />
//        <View
//         fixed
//         style={{
//           display: "flex",
//           flexDirection: "row",
//           justifyContent: "space-between",
//           fontWeight: "bold",
//           fontSize: "9px",
//           padding: "0 4px",
//         }}
//       >
//         <Text>Lei Federal 4.320/64</Text>
//         <Text>R$ 1,00</Text>
//       </View>
//       <View style={stylesPdf.colunas} fixed>
//         {opcoes[1]?.checkBoxes?.map(
//           (each, index) =>
//             each.selected && (
//               <PdfColunas
//                 key={index}
//                 colName={colNames[index]}
//                 styledCol={styledCol[index]}
//                 hideBar
//               />
//             )
//         )}
//       </View>
//       <View style={stylesPdf.divRelatorio}>
//         <View style={{ margin: "0 -3px" }}>
//           {dados?.data?.map((item, index) => {
//             return (
//               <React.Fragment key={index}>
//                 <View
//                   style={[
//                     stylesPdf.colunas,
//                     {
//                       gap: 0,
//                       fontWeight: regexTest(item.codigo, bolderRegex)
//                         ? "bold"
//                         : "normal",
//                       backgroundColor: regexTest(item.codigo, titleRegex)
//                         ? "#a6a6a6"
//                         : "transparent",
//                     },
//                   ]}
//                 >
//                   {opcoes[1]?.checkBoxes[0].selected && item.codigo !== "0" && (
//                     <PdfInfos
//                       pdfInfo={item.serial || ""}
//                       styledCol={[
//                         styledCol[0],
//                         {
//                           padding: "2px 0",
//                           textAlign: "left",
//                           fontWeight: "normal",

//                         },
//                       ]}
//                     />
//                   )}
//                   {opcoes[1]?.checkBoxes[1].selected && (
//                     <PdfInfos
//                       pdfInfo={item.codigo || ""}
//                       styledCol={[
//                         styledCol[1],
//                         { padding: "2px 0", textAlign: "right" },
//                       ]}
//                     />
//                   )}
//                   {opcoes[1]?.checkBoxes[2].selected && (
//                     <PdfInfos
//                       pdfInfo={item.titulo || ""}
//                       styledCol={[
//                         styledCol[2],
//                         { padding: "2px 0", textAlign: "left" },
//                       ]}
//                     />
//                   )}
//                   {opcoes[1]?.checkBoxes[3].selected && (
//                     <PdfInfos
//                       pdfInfo={item.tipo || ""}
//                       styledCol={[styledCol[3], { padding: "2px 0",textAlign: "center"}]}
//                     />
//                   )}
//                   {opcoes[1]?.checkBoxes[4].selected && (
//                     <PdfInfos
//                       pdfInfo={
//                         (item.valor && `R$ ${formatValue(item.valor)}`) || ""
//                       }
//                       styledCol={[
//                         styledCol[4],
//                         { padding: "2px 0", textAlign: "right" },
//                       ]}
//                     />
//                   )}
//                 </View>
//                 {item?.unidade && renderTotal('subtotal', formatValue(item.valor))}
//               </React.Fragment>
//             );
//           })}
//           {/* <View
//             style={[
//               stylesPdf.divInferiorColuna,
//               {
//                 fontWeight: "bold",
//                 fontSize: 10,
//                 textTransform: "uppercase",
//                 color: "white",
//                 display: "flex",
//                 flexDirection: "row",
//                 justifyContent: "space-between",
//               },
//             ]}
//           >
//             <Text>TOTAL</Text>
//             <Text>{formatValue(dados.sum)}</Text>
//           </View> */}
//           {renderTotal('total geral', formatValue(dados.sum))}
//         </View>
//       </View>
//       <PdfRodape currentClient={currentClient} userAuthData={userAuthData} />
//     </Page>
//   );
// };

import React from "react";
import { Page, View, Text } from "@react-pdf/renderer";

import { stylesPdf } from "../../../../../components/ComponenteDeRelatorio/Pdf/StyledPdfRelatorio";
import { PdfHeader } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfHeader";
import { PdfColunas } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfColunas";
import { PdfRodape } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfRodape";

import { FormatValueToLocaleString } from "../../../../../utils/FormatacaoDeDados/FormatValueToLocaleString";

export const RenderPdfQDDAplicacao = (
  dados,
  opcoes,
  currentClient,
  userAuthData,
  dataClientInfos,
  currentYear,
  customData
) => {
  console.log(dados);

  const optionReceitaQdr = opcoes.find(
    (option) => option.opcao === "Quadro Detalhado da Despesa - QDD"
  );

  const colNames = ["código", "especificações", "esfera", "total"];
  const styledCol = [
    { flexBasis: "145px", flexShrink: 1, flexGrow: 1, textAlign: "center" },
    { flexBasis: "100%", flexShrink: 1, flexGrow: 1, textAlign: "center" },
    {
      flexBasis: "105px",
      flexShrink: 1,
      flexGrow: 1,
      textAlign: "center",
    },
    {
      flexBasis: "140px",
      flexShrink: 1,
      flexGrow: 1,
      textAlign: "right",
    },
  ];

  const formatText = (text) => {
    return text.replace(/\n/g, " ");
  };

  const renderInfoUnidadeOrcamentaria = (item) => {
    return (
      <View
        style={[
          stylesPdf.colunas,
          { fontWeight: "bold", backgroundColor: "#a6a6a6",marginHorizontal:-3 },
        ]}
        key={item.unidadeOrcamentariaId.id}
        fixed
      >
        <View style={[styledCol[0]]} wrap={false}>
          <Text
            style={[
              stylesPdf.textInfosRelatorio,
              {
                paddingRight: 0,
                paddingVertical: 2,
                textAlign: "left",
              },
            ]}
          >
            {item.codigoUnidadeOrcamentaria}
          </Text>
        </View>

        <View style={[styledCol[1]]} wrap={false}>
          <Text
            style={[
              stylesPdf.textInfosRelatorio,
              {
                paddingVertical: 2,
                textAlign: "left",
              },
            ]}
          >
            {item.tituloUnidadeOrcamentaria}
          </Text>
        </View>

        <View style={[styledCol[2]]} wrap={false}>
          <Text
            style={[
              stylesPdf.textInfosRelatorio,
              {
                paddingVertical: 2,
              },
            ]}
          >
            {`${""}`}
          </Text>
        </View>

        <View style={[styledCol[3]]} wrap={false}>
          <Text
            style={[
              stylesPdf.textInfosRelatorio,
              {
                textAlign: "right",
                paddingVertical: 2,
              },
            ]}
          >
            {`${FormatValueToLocaleString(
              parseFloat(item.totalAcoesDaUnidade)
            )}`}
          </Text>
        </View>
      </View>
    );
  };

  const renderInfoAcao = (item) => {
    return (
      <View
        style={[stylesPdf.colunas, { fontWeight: "bold" ,marginHorizontal:-3}]}
        key={`${item.acaoGovernamentalId}-${item.nAcao}`}
      >
        <View
          style={[
            styledCol[0],
            {
              flexDirection: "row",
              justifyContent: "space-between",
              textAlign: "left",
            },
          ]}
          wrap={false}
        >
          <Text
            style={[
              stylesPdf.textInfosRelatorio,
              {
                paddingRight: 0,
                paddingVertical: 2,
                fontWeight: "normal",
              },
            ]}
          >
            {`${item.funcao.valor}.${item.subFuncao.valor}.${item.programaPlanejamentoId.numeroPrograma}`}
          </Text>
          <Text
            style={[
              stylesPdf.textInfosRelatorio,
              {
                paddingRight: 0,
                paddingVertical: 2,
              },
            ]}
          >
            {item.nAcao}
          </Text>
        </View>
        <View style={[styledCol[1]]} wrap={false}>
          <Text
            style={[
              stylesPdf.textInfosRelatorio,
              {
                paddingVertical: 2,
                textAlign: "left",
              },
            ]}
          >
            {item.titulo}
          </Text>
          <Text
            wrap={false}
            style={[
              stylesPdf.textInfosRelatorio,
              {
                paddingVertical: 2,
              },
            ]}
          >
            <Text style={{ fontWeight: "normal", textAlign: "justify" }}>
              {formatText(item.objetivos)}
            </Text>
          </Text>
        </View>
        <View style={[styledCol[2]]} wrap={false}>
          <Text
            style={[
              stylesPdf.textInfosRelatorio,
              {
                paddingVertical: 2,
              },
            ]}
          >
            {`${""}`}
          </Text>
        </View>
        <View style={[styledCol[3]]} wrap={false}>
          <Text
            style={[
              stylesPdf.textInfosRelatorio,
              {
                textAlign: "right",
                paddingVertical: 2,
              },
            ]}
          >
            {`${FormatValueToLocaleString(parseFloat(item.valorTotalDaAcao))}`}
          </Text>
        </View>
      </View>
    );
  };

  const renderInfosFonteDeRecurso = (item) => {
    return (
      <View
        style={[stylesPdf.colunas,{marginHorizontal:-3}]}
        key={`${item.fonteDeRecurso.id}-${item.fonteDeRecurso.conta}`}
      >
        <View style={[styledCol[0]]} wrap={false}>
          <Text
            style={[
              stylesPdf.textInfosRelatorio,
              {
                paddingRight: 0,
                paddingVertical: 2,
                textAlign: "right",
              },
            ]}
          >
            {item.fonteDeRecurso.conta}
          </Text>
        </View>
        <View style={[styledCol[1]]} wrap={false}>
          <Text
            style={[
              stylesPdf.textInfosRelatorio,
              {
                paddingVertical: 2,
                textAlign: "left",
              },
            ]}
          >
            {item.fonteDeRecurso.titulo}
          </Text>
        </View>
        <View style={[styledCol[2]]} wrap={false}>
          <Text
            style={[
              stylesPdf.textInfosRelatorio,
              {
                paddingVertical: 2,
              },
            ]}
          >
            {`${""}`}
          </Text>
        </View>
        <View style={[styledCol[3]]} wrap={false}>
          <Text
            style={[
              stylesPdf.textInfosRelatorio,
              {
                textAlign: "right",
                paddingVertical: 2,
              },
            ]}
          >
            {`${FormatValueToLocaleString(parseFloat(item.totalPorFonte))}`}
          </Text>
        </View>
      </View>
    );
  };

  const renderInfosNaturezaDespesa = (item) => {
    return (
      <View
        style={[stylesPdf.colunas, { fontWeight: "bold" ,marginHorizontal:-3}]}
        key={`${item.id}-${item.codigo}`}
      >
        <View
          style={[
            styledCol[0],
            {
              textAlign: "right",
            },
          ]}
          wrap={false}
        >
          <Text
            style={[
              stylesPdf.textInfosRelatorio,
              {
                paddingRight: 0,
                paddingVertical: 2,
              },
            ]}
          >
            {item.codigo}
          </Text>
        </View>
        <View style={[styledCol[1]]} wrap={false}>
          <Text
            style={[
              stylesPdf.textInfosRelatorio,
              {
                paddingVertical: 2,
                textAlign: "left",
              },
            ]}
          >
            {item.nome}
          </Text>
        </View>
        <View style={[styledCol[2]]} wrap={false}>
          <Text
            style={[
              stylesPdf.textInfosRelatorio,
              {
                textAlign: "center",
                paddingVertical: 2,
              },
            ]}
          >
            {item.esfera}
          </Text>
        </View>
        <View style={[styledCol[3]]} wrap={false}>
          <Text
            style={[
              stylesPdf.textInfosRelatorio,
              {
                textAlign: "right",
                paddingVertical: 2,
              },
            ]}
          >
            {`${FormatValueToLocaleString(parseFloat(item.valor))}`}
          </Text>
        </View>
      </View>
    );
  };

  const renderInfosRelatorio = (item, index) => {
    return (
      <>
        
          {renderInfoUnidadeOrcamentaria(item)}

          {item.acoes.map((acao) => (
            <View
              key={acao.acaoGovernamentalId}
              style={{ marginBottom: "10px" }}
            >
              {renderInfoAcao(acao)}
              {acao.despesaFixadaQDD.map((ficha) => (
                <React.Fragment
                  key={`${item.unidadeOrcamentariaId}-${ficha.id}`}
                >
                  {renderInfosFonteDeRecurso(ficha)}
                  {ficha.fichasQDD.map((natureza) =>
                    renderInfosNaturezaDespesa(natureza)
                  )}
                </React.Fragment>
              ))}
            </View>
          ))}
        
        {renderTotal(
          "subtotal",
          FormatValueToLocaleString(parseFloat(item.totalAcoesDaUnidade))
        )}
      </>
    );
  };

  const renderTotal = (textTotalType, total) => {
    return (
      <View
        wrap={false}
        style={[
          stylesPdf.divInferiorColuna,
          {
            fontWeight: "bold",
            fontSize: 10,
            textTransform: "uppercase",
            color: "white",
            flexDirection: "row",
            justifyContent: "space-between",
            marginHorizontal:-3
          },
        ]}
      >
        <Text>{textTotalType}</Text>
        <Text>{total}</Text>
      </View>
    );
  };

  return (
    <Page orientation="portrait" size={"A4"} style={stylesPdf.page}>
      <PdfHeader
        currentClient={currentClient}
        plusText={"vazio"}
        nameRelatorio={
          "Quadro Detalhado da Despesa - QDD por Modalidade de Aplicação"
        }
        anoRelatorio={currentYear}
        dataClientInfos={dataClientInfos}
        customData={customData}
      />
      <View
        fixed
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          fontWeight: "bold",
          fontSize: "9px",
          padding: "0 4px",
        }}
      >
        <Text>Lei Federal 4.320/64</Text>
        <Text>R$ 1,00</Text>
      </View>
      <View style={[stylesPdf.colunas]} fixed>
        {optionReceitaQdr?.checkBoxes?.map(
          (each, index) =>
            each.selected && (
              <PdfColunas
                key={index}
                colName={colNames[index].toUpperCase()}
                styledCol={styledCol[index]}
                hideBar
              />
            )
        )}
      </View>
      <React.Fragment >
        <React.Fragment >
          {dados.data?.map((item, index) => (
            <View key={index} break={index !== 0}>
              {renderInfosRelatorio(item, index)}
            </View>
          ))}

          <View style={{marginTop: 10}}>{renderTotal("total geral", dados.sum)}</View>
        </React.Fragment>
      </React.Fragment>
      <PdfRodape currentClient={currentClient} userAuthData={userAuthData} />
    </Page>
  );
};
