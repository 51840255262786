import api from "../../../../../../utils/api";

export const getDespesaOrgaoUnidade = async (idsEntidades, ano = 2024) => {
  const idsUrl = idsEntidades.ids.join(",");

  try {
    const response = await api.get(`qdd/relatorio/agregado/${idsUrl}/${ano}`);

    return formattedAllDespesas(response.data);
  } catch (error) {
    console.error("Erro na requisição:", error);
    return {
      error: "error",
      name: "Anexo VI - Programa de Trabalho - Órgão e Unidade Orçamentária",
    };
  }
};

const calcularTotalGeralUnidades = (unidades) => {
  console.log(unidades);

  const totais = Object.keys(unidades).reduce(
    (acc, unidadeKey) => {
      const unidadeOrcamentaria = unidades[unidadeKey];
      console.log(unidadeKey);

      console.log(unidadeOrcamentaria);

      acc.totalDaUnidade += unidadeOrcamentaria.totalDaUnidade;
      acc.totalEspeciaisUnidade += unidadeOrcamentaria.totalEspeciaisUnidade;
      acc.totalProjetosUnidade += unidadeOrcamentaria.totalProjetosUnidade;
      acc.totalAtividadesUnidade += unidadeOrcamentaria.totalAtividadesUnidade;
      return acc;
    },
    {
      totalDaUnidade: 0,
      totalEspeciaisUnidade: 0,
      totalProjetosUnidade: 0,
      totalAtividadesUnidade: 0,
    }
  );

  return totais;
};

const calculateTotalEntidades = (entidades) => {
  console.log(entidades);

  const total = entidades.reduce(
    (acc, unidade) => {
      acc.totalDaUnidade +=
        unidade.infoUnidade.totalGeralUnidade.totalDaUnidade;
      acc.totalEspeciaisUnidade +=
        unidade.infoUnidade.totalGeralUnidade.totalEspeciaisUnidade;
      acc.totalProjetosUnidade +=
        unidade.infoUnidade.totalGeralUnidade.totalProjetosUnidade;
      acc.totalAtividadesUnidade +=
        unidade.infoUnidade.totalGeralUnidade.totalAtividadesUnidade;
      return acc;
    },
    {
      totalDaUnidade: 0,
      totalEspeciaisUnidade: 0,
      totalProjetosUnidade: 0,
      totalAtividadesUnidade: 0,
    }
  );
  console.log(total);

  return total;
};

const formattedAllDespesas = (dataQddRelatorioOrgaoUnidade) => {
  console.log(dataQddRelatorioOrgaoUnidade);

  const dadosFormatados = Object.keys(dataQddRelatorioOrgaoUnidade).map(
    (key) => {
      const unidade = dataQddRelatorioOrgaoUnidade[key];
      return {
        infoUnidade: {
          idUnidade: unidade.clienteInfo[0].id,
          tipoNaturezaJuridica: unidade.clienteInfo[0].tipoNaturezaJuridica,
          unidadeGestora: unidade.clienteInfo[0].pessoaJuridica.nomeFantasia,
          totalGeralUnidade: calcularTotalGeralUnidades(
            unidade.unidadesOrcamentarias
          ),
          unidades: unidade.unidadesOrcamentarias,
        },
      };
    }
  );
  console.log(dadosFormatados);

  dadosFormatados.sort((a, b) => {
    const tipoA = parseInt(a.infoUnidade.tipoNaturezaJuridica[0].startsWith());
    const tipoB = parseInt(b.infoUnidade.tipoNaturezaJuridica[0].startsWith());
    return tipoA - tipoB;
  });

  // dadosFormatados.forEach((cliente) => {
  //   cliente.infoUnidade.unidades.sort((a, b) => {
  //     const codigoA = parseFloat(a.codigoUnidadeOrcamentaria.replace(".", ""));
  //     const codigoB = parseFloat(b.codigoUnidadeOrcamentaria.replace(".", ""));
  //     return codigoA - codigoB;
  //   });
  // });

  dadosFormatados.forEach((cliente) => {
    const unidadesArray = Object.values(cliente.infoUnidade.unidades);
    unidadesArray.sort((a, b) => {
      const codigoA = parseFloat(a.codigoUnidadeOrcamentaria.replace(".", ""));
      const codigoB = parseFloat(b.codigoUnidadeOrcamentaria.replace(".", ""));
      return codigoA - codigoB;
    });
    cliente.infoUnidade.unidades = unidadesArray.reduce((acc, unidade) => {
      acc[unidade.unidadeOrcamentariaId] = unidade;
      return acc;
    }, {});
  });

  const ordenarPorCodigoUnidadeOrcamentaria = (obj) => {
    return Object.values(obj).sort(
      (a, b) =>
        parseFloat(a.codigoUnidadeOrcamentaria.replace(".", "")) -
        parseFloat(b.codigoUnidadeOrcamentaria.replace(".", ""))
    );
  };

  dadosFormatados[0].infoUnidade.unidades = ordenarPorCodigoUnidadeOrcamentaria(
    dadosFormatados[0].infoUnidade.unidades
  );

  const totalEntidades = calculateTotalEntidades(dadosFormatados);
  console.log(dadosFormatados);

  const result = {
    dataDespesa: dadosFormatados,
    total: totalEntidades,
  };
  console.log("RESULT: ", result);
  return result;
};
