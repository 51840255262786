//import { calcularTotalReceitasComDeducao } from "../../../../LOA_QDR/RELATORIO_QDR/calcularReceitas";
// import api from "../../../../../../utils/api";

export const getDespesaCompatibilidade = async (clienteId, ano = 2024) => {
  console.log("clienteId: ", clienteId);
  // try {
  //   const response = await api.get(`qdd/relatorio-qdd/${clienteId}/${ano}`);
    
    
  //   return response.data;
  // } catch (error) {
  //   console.error("Erro na requisição:", error);
  //   return {
  //     error: "error",
  //     name: "Quadro Detalhado da Despesa - QDD por Modalidade de Aplicação",
  //   };
  // }
  return ["1",""]
};

// const processAllData = () => {
//   return "";
// };
