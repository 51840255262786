import api from "../../../../../../utils/api";
import { getUrl } from "../getUrlConsolidado";

export const getDespesaDemonstrativoPrograma = async (
  clienteId,
  entidadesGestoras,
  ano = 2024
) => {
  const url = getUrl(clienteId, entidadesGestoras);

  try {
    const response = await api.get(
      `qdd/relatorio/porPrograma/${url.idsUrl}/${ano}?${url.filterConsolidado}`
    );

    return formattedData(response.data);
  } catch (error) {
    console.error("Erro na requisição:", error);
    return {
      error: "error",
      name: "Demonstrativo da Despesa por Programas Governamentais",
    };
  }
};

const formattedData = (programas) => {
  
  const filterProgramas = programas.filter(
    (programa) => Object.keys(programa.Programas).length > 0
  );

  const totalGeral = filterProgramas.reduce((acc, programa) => {
    return (acc += programa.total);
  }, 0);
  
  return {
    filterProgramas,
    total: totalGeral,
  };
};
