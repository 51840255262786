import React, { useEffect, useState } from "react";
import { CCol, RowToModal } from "../../../../components/Grid/CCol";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import InputData from "../../../../components/Grid/InputData";
import MyAutoComplete from "../../../../components/Inputs/MyAutoComplete";
import api from "../../../../utils/api";
import ReactInputMask from "react-input-mask";
import { Search } from "@mui/icons-material";
import { ReturnDataAtual } from "../../../../utils/Gerais/ReturnDataAtual";
import { FormatDateToNA } from "../../../../utils/FormatacaoDeDados/FormatDateToNA";

const Agencia = () => {
  const [todosOsBancos, setTodosOsBancos] = useState([]);
  const [bancosFiltrados, setBancosFiltrados] = useState([]);
  const [inputSearchBancoValue, setInputSearchBancoValue] = useState("");
  const [loadingCep, setLoadingCep] = useState(false);
  const dataAtual = FormatDateToNA(ReturnDataAtual());

  //state da requisicao
  const [contaValues, setContaValues] = useState({
    codigo: "",
    nome: "",
    agencia: "",
    digito: "",
    cep: "",
    logradouro: "",
    bairro: "",
    cidade: "",
    estado: "",
    data: dataAtual,
  });

  const fetchBancos = async () => {
    try {
      const resp = await api.get("brasilApi/bank");
      const data = resp.data;

      setTodosOsBancos(
        data
          .filter((item) => item.code !== null)
          .map((item) => ({
            ...item,
            code: item.code.toString().padStart(3, "0"),
          }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchBancos();
  }, []);

  const handleSearchInputChange = (e) => {
    if (e.target.value.length > 0) {
      const cleanedValue = e.target.value.replace(/\./g, "").toLowerCase();
      const arrayFiltrado = todosOsBancos.filter(
        (banco) =>
          banco.fullName.toLowerCase().includes(cleanedValue) ||
          banco.code.includes(cleanedValue)
      );

      console.log(arrayFiltrado);

      setBancosFiltrados(arrayFiltrado);
    } else {
      setBancosFiltrados([]);
    }
  };

  const handleOptionSelect = (item) => {
    console.log(item);
  };

  const fetchCep = async () => {
    setLoadingCep(true);

    try {
      const resp = await api.get(`brasilApi/cep/${contaValues.cep}`);
      const data = resp.data;

      setContaValues((prev) => ({
        ...prev,
        logradouro: data.street,
        bairro: data.neighborhood,
        cidade: data.city,
        estado: data.state,
      }));
    } catch (error) {
      console.log(error);
    }

    setLoadingCep(false);
  };

  useEffect(() => {
    if (contaValues.cep.length === 9) {
      fetchCep();
    }

    //eslint-disable-next-line
  }, [contaValues.cep]);

  // const debug = () => {
  //   console.log(todosOsBancos);
  //   console.log(contaValues.data);
  // };

  return (
    <>
      <RowToModal className="row_form mt-1">
        <CCol>
          {/* <button onClick={debug}>debug</button> */}
          <MyAutoComplete
            labelInput={"Código Banco"}
            placeholder="Código Banco"
            style={{ height: "38px" }}
            options={bancosFiltrados}
            inputValue={inputSearchBancoValue}
            setInputValue={setInputSearchBancoValue}
            labelFormat={(option) => `${option.code} - ${option.fullName}`}
            onChange={handleSearchInputChange}
            onOptionSelect={handleOptionSelect}
          />
        </CCol>
        <CCol md={2}>
          <ReactInputMask mask="9999" maskPlaceholder={null}>
            <FloatingLabelInput label="Nº Agência" placeholder="Nº Agência" />
          </ReactInputMask>
        </CCol>
        <CCol md={1}>
          <ReactInputMask mask="9" maskPlaceholder={null}>
            <FloatingLabelInput label="Dígito" placeholder="Dígito" />
          </ReactInputMask>
        </CCol>
        <CCol md={2}>
          <InputData
            externalValue={contaValues.data}
            onChange={(e) =>
              setContaValues((prev) => ({ ...prev, data: e.target.value }))
            }
          />
        </CCol>
      </RowToModal>
      <RowToModal className="row_form" style={{ marginTop: "-15px" }}>
        <CCol md={3}>
          <div style={{ position: "relative" }}>
            <Search
              style={{
                color: "#515c70",
                position: "absolute",
                top: "50%",
                transform: "translateY(-50%)",
                right: "5px",
              }}
            />
            <ReactInputMask
              mask="99999-999"
              maskPlaceholder={null}
              onChange={(e) =>
                setContaValues((prev) => ({ ...prev, cep: e.target.value }))
              }
            >
              <FloatingLabelInput placeholder="CEP" label="CEP" />
            </ReactInputMask>
          </div>
        </CCol>
        <CCol>
          <FloatingLabelInput
            label="Logradouro"
            placeholder="Logradouro"
            disabled={loadingCep}
            value={contaValues.logradouro}
          />
        </CCol>
      </RowToModal>
      <RowToModal className="row_form_2" style={{ marginTop: "-15px" }}>
        <CCol md={6}>
          <FloatingLabelInput
            label="Bairro"
            placeholder="Bairro"
            disabled={loadingCep}
            value={contaValues.bairro}
          />
        </CCol>
        <CCol md={3}>
          <FloatingLabelInput
            label="Cidade"
            placeholder="Cidade"
            disabled={loadingCep}
            value={contaValues.cidade}
          />
        </CCol>
        <CCol>
          <FloatingLabelInput
            label="Estado"
            placeholder="Estado"
            disabled={loadingCep}
            value={contaValues.estado}
          />
        </CCol>
      </RowToModal>
    </>
  );
};

export default Agencia;
