import { Icon } from "@iconify/react/dist/iconify.js";
import React from "react";
import { NavLink } from "react-router-dom";
import ButtonDropOrHover from "./ButtonDropOrHover";
import { NavLinksMenores, TitulosNavs } from "./SideBarComponent";

const PlanejamentoNav = ({ checkSidebar }) => {
  const optionsCadastros = [
    {
      titulo: "Natureza da Receita",
      path: "/planejamento/cadastro/natureza-da-receita/consulta",
    },
    {
      titulo: "Natureza da Despesa",
      path: "/planejamento/cadastro/natureza-da-despesa/consulta",
    },
    {
      titulo: "Fonte de Recursos",
      path: "/planejamento/cadastro/fonte-de-recursos/consulta",
    },
    {
      titulo: "CPF/CNPJ",
      path: "/planejamento/cadastro/cadastros-cpf-cnpj/consulta",
    },
    {
      titulo: "Unidade Orçamentária",
      path: "/planejamento/cadastro/unidade-orcamentaria/consulta",
    },
    {
      titulo: "Ação Governamental",
      path: "/planejamento/cadastro/acao-governamental/consulta",
    },
    {
      titulo: "Programa",
      path: "/planejamento/cadastro/programa/consulta",
    },
    {
      titulo: "Eixo Estratégico",
      path: "/planejamento/cadastro/eixo-estrategico/consulta",
    },
    {
      titulo: "Diretriz",
      path: "/planejamento/cadastro/diretriz/consulta",
    },
    {
      titulo: "Parâmetros / Índices",
      path: "/planejamento/cadastro/parametro-indices/consulta",
    },
    {
      titulo: "Função / Subfunção",
      path: "/planejamento/cadastro/funcao-subfuncao/consulta",
    },
    {
      titulo: "Tipo de Programa",
      path: "/planejamento/cadastro/tipo-de-programa/consulta",
    },
    {
      titulo: "Indicadores",
      path: "/planejamento/cadastro/indicadores/consulta",
    },
    {
      titulo: "Objetivos da ONU",
      path: "/planejamento/cadastro/objetivos-da-onu/consulta",
    },
  ];

  const arrayDropDownButtons = [
    {
      titulo: "LOA",
      options: [
        {
          titulo: "QDR",
          path: "/planejamento/loa/qdr/consulta",
        },
        {
          titulo: "QDD",
          path: "/planejamento/loa/qdd/consulta",
        },
      ],
      icon: "ic:baseline-pie-chart",
    },
    {
      titulo: "LDO",
      options: [
        {
          titulo: "Riscos Fiscais",
          path: "/planejamento/ldo/riscos-fiscais",
        },
        {
          titulo: "Metas Fiscais",
          path: "/planejamento/ldo/metas-fiscais",
        },
      ],
      icon: "ph:chart-line-up-bold",
    },
    {
      titulo: "PPA",
      options: [
        {
          titulo: "Receita Estimada",
          path: "/planejamento/ppa/receita-estimada/consulta",
        },
        {
          titulo: "Despesa Fixada",
          path: "/",
        },
      ],
      icon: "oi:bar-chart",
    },
  ];

  return (
    <>
      <NavLink to={"/planejamento/inicio"} className={"link_direto"}>
        <Icon icon="ion:home" width={25} />
        <span style={{ marginLeft: "3px" }}>Início</span>
      </NavLink>
      {arrayDropDownButtons.map((item, index) => (
        <ButtonDropOrHover
          key={index}
          checkSidebar={checkSidebar}
          tituloButton={item.titulo}
          navOptions={item.options}
          iconSrc={item.icon}
        />
      ))}
      <NavLink to={"/planejamento/pcasp/consulta"} className={"link_direto"}>
        <Icon icon="oi:list-rich" width={22} style={{ marginLeft: "2px" }} />
        <span style={{ marginLeft: "4px" }}>PCASP</span>
      </NavLink>

      <TitulosNavs>Impressos</TitulosNavs>
      <NavLinksMenores>
        <NavLink
          to={"/planejamento/impressos/relatorios"}
          className={"link_direto"}
        >
          <Icon
            icon="gg:loadbar-doc"
            className="icon_relatorios"
            style={{ transform: "translateY(-1px)" }}
            width={25}
          />
          <span style={{ marginLeft: "3px" }}>Relatórios</span>
        </NavLink>
        <NavLink
          to={"/planejamento/cadastro/leis-e-documentos/"}
          className={"link_direto"}
        >
          <Icon
            icon="mdi:file-document-box-check-outline"
            style={{ transform: "translateY(-1px)" }}
            width={25}
          />
          <span style={{ marginLeft: "3px" }}>Leis e Documentos</span>
        </NavLink>
      </NavLinksMenores>

      <TitulosNavs>Ferramentas</TitulosNavs>
      <ButtonDropOrHover
        smallerType
        checkSidebar={checkSidebar}
        tituloButton={"Cadastros"}
        iconSrc={"ic:round-addchart"}
        navOptions={optionsCadastros}
      />

      <NavLinksMenores>
        <NavLink
          to={"/planejamento/cadastro/importar/"}
          className={"link_direto"}
        >
          <Icon icon="tabler:file-import" width={25} />
          <span style={{ marginLeft: "3px" }}>Importar</span>
        </NavLink>
        <NavLink
          to={"/planejamento/cadastro/exportar/"}
          className={"link_direto"}
        >
          <Icon icon="tabler:file-export" width={25} />
          <span style={{ marginLeft: "3px" }}>Exportar</span>
        </NavLink>
        <NavLink to={"/"} className={"link_direto"}>
          <Icon icon="carbon:document-configuration" width={25} />
          <span style={{ marginLeft: "3px" }}>Configurar</span>
        </NavLink>
      </NavLinksMenores>
    </>
  );
};

export default PlanejamentoNav;
