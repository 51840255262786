import React from "react";
import {
  CCol,
  CColButtonsAcoes2Icons,
  RowAninhada,
} from "../../../../components/Grid/CCol";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import { Row } from "react-bootstrap";
import styled from "styled-components";
import {
  FormControlListaDeInputs,
  PTitulosInputs,
} from "../../../../styles/StyledComponents/ListaDeInputs";
import InputFormatRealBrasileiro from "../../../../components/Grid/InputFormatRealBrasileiro";
import { Icon } from "@iconify/react/dist/iconify.js";
import { SpanTotalPorFonte } from "../../../Paginas_Sistemas_Planejamento/LOA_QDD/StyledComponentsQDD";

export const RowInfosAcao = styled(Row)`
  align-content: baseline;
  min-height: ${({ minHeight }) => minHeight || "80px"};
  margin: 0px;
  border-radius: 10px;
  background-color: #ebeaea;
  padding: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  resize: vertical;
  border-radius: 5px;
  border: 1px solid #ccc;
  position: relative;

  span {
    color: #73767e;
  }

  p {
    margin: 0px;
    margin-left: 10px;
    color: #515c70;
    font-weight: 500;
    font-size: 15.5px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  span + p {
    display: inline;
  }
`;

const mapToMap = [0, 1, 2];

const ReceitaPrevista = () => {
  return (
    <>
      <Row className="row_form mt-1">
        <RowAninhada>
          <CCol>
            <FloatingLabelInput
              labelInput={"Número de Ação"}
              placeholder={"Fonte de Recursos"}
            />
          </CCol>
        </RowAninhada>
        <RowAninhada>
          <CCol md={12} style={{ marginTop: "14px" }}>
            <RowInfosAcao>
              <CCol md={12}>
                <p>
                  {
                    "Registra as receitas que se originaram dos impostos sobre a exportação. De competência da União, incide sobre a exportação, para o estrangeiro, de produtos nacionais ou nacionalizados."
                  }
                </p>
              </CCol>
            </RowInfosAcao>
          </CCol>
        </RowAninhada>
      </Row>

      <Row className="row_form mt-1">
        <RowAninhada
          style={{
            margin: "0px",
            marginTop: "6px",
            padding: "0px",
          }}
        >
          <CCol md={1}>
            <PTitulosInputs>Ficha</PTitulosInputs>
            <FormControlListaDeInputs
              disabled
              style={{ textAlign: "end", fontSize: "15px" }}
              radiusborder={mapToMap.length > 0 && "5px 5px 0 0"}
            />
          </CCol>
          <CCol md={6}>
            <PTitulosInputs>
              Natureza da Despesa - Elemento de Despesa
            </PTitulosInputs>
            <FloatingLabelInput
              placeholder={"Nome ou Código da natureza da despesa"}
              disabled
              style={{ height: "30px" }}
              radiusborder={mapToMap.length > 0 && "5px 5px 0 0"}
            />
          </CCol>
          <CCol md={2}>
            <PTitulosInputs>Esfera</PTitulosInputs>
            <FloatingLabelInput
              height={"30px"}
              new={true}
              holdSelect
              style={{
                color: "#515c70",
              }}
              tipo="input_select"
              label="Fiscal"
              placeholder="Fiscal"
              options={[
                { label: "Fiscal", value: "Fiscal" },
                { label: "Seguridade", value: "Seguridade" },
              ]}
              radiusborder={mapToMap.length > 0 && "5px 5px 0 0"}
            />
          </CCol>
          <CCol>
            <PTitulosInputs>Despesas Fixadas</PTitulosInputs>
            <InputFormatRealBrasileiro
              listaDeInputs={true}
              style={{ textAlign: "end", fontSize: "15px" }}
              radiusborder={mapToMap.length > 0 && "5px 5px 0 0"}
            />
          </CCol>
          <CColButtonsAcoes2Icons md={1}>
            <PTitulosInputs>Ação</PTitulosInputs>
            <Icon
              icon="ic:baseline-add-box"
              color="#105200"
              height="28"
              style={{
                cursor: "pointer",
                marginLeft: "0px",
                marginTop: "1px",
              }}
            />
          </CColButtonsAcoes2Icons>
        </RowAninhada>
        {mapToMap.map((index) => (
          <RowAninhada
            style={{
              padding: "0px",
              margin: "0px",
              marginTop: "-1px",
            }}
            key={index}
          >
            <CCol md={1}>
              <FormControlListaDeInputs
                disabled
                style={{ textAlign: "end", fontSize: "15px" }}
                radiusborder={
                  index === mapToMap.length - 1 ? "0 0 5px 5px" : "0"
                }
              />
            </CCol>
            <CCol md={6}>
              <FormControlListaDeInputs
                style={{ fontSize: "15px" }}
                radiusborder={
                  index === mapToMap.length - 1 ? "0 0 5px 5px" : "0"
                }
                disabled
              />
            </CCol>
            <CCol md={2}>
              <FloatingLabelInput
                height={"30px"}
                new={true}
                style={{
                  color: "#515c70",
                }}
                tipo="input_select"
                placeholder="Fiscal"
                options={[
                  { label: "Fiscal", value: "Fiscal" },
                  { label: "Seguridade", value: "Seguridade" },
                ]}
                radiusborder={
                  index === mapToMap.length - 1 ? "0 0 5px 5px" : "0"
                }
              />
            </CCol>
            <CCol>
              <InputFormatRealBrasileiro
                listaDeInputs={true}
                style={{ fontSize: "15px", textAlign: "right" }}
                onKeyDown={(e) => {}}
                radiusborder={
                  index === mapToMap.length - 1 ? "0 0 5px 5px" : "0"
                }
              />
            </CCol>
            <CColButtonsAcoes2Icons md={1}>
              <Icon
                icon="bx:edit"
                color="#5971C8"
                height="26"
                style={{
                  cursor: "pointer",
                  marginLeft: "2px",
                  marginTop: "3px",
                }}
              />
              <Icon
                icon="ic:baseline-delete"
                color="#E79900"
                height="28"
                style={{
                  cursor: "pointer",
                  marginLeft: "1px",
                  marginTop: "2px",
                }}
              />
            </CColButtonsAcoes2Icons>
          </RowAninhada>
        ))}
        <RowAninhada
          style={{
            padding: "0px",
            margin: "0px",
            marginTop: "9px",
          }}
        >
          <CCol md={/*selfArray.length - 1 === indexDespesa ? 3 : 7*/ 7}></CCol>
          <CCol
            md={2}
            style={{
              textAlign: "center",
              marginTop: "4px",
            }}
          >
            <SpanTotalPorFonte>Total por Fonte</SpanTotalPorFonte>
          </CCol>
          <CCol>
            <FormControlListaDeInputs
              style={{
                borderRadius: "10px",
                padding: "15px",
                border: "none",
                textAlign: "end",
              }}
              disabled
              value={`R$ 10.000,00`}
            />
          </CCol>
          <CColButtonsAcoes2Icons md={1} />
        </RowAninhada>
      </Row>
    </>
  );
};

export default ReceitaPrevista;
