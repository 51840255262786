import api from "../../../../../../utils/api";
import { getUrl } from "../getUrlConsolidado";

export const getDespesaEsfera = async (
  clientes,
  ano = 2024,
  entidadesGestoras
) => {
  const url = getUrl(clientes, entidadesGestoras);
  try {
    const response = await api.get(
      `/naturezaDaDespesa/relatorio/${url.idsUrl}/${ano}?${url.filterConsolidado}`
    );

    return processAllData(response.data.contas.relatorios[String(url.idsUrl)]);
  } catch (error) {
    console.error("Erro na requisição:", error);
    return {
      error: "error",
      name: "Natureza da Despesa por Esfera ",
    };
  }
};

const getTotalEsfera = (dadosFiltrados) => {
  const dataSection = dadosFiltrados.filter(
    (naturezaSection) =>
      naturezaSection.codigo.split(".").filter((secao) => parseInt(secao) !== 0)
        .length === 1
  );

  const total = dataSection.reduce(
    (acc, item) => {
      acc.fiscal += item.fiscal ? parseFloat(item.fiscal) : 0;
      acc.seguridade += item.seguridade ? parseFloat(item.seguridade) : 0;
      return acc;
    },
    { fiscal: 0, seguridade: 0 }
  );

  return total;
};

const formatarNaturezas = (item) => {
  const resultado = [
    {
      codigo: item.codigo,
      especificacao: item.nome,
      fiscal:
        parseFloat(item.valorTotalFiscal) !== 0 ? item.valorTotalFiscal : null,
      seguridade:
        parseFloat(item.valorTotalSeguridade) !== 0
          ? item.valorTotalSeguridade
          : null,
    },
  ];

  if (item.fontes.length) {
    const recursos = item.fontes.map((recurso) => {
      return {
        codigo: recurso.conta,
        especificacao: recurso.tituloFonte,
        fiscal: recurso.totalFiscal || null,
        seguridade: recurso.totalSeguridade || null,
      };
    });
    resultado.push(...recursos);
  }

  return resultado;
};

const processAllData = (dataQddRelatorio) => {
  dataQddRelatorio.naturezaConsolidadas.forEach((item) => {
    if (item.fontes.length > 0) {
      item.fontes.sort((a, b) => a.conta.localeCompare(b.conta));
    }
  });

  const despesasFormatada = dataQddRelatorio.naturezaConsolidadas
    .map((natureza) => formatarNaturezas(natureza))
    .flat();

  const totalGeralReceitaEsfera = getTotalEsfera(despesasFormatada);

  return {
    data: { naturezas: despesasFormatada },
    totalGeralReceitaEsfera,
  };
};
