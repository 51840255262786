import api from "../../../../../../utils/api";
import { getUrl } from "../getUrlConsolidado";

export const getDespesaPorFonteRec = async (clientes, ano = 2024, entidadesGestoras) => {
  const url = getUrl(clientes, entidadesGestoras);
  try {
    const response = await api.get(
      `qdd/relatorio/fontes-esfera/${url.idsUrl}/${ano}?${url.filterConsolidado}&fontesId=&esfera=&`
    );
    return processAllData(response.data);
  } catch (error) {
    console.error("Erro na requisição:", error);
    return {
      error: "error",
      name: "Natureza da Despesa por Fontes ou Destinações de Recursos",
    };
  }
};

export const processAllData = (fontes) => {
  let fontesDespesas = fontes;

  const letsCompare = (number) => {
    const valor = parseFloat(number.replace(".", ""));
    switch (true) {
      case [1500, 1501, 1502].includes(valor):
        return "Recursos Livres (Não Vinculados)";
      case valor <= 1599:
        return "Recursos Vinculados à Educação";
      case valor <= 1659:
        return "Recursos Vinculados à Saúde";
      case valor <= 1699:
        return "Recursos Vinculados à Assistência Social";
      case valor <= 1749:
        return "Demais Vinculações Decorrentes de Transferências";
      case valor <= 1799:
        return "Demais Vinculações Legais";
      case valor <= 1859:
        return "Recursos Vinculados à Previdência Social";
      case valor <= 1869:
        return "Recursos Extraorçamentários";
      case valor < 1900:
        return "Outras Vinculações";
      default:
        return "Valores não identificados";
    }
  };

  const part1 = () => {
    let fontesDesp = [];
    let dadosFiltrados = [];
    let actualType = null;

    fontesDespesas.fontes.forEach((item) => {
      fontesDesp.push({
        codigo: item.conta,
        especificacao: item.titulo,
        valor: parseFloat(item.totalFiscal) + parseFloat(item.totalSeguridade),
        funcao: item.funcao,
      });
    });

    fontesDesp = fontesDesp.sort((a, b) => {
      if (a.codigo < b.codigo) return -1;
      if (a.codigo > b.codigo) return 1;
      return 0;
    });

    fontesDesp.forEach((item) => {
      const especificacao = letsCompare(item.codigo);
      if (actualType !== especificacao) {
        actualType = especificacao;
        dadosFiltrados.push({
          codigo: null,
          especificacao: especificacao,
          valor: null,
        });
      }
      dadosFiltrados.push(item);
    });
    return dadosFiltrados;
  };
  const allData = {
    data: [...part1()],
    sum: parseFloat(fontesDespesas.totais.totalGeral),
  };

  const sumAndGroupValues = (data) => {
    let soma = 0;
    return data.map((item, index, arr) => {
      if (item.codigo === null && item.valor === null) {
        soma = 0;
        for (let i = index + 1; i < arr.length && arr[i].codigo !== null; i++) {
          soma += arr[i].valor ? arr[i].valor : 0;
        }
        return { ...item, valor: soma };
      }
      return item;
    });
  };

  const filteredData = {
    data: sumAndGroupValues(allData.data),
    sum: allData.sum,
  };
  return filteredData;
};
