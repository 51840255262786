import { Document } from "@react-pdf/renderer";
import React from "react";
import { RenderPdfReceitaDespesaCEconomica } from "./RenderPdfReceitaDespesaCEconomica";
import { RenderPdfReceitaSegCEconomica } from "./RenderPdfReceitaSegCEconomica";
import { RenderPdfReceitaCatEconOrgao } from "./RenderPdfReceitaCatEconOrgao";
import { RenderPdfEsfera } from "./RenderPdfEsfera";
import { RenderPdfReceitaPorFonteRec } from "./RenderPdfReceitaPorFonteRec";
import { RenderPdfReceitaQdr } from "./RenderPdfReceitaQdr";
import { RenderPdfDespesaCatEconOrgao } from "./RenderPdfDespesaCatEconOrgao";
import { RenderPdfDespesaQdd } from "./RenderPdfDespesaQdd";
import { RenderPdfQDDAplicacao } from "./RenderPdfQDDAplicacao";
import { RenderPdfDespesaOrgaoUnidade } from "./RenderPdfDespesaOrgaoUnidade";
import { RenderPdfDespesaDemonstrativo } from "./RenderPdfDespesaDemonstrativo";
import { RenderPdfDespesaVinculado } from "./RenderPdfDespesaVinculado";
import { RenderPdfDespesaDemonstrativoUnidade } from "./RenderPdfDespesaDemonstrativoUnidade";
import { RenderPdfDemoRiscosFiscaisProvidencias } from "./RenderPdfDemoRiscosFiscaisProvidencias";
import { RenderPdfDespesaDemonstrativoAcao } from "./RenderPdfDespesaDemonstrativoAcao";
import { RenderPdfDespesaDemonstrativoPrograma } from "./RenderPdfDespesaDemonstrativoPrograma";
import { RenderPdfDespesaCompatibilidade } from "./RenderPdfDespesaCompatibilidade";
import { RenderPdfDespesaClassificacao } from "./RenderPdfDespesaClassificacao";
import { RenderPdfDespesaDemonstrativoOrgaoFuncao } from "./RenderPdfDespesaDemonstrativoOrgaoFuncao";
import { RenderPdfDespesaDemonstrativoRegionalizado } from "./RenderPdfDespesaDemonstrativoRegionalizado";

export const AllRelatorios = ({ pdfContent }) => {
  const {
    dadosRelatorio,
    opcoes,
    userData: { currentClient, userAuthData, dataClientInfos },
    currentYear,
    selectBoxesOptionsAbaPrincipal,
    entidadesGestorasDoCliente,
  } = pdfContent;
  console.log(entidadesGestorasDoCliente);

  let nameTitleDocumentPdf = "Lei Orçamentária Anual";
  // let loaName = "Lei Orçamentária Anual";

  if (!dadosRelatorio) return null;

  const optionsToCheck = [
    "LOA - Lei Orçamentária Anual",
    "LDO - Lei de Diretrizes Orçamentárias",
    "PPA - Plano Plurianual",
  ];

  for (const optionKey of optionsToCheck) {
    const selectedOption = selectBoxesOptionsAbaPrincipal.find(
      (option) => option.opcao === optionKey
    );

    if (selectedOption) {
      const selectedCheckBox = selectedOption.checkBoxes.filter(
        (checkBox) => checkBox.selected
      );

      if (selectedCheckBox.length === 1) {
        nameTitleDocumentPdf = selectedCheckBox[0].label;
        break;
      }
    }
  }

  return (
    <Document
      title={
        dadosRelatorio.uniqueName[0]
          ? dadosRelatorio.uniqueName[0]
          : nameTitleDocumentPdf
      }
    >
      {dadosRelatorio.reportIds.includes(1) &&
        dadosRelatorio.data.receitaDespesaCEconomica &&
        RenderPdfReceitaDespesaCEconomica(
          dadosRelatorio.data.receitaDespesaCEconomica,
          opcoes,
          currentClient,
          userAuthData,
          dataClientInfos,
          currentYear,
          dadosRelatorio.customData
        )}
      {dadosRelatorio.reportIds.includes(2) &&
        dadosRelatorio.data.receitaSegCEconomica &&
        RenderPdfReceitaSegCEconomica(
          dadosRelatorio.data.receitaSegCEconomica,
          opcoes,
          currentClient,
          userAuthData,
          dataClientInfos,
          currentYear,
          dadosRelatorio.customData
        )}
      {dadosRelatorio.reportIds.includes(3) &&
        dadosRelatorio.data.receitasCatEconOrgao &&
        RenderPdfReceitaCatEconOrgao(
          dadosRelatorio.data.receitasCatEconOrgao,
          opcoes,
          currentClient,
          userAuthData,
          dataClientInfos,
          currentYear,
          dadosRelatorio.customData
        )}
      {dadosRelatorio.reportIds.includes(4) &&
        dadosRelatorio.data.receitasEsferas &&
        RenderPdfEsfera(
          dadosRelatorio.data.receitasEsferas,
          opcoes,
          currentClient,
          userAuthData,
          dataClientInfos,
          currentYear,
          dadosRelatorio.customData
        )}
      {dadosRelatorio.reportIds.includes(5) &&
        dadosRelatorio.data.receitaPorFonteRec &&
        RenderPdfReceitaPorFonteRec(
          dadosRelatorio.data.receitaPorFonteRec,
          opcoes,
          currentClient,
          userAuthData,
          dataClientInfos,
          currentYear,
          dadosRelatorio.customData
        )}
      {dadosRelatorio.reportIds.includes(6) &&
        dadosRelatorio.data.receitaQdr &&
        RenderPdfReceitaQdr(
          dadosRelatorio.data.receitaQdr,
          opcoes,
          currentClient,
          userAuthData,
          dataClientInfos,
          currentYear,
          dadosRelatorio.customData
        )}
      {dadosRelatorio.reportIds.includes(7) &&
        dadosRelatorio.data.despesaSegCEconomica &&
        RenderPdfReceitaSegCEconomica(
          dadosRelatorio.data.despesaSegCEconomica,
          opcoes,
          currentClient,
          userAuthData,
          dataClientInfos,
          currentYear,
          dadosRelatorio.customData,
          "Despesa Segundo a Categoria Econômica"
        )}
      {dadosRelatorio.reportIds.includes(8) &&
        dadosRelatorio.data.despesaCatEconOrgao &&
        RenderPdfDespesaCatEconOrgao(
          dadosRelatorio.data.despesaCatEconOrgao,
          opcoes,
          currentClient,
          userAuthData,
          dataClientInfos,
          currentYear
        )}
      {dadosRelatorio.reportIds.includes(9) &&
        dadosRelatorio.data.despesaPorEsfera &&
        RenderPdfEsfera(
          dadosRelatorio.data.despesaPorEsfera,
          opcoes,
          currentClient,
          userAuthData,
          dataClientInfos,
          currentYear,
          dadosRelatorio.customData,
          true
        )}
      {dadosRelatorio.reportIds.includes(10) &&
        dadosRelatorio.data.despesaPorFonteRec &&
        //RenderPDFDespesaPorFonteRec - Reaproveitado de Receita
        RenderPdfReceitaPorFonteRec(
          dadosRelatorio.data.despesaPorFonteRec,
          opcoes,
          currentClient,
          userAuthData,
          dataClientInfos,
          currentYear,
          dadosRelatorio.customData,
          "Natureza da Despesa por Fontes ou Destinações de Recursos"
        )}
      {dadosRelatorio.reportIds.includes(11) &&
        dadosRelatorio.data.despesaQdd &&
        RenderPdfDespesaQdd(
          dadosRelatorio.data.despesaQdd,
          opcoes,
          currentClient,
          userAuthData,
          dataClientInfos,
          currentYear,
          dadosRelatorio.customData
        )}
      {dadosRelatorio.reportIds.includes(12) &&
        dadosRelatorio.data.qddAplicacao &&
        RenderPdfQDDAplicacao(
          dadosRelatorio.data.qddAplicacao,
          opcoes,
          currentClient,
          userAuthData,
          dataClientInfos,
          currentYear,
          dadosRelatorio.customData
        )}
      {dadosRelatorio.reportIds.includes(13) &&
        dadosRelatorio.data.despesaOrgaoUnidade &&
        RenderPdfDespesaOrgaoUnidade(
          dadosRelatorio.data.despesaOrgaoUnidade,
          opcoes,
          currentClient,
          userAuthData,
          dataClientInfos,
          currentYear,
          dadosRelatorio.customData
        )}
      {dadosRelatorio.reportIds.includes(14) &&
        dadosRelatorio.data.despesaDemonstrativo &&
        RenderPdfDespesaDemonstrativo(
          dadosRelatorio.data.despesaDemonstrativo,
          opcoes,
          currentClient,
          userAuthData,
          dataClientInfos,
          currentYear,
          dadosRelatorio.customData
        )}
      {dadosRelatorio.reportIds.includes(15) &&
        dadosRelatorio.data.despesaVinculado &&
        RenderPdfDespesaVinculado(
          dadosRelatorio.data.despesaVinculado,
          opcoes,
          currentClient,
          userAuthData,
          dataClientInfos,
          currentYear,
          dadosRelatorio.customData
        )}
      {dadosRelatorio.reportIds.includes(16) &&
        dadosRelatorio.data.despesaDemonstrativoUnidade &&
        RenderPdfDespesaDemonstrativoUnidade(
          dadosRelatorio.data.despesaDemonstrativoUnidade,
          opcoes,
          currentClient,
          userAuthData,
          dataClientInfos,
          currentYear,
          dadosRelatorio.customData
        )}
      {dadosRelatorio.reportIds.includes(17) &&
        dadosRelatorio.data.despesaDemonstrativoAcao &&
        RenderPdfDespesaDemonstrativoAcao(
          dadosRelatorio.data.despesaDemonstrativoAcao,
          opcoes,
          currentClient,
          userAuthData,
          dataClientInfos,
          currentYear,
          dadosRelatorio.customData
        )}
      {dadosRelatorio.reportIds.includes(18) &&
        dadosRelatorio.data.despesaDemonstrativoPrograma &&
        RenderPdfDespesaDemonstrativoPrograma(
          dadosRelatorio.data.despesaDemonstrativoPrograma,
          opcoes,
          currentClient,
          userAuthData,
          dataClientInfos,
          currentYear,
          dadosRelatorio.customData
        )}
      {dadosRelatorio.reportIds.includes(19) &&
        dadosRelatorio.data.despesaCompatibilidade &&
        RenderPdfDespesaCompatibilidade(
          dadosRelatorio.data.despesaCompatibilidade,
          opcoes,
          currentClient,
          userAuthData,
          dataClientInfos,
          currentYear,
          dadosRelatorio.customData
        )}
      {dadosRelatorio.reportIds.includes(20) &&
        dadosRelatorio.data.despesaClassificacao &&
        RenderPdfDespesaClassificacao(
          dadosRelatorio.data.despesaClassificacao,
          opcoes,
          currentClient,
          userAuthData,
          dataClientInfos,
          currentYear,
          dadosRelatorio.customData
        )}
      {dadosRelatorio.reportIds.includes(21) &&
        dadosRelatorio.data.despesaDemonstrativoOrgaoFuncao &&
        RenderPdfDespesaDemonstrativoOrgaoFuncao(
          dadosRelatorio.data.despesaDemonstrativoOrgaoFuncao,
          opcoes,
          currentClient,
          userAuthData,
          dataClientInfos,
          currentYear,
          dadosRelatorio.customData
        )}
      {dadosRelatorio.reportIds.includes(22) &&
        dadosRelatorio.data.despesaDemonstrativoRegionalizado &&
        RenderPdfDespesaDemonstrativoRegionalizado(
          dadosRelatorio.data.despesaDemonstrativoRegionalizado,
          opcoes,
          currentClient,
          userAuthData,
          dataClientInfos,
          currentYear,
          dadosRelatorio.customData
        )}
      {dadosRelatorio.reportIds.includes(23) &&
        dadosRelatorio.data.demoRiscosFiscaisProvidencias &&
        RenderPdfDemoRiscosFiscaisProvidencias(
          dadosRelatorio.data.demoRiscosFiscaisProvidencias,
          opcoes,
          currentClient,
          userAuthData,
          dataClientInfos,
          currentYear,
          dadosRelatorio.customData,
          "Lei de Diretrizes Orçamentárias"
        )}
    </Document>
  );
};
