import React, { useState, useEffect } from "react";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import { ToastContainer } from "react-toastify";
import { Container, Row } from "react-bootstrap";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import { CCol, RowAninhada } from "../../../../components/Grid/CCol";
import { InputImportDB } from "../../../../components/Inputs/InputImportDB";
import styled from "styled-components";
import ProgramaMenu from "./Menu/ImportarOrcamentoSave/ProgramaMenu";
import QuadroDespesaQDDMenu from "./Menu/ImportarOrcamentoSave/QuadroDespesaQDDMenu";
import QuadroReceitaQDRMenu from "./Menu/ImportarOrcamentoSave/QuadroReceitaQDRMenu";
import UnidadeOrcamentariaMenu from "./Menu/ImportarOrcamentoSave/UnidadeOrcamentariaMenu";
import AcaoGovernamentalMenu from "./Menu/ImportarOrcamentoSave/AcaoGovernamentalMenu";
import PCASPEstendidoMenu from "./Menu/ImplantarMatrizSaldosContabeis/PCASPEstendidoMenu";

const ProgressBarContainer = styled.div`
  position: relative;
  background-color: #cad9f4;
  width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
  border-radius: 4px;
`;

const ProgressBar = styled.div`
  background-color: #515c70;
  width: ${(props) => (props.percentage ? props.percentage : "0")}%;
  height: 100%;
  border-radius: 4px;
`;

const PercentageValue = styled.span`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translate(-50%, -50%);
  font-weight: 600;
  color: #515c70;
`;

const ProgressBarComponent = ({ percentage = 0 }) => {
  return (
    <>
      <ProgressBarContainer>
        <ProgressBar percentage={percentage}>
          <PercentageValue>{percentage ? percentage : "0"}%</PercentageValue>
        </ProgressBar>
      </ProgressBarContainer>
    </>
  );
};

const Span = styled.span`
  position: relative;
  font-size: 16px;
  font-weight: 600;
  margin: 0 15px;
  white-space: nowrap;
  cursor: pointer;
  text-align: center;

  &::after {
    content: "";
    position: absolute;
    bottom: -6px;
    left: 50%;
    transform: translateX(-50%);
    width: ${(props) => (props.active ? 100 : 0)}%;
    height: 2px;
    background-color: #515c70;
    transition: width 0.2s ease-in;
  }
`;

const RegisterBoxes = ({ boxTitle }) => {
  const [boxValue, setBoxValue] = useState("Unidade Orçamentária");

  useEffect(() => {
    setBoxValue(boxTitle && boxTitle[0]);
  }, [boxTitle]);

  return (
    <>
      <div
        style={{
          borderRadius: "8px 8px 0 0",
          border: "2px solid #E8E8E8",
          height: "38px",
          display: "flex",
          alignItems: "center",
          flexWrap: "nowrap",
        }}
      >
        {boxTitle &&
          boxTitle.map((item, index) => (
            <Span
              key={index}
              active={item === boxValue}
              onClick={() => setBoxValue(item)}
            >
              {item}
            </Span>
          ))}
      </div>
      <div
        style={{
          borderRadius: "0 0 8px 8px",
          border: "2px solid #E8E8E8",
          borderTop: "none",
          padding: "20px 15px",
        }}
      >
        {boxValue === "Unidade Orçamentária" && <UnidadeOrcamentariaMenu />}
        {boxValue === "Programa" && <ProgramaMenu />}
        {boxValue === "Ação Governamental" && <AcaoGovernamentalMenu />}
        {boxValue === "Quadro da Receita - QDR" && <QuadroReceitaQDRMenu />}
        {boxValue === "Quadro da Despesa - QDD" && <QuadroDespesaQDDMenu />}

        {boxValue === "PCASP Estendido" && <PCASPEstendidoMenu />}
      </div>
    </>
  );
};

const Importar = () => {
  const [option, setOption] = useState(1);
  const [boxTitle, setBoxTitle] = useState();

  useEffect(() => {
    if (option === 1) {
      setBoxTitle([
        "Unidade Orçamentária",
        "Programa",
        "Ação Governamental",
        "Quadro da Receita - QDR",
        "Quadro da Despesa - QDD",
      ]);
    } else if (option === 2) {
      setBoxTitle([
        "PCASP Estendido",
        "Função/SubFunção",
        "Natureza de Despesa",
        "Fonte de Recursos",
        "CO - Comple...",
        "Natureza da Receita",
      ]);
    }
  }, [option]);

  return (
    <Container
      id={"pags_responsividade_padding_geral"}
      fluid
      style={{ color: "#515C70" }}
      className={"container_conteudo conteudo_pagina"}
    >
      <HeaderCadastros
        NomePaginaTitulo={"Importar"}
        PaginaSubtitulo={"Cadastro"}
        BotaoSaveAtivo={true}
      />
      <ToastContainer />
      <Row className="row_form mt-1">
        <RowAninhada>
          <CCol>
            <FloatingLabelInput
              new={true}
              tipo="input_select"
              placeholder={"Importar Orçamento Save"}
              options={[
                { label: "Importar Orçamento Save", value: 1 },
                { label: "Implantar Matriz de Saldos Contábeis", value: 2 },
              ]}
              onSelect={(option) => setOption(option.value)}
            />
          </CCol>
          <CCol>
            <InputImportDB placeholder={"Pasta com arquivos para importação"} />
          </CCol>
        </RowAninhada>
        <RowAninhada className="mt-3">
          <CCol>
            <ProgressBarComponent percentage={60} />
          </CCol>
        </RowAninhada>
        <RowAninhada className="mt-3">
          <CCol>
            <RegisterBoxes boxTitle={boxTitle} />
          </CCol>
        </RowAninhada>
      </Row>
    </Container>
  );
};

export default Importar;
