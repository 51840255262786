import { Row, Col, Table } from "react-bootstrap";
import { useState } from "react";
import {
  BodyTable,
  FilterContainer,
  FilterLabel,
  FilterSelect,
  PassadorPage,
  TituloTable,
  TituloTableAction,
} from "../../../../components/TableConsulta/TableConsulta";
import InputSvg from "../../../../components/Grid/InputSvg";
import { Icon } from "@iconify/react";
import outlineSearch from "@iconify/icons-ic/outline-search";
import ButtonsAcoesListas from "../../../../styles/StyledComponents/ButtonsAcoesListas";
import Pagination from "../../../../components/PaginationComponent/Pagination";
import { ToastContainer } from "react-toastify";
import Modal from "react-modal";

const ListaAgencias = () => {
  Modal.setAppElement("#root");

  const [itens] = useState([]);
  const [itensPorPagina, setItensPorPagina] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchTerm] = useState("");
  const [itensFiltrados] = useState([]);
  const [loadingLista] = useState(false);

  //   const [modalOpened, setModalOpened] = useState(false);
  //   const [dataDelete, setDataDelete] = useState({});

  const startIndex = currentPage * itensPorPagina;
  const endIndex = startIndex + itensPorPagina;
  const currentItens = itens.slice(startIndex, endIndex);
  const currentFilteredItens = itensFiltrados.slice(startIndex, endIndex);

  const pages = Math.ceil(itens.length / itensPorPagina);
  const filteredPages = Math.ceil(itensFiltrados.length / itensPorPagina);

  return (
    <div>
      <ToastContainer />
      <Row className="row_form mt-1">
        <Col md={8}>
          <FilterContainer>
            <FilterLabel>Itens por página:</FilterLabel>
            <FilterSelect
              value={itensPorPagina}
              onChange={(event) =>
                setItensPorPagina(Number(event.target.value))
              }
            >
              <option value={10}>10</option>
              <option value={15}>15</option>
              <option value={20}>20</option>
            </FilterSelect>
          </FilterContainer>
        </Col>
        <Col md={4}>
          <InputSvg
            icon={outlineSearch}
            height={"23px"}
            color={"#6C7585"}
            placeholder={"pesquise"}
            // onChange={handleSearch}
          />
        </Col>
      </Row>
      <Row className="row_form mt-1">
        {loadingLista ? (
          <Col
            md={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Icon height={50} icon="line-md:loading-loop" color="#515c70" />
          </Col>
        ) : null}
        <Table
          responsive
          style={loadingLista ? { display: "none" } : { marginTop: "-10px" }}
        >
          <thead>
            <tr>
              <TituloTable>Código</TituloTable>
              <TituloTable>Agência</TituloTable>

              <TituloTable>Número da Agência</TituloTable>
              <TituloTableAction>Ação</TituloTableAction>
            </tr>
          </thead>
          <BodyTable>
            {searchTerm
              ? currentFilteredItens
                  .filter((item) => item.valor !== "00")
                  .map((item, index) => (
                    <tr key={index}>
                      <td style={{ whiteSpace: "nowrap" }}>{item.valor}</td>
                      <td title={item.nome} style={{ whiteSpace: "nowrap" }}>
                        {item.nome.length > 35
                          ? item.nome.substring(0, 35) + "..."
                          : item.nome}
                      </td>

                      <td
                        title={item.descricao}
                        style={{ whiteSpace: "nowrap" }}
                      >
                        {item.descricao.length > 60
                          ? item.descricao.substring(0, 60) + "..."
                          : item.descricao}
                      </td>

                      <td>
                        <ButtonsAcoesListas
                          IconDelete={false}
                          CaminhoPagUpdate={`/planejamento/cadastro/funcao-subfuncao/visualizar/${item.valor}`}
                        />
                      </td>
                    </tr>
                  ))
              : currentItens
                  .filter((item) => item.valor !== "00")
                  .map((item, index) => (
                    <tr key={index}>
                      <td style={{ whiteSpace: "nowrap" }}>{item.valor}</td>
                      <td title={item.nome} style={{ whiteSpace: "nowrap" }}>
                        {item.nome.length > 35
                          ? item.nome.substring(0, 35) + "..."
                          : item.nome}
                      </td>
                      <td
                        title={item.descricao}
                        style={{ whiteSpace: "nowrap" }}
                      >
                        {item.descricao.length > 90
                          ? item.descricao.substring(0, 90) + "..."
                          : item.descricao}
                      </td>
                      <td>
                        <ButtonsAcoesListas
                          IconDelete={false}
                          CaminhoPagUpdate={`/planejamento/cadastro/funcao-subfuncao/visualizar/${item.valor}`}
                        />
                      </td>
                    </tr>
                  ))}
          </BodyTable>
        </Table>
      </Row>
      <Row
        className="row_form mt-1"
        style={{
          borderBottomLeftRadius: "5px",
          borderBottomRightRadius: "5px",
        }}
      >
        <Col md={4}>
          <span>
            Mostrando de {startIndex + 1} a {endIndex} de{" "}
            {searchTerm ? itensFiltrados.length : itens.length} registros
          </span>
        </Col>
        <Col md={8}>
          <PassadorPage>
            <span>
              {currentPage + 1} de {searchTerm ? filteredPages : pages} páginas
            </span>

            <Pagination
              pages={searchTerm ? filteredPages : pages}
              itemsPerPage={itensPorPagina}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </PassadorPage>
        </Col>
      </Row>
      <div style={{ padding: "11px" }}></div>
    </div>
  );
};

export default ListaAgencias;
