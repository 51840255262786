// //import { calcularTotalReceitasComDeducao } from "../../../../LOA_QDR/RELATORIO_QDR/calcularReceitas";
// import api from "../../../../../../utils/api";

// export const getQuadroDetalhadoQDDAplicacao = async (clienteId, ano = 2024) => {
//   try {
//     const response = await api.get(
//       `qdd/relatorio-qdd/${clienteId}/${ano}?truncado=1`
//     );
//     console.log("response.data", response.data);
//     return processAllData(response.data);
//   } catch (error) {
//     console.error("Erro na requisição:", error);
//     return {
//       error: "error",
//       name: "Quadro Detalhado da Despesa - QDD por Modalidade de Aplicação",
//     };
//   }
// };

// const processAllData = (dataQddRelatorio) => {
//   let sumValues = 0;
//   const orderData = (data) => {
//     const sortAcoes = (unidade) => {
//       unidade.acoes.sort((a, b) => a.nAcao.localeCompare(b.nAcao));
//     };
//     data.forEach((unidade) => sortAcoes(unidade));
//     data.sort((a, b) =>
//       a.codigoUnidadeOrcamentaria.localeCompare(b.codigoUnidadeOrcamentaria)
//     );
//     return data;
//   };

//   const part1 = (data) => {
//     let filteredData = [];

//     data.forEach((item) => {
//       if (item.totalAcoesDaUnidade) {
//         sumValues += parseFloat(item.totalAcoesDaUnidade);
//       }
//       // Adiciona a unidade orçamentária
//       filteredData.push({
//         serial: null,
//         codigo: item.codigoUnidadeOrcamentaria,
//         titulo: item.tituloUnidadeOrcamentaria,
//         tipo: null,
//         valor: item.totalAcoesDaUnidade,
//       });
//       // Adiciona as ações da unidade
//       item.acoes.forEach((subItem) => {
//         filteredData.push(
//           {
//             serial: `${subItem.funcao.valor}.${subItem.subFuncao.valor}.${subItem.programaPlanejamentoId.numeroPrograma}`,
//             codigo: subItem.nAcao,
//             titulo: subItem.titulo,
//             tipo: null,
//             valor: subItem.valorTotalDaAcao,
//           },
//           {
//             serial: null,
//             codigo: null,
//             titulo: subItem.objetivos,
//             tipo: null,
//             valor: null,
//           }
//         );
//         // Adiciona despesas fixadas QDD
//         subItem.despesaFixadaQDD.forEach((subSubItem) => {
//           filteredData.push({
//             serial: null,
//             codigo: subSubItem.fonteDeRecurso.conta,
//             titulo: subSubItem.fonteDeRecurso.titulo,
//             tipo: null,
//             valor: subSubItem.totalPorFonte,
//           });

//           // Adiciona fichas QDD
//           subSubItem.fichasQDD.forEach((subSubSubItem) => {
//             filteredData.push({
//               serial: "",
//               codigo: subSubSubItem.codigo,
//               titulo: subSubSubItem.nome,
//               tipo: subSubSubItem.esfera,
//               valor: subSubSubItem.valor,
//             });
//           });
//         });
//       });
//     });
//     return filteredData;
//   };

//   const dadosRelatorio = part1(orderData(dataQddRelatorio));

//   return {
//     data: dadosRelatorio,
//     sum: sumValues,
//   };
// };

import api from "../../../../../../utils/api";
import { FormatValueToLocaleString } from "../../../../../../utils/FormatacaoDeDados/FormatValueToLocaleString";

import { calcularTotalUnidade } from "../../../../LOA_QDD/Relatorio/calcularTotalUnidade";
import { getUrl } from "../getUrlConsolidado";

export const getQuadroDetalhadoQDDAplicacao = async (
  clientes,
  ano = 2024,
  entidadesGestoras
) => {
  const url = getUrl(clientes, entidadesGestoras);
  try {
    const response = await api.get(
      `qdd/relatorio-qdd/${url.idsUrl}/${ano}?truncado=1&${url.filterConsolidado}`
    );

    return processAllData(response.data);
  } catch (error) {
    console.error("Erro na requisição:", error);
    return {
      error: "error",
      name: "Quadro Detalhado da Despesa - QDD por Modalidade de Aplicação",
    };
  }
};

export const processAllData = (dataQddRelatorioCatEconOrgao) => {
  dataQddRelatorioCatEconOrgao.sort((unidadeA, unidadeB) => {
    const codigoUnidadeA = parseFloat(
      unidadeA.codigoUnidadeOrcamentaria.replace(".", "")
    );
    const codigoUnidadeB = parseFloat(
      unidadeB.codigoUnidadeOrcamentaria.replace(".", "")
    );

    return codigoUnidadeA - codigoUnidadeB;
  });

  dataQddRelatorioCatEconOrgao.forEach((unidade) => {
    unidade.acoes.sort(
      (acaoA, acaoB) => parseInt(acaoA.nAcao) - parseInt(acaoB.nAcao)
    );
  });

  const totalUnidades = FormatValueToLocaleString(
    calcularTotalUnidade(dataQddRelatorioCatEconOrgao)
  );

  return {
    data: dataQddRelatorioCatEconOrgao,
    sum: totalUnidades,
  };
};

/* 
const part1 = (data) => {
    let filteredData = [];
    data.map((item) => {
      if (item.totalAcoesDaUnidade) {
        sumValues += parseFloat(item.totalAcoesDaUnidade);
      }
      //02.010 Gabinete do Prefeito - Exemplo
      filteredData.push({
        serial: null,
        codigo: item.codigoUnidadeOrcamentaria,
        titulo: item.tituloUnidadeOrcamentaria,
        tipo: null,
        valor: item.totalAcoesDaUnidade,
      });
      item.acoes.map((subItem) => {
        //04.122.2001 2003 Manutenção das Atividades do Gabinete do Prefeito  - Exemplo
        filteredData.push(
          {
            serial: `${subItem.funcao.valor}.${subItem.subFuncao.valor}.${subItem.programaPlanejamentoId.numeroPrograma}`,
            codigo: subItem.nAcao,
            titulo: subItem.titulo,
            tipo: null,
            valor: subItem.valorTotalDaAcao,
          },
          //Manter as atividades do Gabinete do Prefeito... - exemplo
          {
            serial: null,
            codigo: null,
            titulo: subItem.objetivos,
            tipo: null,
            valor: null,
          }
        );
        //1.500 Recursos não Vinculados de Impostos - Exemplo
        subItem.despesaFixadaQDD.map((subSubItem) => {
          filteredData.push({
            serial: null,
            codigo: subSubItem.fonteDeRecurso.conta,
            titulo: subSubItem.fonteDeRecurso.titulo,
            tipo: null,
            valor: subSubItem.totalPorFonte,
          });
          //3.3.90 Aplicações Diretas Fiscal - Exemplo
          subSubItem.fichasQDD.map((subSubSubItem) => {
            filteredData.push({
              serial: "",
              codigo: subSubSubItem.codigo,
              titulo: subSubSubItem.nome,
              tipo: subSubSubItem.esfera,
              valor: subSubSubItem.valor,
            });
            return "";
          });
          return "";
        });
        return "";
      });
      return "";
    });
    return filteredData;
  };
*/
