export const LOA = [
  {
    id: 1,
    titulo:
      "Anexo I - Demonstração da Receita e Despesa segundo as Categorias Econômicas",
    typeRelatorio: "receita",
  },
  {
    id: 2,
    titulo: "Anexo II - Receita Segundo a Categoria Econômica",
    typeRelatorio: "receita",
  },
  {
    id: 3,
    titulo: "Anexo II - Receita Segundo as Categorias Econômicas por Órgão",
    typeRelatorio: "receita",
  },
  {
    id: 4,
    titulo: "Natureza da Receita por Esfera",
    typeRelatorio: "receita",
  },
  {
    id: 5,
    titulo: "Natureza da Receita por Fontes ou Destinações de Recursos",
    typeRelatorio: "receita",
  },
  {
    id: 6,
    titulo: "Quadro Detalhado da Receita - QDR",
    typeRelatorio: "receita",
  },
  {
    id: 7,
    titulo: "Anexo II - Despesa Segundo as Categorias Econômicas",
    typeRelatorio: "despesa",
  },
  {
    id: 8,
    titulo: "Anexo II - Despesa Segundo as Categorias Econômicas - Órgão",
    typeRelatorio: "despesa",
  },
  {
    id: 9,
    titulo: "Natureza da Despesa por Esfera",
    typeRelatorio: "despesa",
  },
  {
    id: 10,
    titulo: "Natureza da Despesa por Fontes ou Destinações de Recursos",
    typeRelatorio: "despesa",
  },
  {
    id: 11,
    titulo: "Quadro Detalhado da Despesa - QDD por Elemento de Despesa",
    typeRelatorio: "despesa",
  },
  {
    id: 12,
    titulo: "Quadro Detalhado da Despesa - QDD por Modalidade de Aplicação",
    typeRelatorio: "despesa",
  },
  {
    id: 20,
    titulo:
      "Anexo V - Despesa por Classificação Funcional Programática Função Subfunção Programa Ações",
    typeRelatorio: "",
  },
  {
    id: 13,
    titulo: "Anexo VI - Programa de Trabalho - Órgão e Unidade Orçamentária",
    typeRelatorio: "",
  },
  {
    id: 14,
    titulo:
      "Anexo VII - Programa de Trabalho - Demonstrativo de Funções, Subfunções, Programas, Ações por Especiais, Projetos e Atividades",
    typeRelatorio: "",
  },
  {
    id: 15,
    titulo:
      "Anexo VIII - Demonstrativo da Despesa por Funções, Subfunções, Programas, Ações Conforme o Vínculo com os Recursos",
    typeRelatorio: "",
  },
  {
    id: 21,
    titulo: "Anexo IX - Demonstrativo da Despesa por Órgãos e Funções",
    typeRelatorio: "",
  },
  {
    id: 16,
    titulo: "Demonstrativo da Despesa por Unidade Orçamentária",
    typeRelatorio: "",
  },
  {
    id: 17,
    titulo: "Demonstrativo da Despesa por Ações Governamentais",
    typeRelatorio: "",
  },
  {
    id: 18,
    titulo: "Demonstrativo da Despesa por Programas Governamentais",
    typeRelatorio: "",
  },
  {
    id: 19,
    titulo:
      "Demonstrativo da Compatibilidade da Programação dos Orçamentos com os Objetivos e Metas",
    typeRelatorio: "",
  },
  {
    id: 22,
    titulo:
      "Demonstrativo Regionalizado do Efeito sobre Receitas e Despesa ",
    typeRelatorio: "",
  },
];

export const LDO = [
  {
    id: 23,
    titulo:
      "Demonstrativo de Riscos Fiscais e Providências – Anexos Riscos Fiscais",
    typeRelatorio: "",
  },
  {
    id: 24,
    titulo: "Demonstrativo 1 - Metas Anuais - Anexo Metas Fiscais",
    typeRelatorio: "",
  },
  {
    id: 25,
    titulo:
      "Demonstrativo 2 - Avaliação do Cumprimento das Metas Fiscais do Exercício Anterior - Anexo Metas Fiscais",
    typeRelatorio: "",
  },
  {
    id: 26,
    titulo:
      "Demonstrativo 3 - Metas Fiscais Atuais Comparadas com as Fixadas nos três Exercícios Anteriores - Anexo Metas Fiscais",
    typeRelatorio: "",
  },
  {
    id: 27,
    titulo:
      "Demonstrativo 4 - Evolução do Patrimônio Líquido - Anexo Metas Fiscais",
    typeRelatorio: "",
  },
  {
    id: 28,
    titulo:
      "Demonstrativo 5 - Origem e Aplicação dos Recursos Obtidos com a Alienação de Ativos - Anexo Metas Fiscais",
    typeRelatorio: "",
  },
  {
    id: 29,
    titulo:
      "Demonstrativo 6 - Avaliação da Situação Financeira e Atuarial do Regime Próprio de Previdência dos Servidores - Anexo Metas Fiscais",
    typeRelatorio: "",
  },
  {
    id: 30,
    titulo:
      "Demonstrativo 7 - Estimativa e Compensação da Renúncia de Receita - Anexo Metas Fiscais",
    typeRelatorio: "",
  },
  {
    id: 31,
    titulo:
      "Demonstrativo 8 - Margem de Expansão das Despesas Obrigatórias de Caráter Continuado - Anexo Metas Fiscais",
    typeRelatorio: "",
  },
  {
    id: 32,
    titulo:
      "Anexo IX - Prioridades e Metas da Administração Pública - Lei Federal 4.320/64",
    typeRelatorio: "",
  },
  {
    id: 33,
    titulo:
      "Anexo X - Compatibilidade da LDO com o Plano Plurianual - PPA - Lei Federal 4.320/64",
    typeRelatorio: "",
  },
  {
    id: 34,
    titulo:
      "Anexo XI - Estimativa da Receita Corrente Líquida - Lei Federal 4.320/64",
    typeRelatorio: "",
  },
];

//selects
export const SBAO = [
  {
    opcao: "Exibir",
    checkBoxes: [
      {
        label: "Código",
        value: "codigo",
        selected: true,
      },
      {
        label: "Descrição",
        value: "descricao",
        selected: true,
      },
    ],
  },
  {
    opcao: "Exibir",
    checkBoxes: [
      {
        label: "Código",
        value: "codigo",
        selected: true,
      },
      {
        label: "Especificações",
        value: "especificacoes",
        selected: true,
      },
      {
        label: "Desdobramento",
        value: "desdobramento",
        selected: true,
      },
      {
        label: "Fonte",
        value: "fonte",
        selected: true,
      },
      {
        label: "Categoria Econômica",
        value: "categoria economica",
        selected: true,
      },
    ],
  },
  {
    opcao: "Exibir",
    checkBoxes: [
      {
        label: "Código",
        value: "codigo",
        selected: true,
      },
      {
        label: "Especificações",
        value: "especificacoes",
        selected: true,
      },
      {
        label: "Desdobramento",
        value: "desdobramento",
        selected: true,
      },
      {
        label: "Fonte",
        value: "fonte",
        selected: true,
      },
      {
        label: "Categoria Econômica",
        value: "categoria economica",
        selected: true,
      },
    ],
  },
  {
    opcao: "Natureza por Esfera",
    checkBoxes: [
      {
        label: "Código",
        value: "codigo",
        selected: true,
      },
      {
        label: "Especificações",
        value: "especificacoes",
        selected: true,
      },
      {
        label: "Fiscal",
        value: "fiscal",
        selected: true,
      },
      {
        label: "Seguridade",
        value: "seguridade",
        selected: true,
      },
    ],
  },
  {
    opcao: "Exibir",
    checkBoxes: [
      {
        label: "Código",
        value: "codigo",
        selected: true,
      },
      {
        label: "Especificacao",
        value: "descricao",
        selected: true,
      },
      {
        label: "Valor",
        value: "valor",
        selected: true,
      },
    ],
  },
  {
    opcao: "Quadro Detalhado da Receita - QDR",
    checkBoxes: [
      {
        label: "Código",
        value: "codigo",
        selected: true,
      },
      {
        label: "Especificações",
        value: "especificacoes",
        selected: true,
      },
      {
        label: "Total",
        value: "total",
        selected: true,
      },
    ],
  },
  {
    opcao: "Despesa Segundo as Categorias Econômicas - Órgão",
    checkBoxes: [
      {
        label: "Código",
        value: "codigo",
        selected: true,
      },
      {
        label: "Especificações",
        value: "especificacoes",
        selected: true,
      },
      {
        label: "Desdobramento",
        value: "desdobramento",
        selected: true,
      },
      {
        label: "Grupo da Natureza",
        value: "grupoNatureza",
        selected: true,
      },
      {
        label: "Categoria Econômica",
        value: "categoriaEconomica",
        selected: true,
      },
    ],
  },
  {
    opcao: "Quadro Detalhado da Despesa - QDD",
    checkBoxes: [
      {
        label: "Código",
        value: "codigo",
        selected: true,
      },
      {
        label: "Especificações",
        value: "especificacoes",
        selected: true,
      },
      {
        label: "Esfera",
        value: "esfera",
        selected: true,
      },
      {
        label: "Total",
        value: "total",
        selected: true,
      },
    ],
  },
  {
    opcao: "Programa de Trabalho",
    checkBoxes: [
      {
        label: "Código",
        value: "codigo",
        selected: true,
      },
      {
        label: "Especificações",
        value: "especificacoes",
        selected: true,
      },
      {
        label: "Especiais",
        value: "especiais",
        selected: true,
      },
      {
        label: "Projetos",
        value: "projetos",
        selected: true,
      },
      {
        label: "Atividades",
        value: "atividade",
        selected: true,
      },
      {
        label: "Totais",
        value: "totais",
        selected: true,
      },
    ],
  },
  {
    opcao: "Despesa Vinculo",
    checkBoxes: [
      {
        label: "Código",
        value: "codigo",
        selected: true,
      },
      {
        label: "Especificações",
        value: "especificacoes",
        selected: true,
      },
      {
        label: "Ordinário",
        value: "ordinario",
        selected: true,
      },
      {
        label: "Vinculado",
        value: "vinculado",
        selected: true,
      },
      {
        label: "Total",
        value: "total",
        selected: true,
      },
    ],
  },
];
