// import api from "../../../../../../utils/api";

// export const getDespesaClassificacao = async (idsEntidades, ano = 2024) => {
//   const idsUrl = idsEntidades.ids.join(",");

//   try {
//     const response = await api.get(
//       `qdd/relatorio/agregado/${idsUrl}/${ano}?porAcao=1`
//     );

//     return formattedAllDespesas(response.data);
//   } catch (error) {
//     console.error("Erro na requisição:", error);
//     return {
//       error: "error",
//       name: "Anexo V - Despesa por Classificação Funcional Programática Função Subfunção Programa Ações",
//     };
//   }
// };

// const formattedAllDespesas = (dataQddRelatorioOrgaoUnidade) => {
//   const dadosFormatados = Object.keys(dataQddRelatorioOrgaoUnidade).map(
//     (key) => {
//       const unidade = dataQddRelatorioOrgaoUnidade[key];
//       console.log(unidade);

//       return {
//         infoUnidade: {
//           idUnidade: unidade.clienteInfo[0].id,
//           tipoNaturezaJuridica: unidade.clienteInfo[0].tipoNaturezaJuridica,
//           unidadeGestora: unidade.clienteInfo[0].pessoaJuridica.nomeFantasia,
//           totalGeralUnidade: {
//             totalProjetosCliente: unidade.totalProjetosCliente,
//             totalAtividadesCliente: unidade.totalAtividadesCliente,
//             totalEspeciaisCliente: unidade.totalEspeciaisCliente,
//           },
//           unidades: unidade.relatorio,
//         },
//       };
//     }
//   );

//   dadosFormatados.sort((a, b) => {
//     const tipoA = parseInt(a.infoUnidade.tipoNaturezaJuridica[0]);
//     const tipoB = parseInt(b.infoUnidade.tipoNaturezaJuridica[0]);
//     return tipoA - tipoB;
//   });
//   console.log(dadosFormatados);

//   return {
//     dataDespesa: dadosFormatados,
//     total: 0,
//   };
// };

import api from "../../../../../../utils/api";

export const getDespesaClassificacao = async (idsEntidades, ano = 2024) => {
  const idsUrl = idsEntidades.ids.join(",");

  try {
    const response = await api.get(`qdd/relatorio/agregado/${idsUrl}/${ano}`);

    return formattedAllDespesas(response.data);
  } catch (error) {
    console.error("Erro na requisição:", error);
    return {
      error: "error",
      name: "Anexo V - Despesa por Classificação Funcional Programática Função Subfunção Programa Ações",
    };
  }
};

// const calcularTotalGeralUnidades = (unidades) => {
//   console.log(unidades);

//   const totais = Object.keys(unidades).reduce((acc,unidadeKey)=>{
//     const unidadeOrcamentaria = unidades[unidadeKey]
//     console.log(unidadeKey);

//     console.log(unidadeOrcamentaria);

//     acc.totalDaUnidade += unidadeOrcamentaria.totalDaUnidade;
//       acc.totalEspeciaisUnidade += unidadeOrcamentaria.totalEspeciaisUnidade;
//       acc.totalProjetosUnidade += unidadeOrcamentaria.totalProjetosUnidade;
//       acc.totalAtividadesUnidade += unidadeOrcamentaria.totalAtividadesUnidade;
//     return acc
//   },{

//       totalDaUnidade: 0,
//       totalEspeciaisUnidade: 0,
//       totalProjetosUnidade: 0,
//       totalAtividadesUnidade: 0,

//   })

//   return totais;
// };

const calcularTotalGeral = (data) => {
  return data.reduce((totalGeral, item) => {
    const unidades = item.infoUnidade.unidades;
    // Percorre cada unidade e soma o totalDaUnidade
    const totalPorUnidade = Object.keys(unidades).reduce(
      (total, unidadeKey) => {
        const unidade = unidades[unidadeKey];
        return total + unidade.totalDaUnidade;
      },
      0
    );
    return totalGeral + totalPorUnidade;
  }, 0);
};

// const calculateTotalEntidades = (entidades) => {
//   console.log(entidades);

//   const total = entidades.reduce(
//     (acc, unidade) => {
//       acc.totalDaUnidade +=
//         unidade.infoUnidade.totalGeralUnidade.totalDaUnidade;
//       acc.totalEspeciaisUnidade +=
//         unidade.infoUnidade.totalGeralUnidade.totalEspeciaisUnidade;
//       acc.totalProjetosUnidade +=
//         unidade.infoUnidade.totalGeralUnidade.totalProjetosUnidade;
//       acc.totalAtividadesUnidade +=
//         unidade.infoUnidade.totalGeralUnidade.totalAtividadesUnidade;
//       return acc;
//     },
//     {
//       totalDaUnidade: 0,
//       totalEspeciaisUnidade: 0,
//       totalProjetosUnidade: 0,
//       totalAtividadesUnidade: 0,
//     }
//   );
//   console.log(total);

//   return total;
// };

const formattedAllDespesas = (dataQddRelatorioOrgaoUnidade) => {
  const dadosFormatados = Object.keys(dataQddRelatorioOrgaoUnidade).map(
    (key) => {
      const unidade = dataQddRelatorioOrgaoUnidade[key];
      return {
        infoUnidade: {
          unidades: unidade.unidadesOrcamentarias,
        },
      };
    }
  );

  const ordenarPorCodigoUnidadeOrcamentaria = (obj) => {
    return Object.values(obj).sort(
      (a, b) =>
        parseFloat(a.codigoUnidadeOrcamentaria.replace(".", "")) -
        parseFloat(b.codigoUnidadeOrcamentaria.replace(".", ""))
    );
  };

  dadosFormatados[0].infoUnidade.unidades = ordenarPorCodigoUnidadeOrcamentaria(
    dadosFormatados[0].infoUnidade.unidades
  );

  const totalEntidades = calcularTotalGeral(dadosFormatados);

  // console.log(dadosFormatados);

  return {
    dataDespesa: dadosFormatados,
    total: totalEntidades,
  };
};
