import { FormatValueToLocaleString } from "../../../../../../utils/FormatacaoDeDados/FormatValueToLocaleString";
import { calcularTotalReceitas } from "../../../../LOA_QDR/RELATORIO_QDR/calcularReceitas";
import api from "../../../../../../utils/api";
import { getUrl } from "../getUrlConsolidado";

export const getReceitasEsfera = async (
  clientes,
  entidadesGestoras,
  ano = 2024
) => {
  const url = getUrl(clientes, entidadesGestoras);
  try {
    const ids = clientes.ids.join(",");
    const response = await api.get(
      `qdr/relatorio/${ids}/${ano}${url.filterConsolidado}`
    );
    return processAllData(response.data);
  } catch (error) {
    console.error("Erro na requisição:", error);
    return {
      error: "error",
      name: "Natureza da Receita por Esfera",
    };
  }
};

const orderFonts = (naturezas) => {
  return naturezas.map((natureza) => {
    const { qdr } = natureza;

    if (qdr && qdr !== "vazio" && qdr.fontesDeRecursos) {
      qdr.fontesDeRecursos.sort((a, b) => a.conta.localeCompare(b.conta));
    }

    return natureza;
  });
};

const processAllData = (dataQdrRelatorio) => {
  console.log(dataQdrRelatorio);

  const codigosSectionRelatorio = ["1.0.0.0.00.0.0", "2.0.0.0.00.0.0"];
  const fonteComDeducao = 3;
  const fonteSemDeducao = 1;

  const getTotalEsfera = (dadosFiltrados) => {
    const dataSection = dadosFiltrados.filter((data) =>
      codigosSectionRelatorio.includes(data.codigo)
    );

    const total = dataSection.reduce(
      (acc, item) => {
        acc.fiscal += item.fiscal;
        acc.seguridade += item.seguridade;
        return acc;
      },
      { fiscal: 0, seguridade: 0 }
    );

    return total;
  };

  const processItem = (item, deducao) => {
    const resultado = [
      {
        codigo: item.nr,
        especificacao: item.especificacao,
        fiscal: item.totalFiscal,
        seguridade: item.totalSeguridade,
      },
    ];

    if (
      item.qdr &&
      item.qdr.fontesDeRecursos &&
      item.qdr.fontesDeRecursos.length > 0
    ) {
      const recursos = item.qdr.fontesDeRecursos
        .map((recurso) => {
          if (recurso.tipo === deducao) {
            return {
              codigo: recurso.conta,
              especificacao: recurso.titulo,
              fiscal:
                recurso.esfera.toLowerCase() === "fiscal"
                  ? recurso.valorPrevisto
                  : null,
              seguridade:
                recurso.esfera.toLowerCase() === "seguridade"
                  ? recurso.valorPrevisto
                  : null,
            };
          }
          return null;
        })
        .filter((recurso) => recurso !== null);
      resultado.push(...recursos);
    }

    return resultado;
  };

  const naturezas = orderFonts(dataQdrRelatorio.naturezas)
    .map((natureza) =>
      processItem(natureza, fonteSemDeducao, dataQdrRelatorio.naturezas)
    )
    .flat();

  const naturezasDeducao = orderFonts(dataQdrRelatorio.naturezasDeducao)
    .map((natureza) =>
      processItem(natureza, fonteComDeducao, dataQdrRelatorio.naturezasDeducao)
    )
    .flat();
  const dadosFiltrados = { naturezas, naturezasDeducao };

  const totalReceitas = calcularTotalReceitas(
    dataQdrRelatorio,
    codigosSectionRelatorio
  );

  const totalEsferaSemDeducao = getTotalEsfera(dadosFiltrados.naturezas);

  const totalReceitasDeducao = getTotalEsfera(dadosFiltrados.naturezasDeducao);

  const totalGeralReceitaEsfera = {
    fiscal: totalEsferaSemDeducao.fiscal - totalReceitasDeducao.fiscal,
    seguridade:
      totalEsferaSemDeducao.seguridade - totalReceitasDeducao.seguridade,
  };

  return {
    data: dadosFiltrados,
    sum: FormatValueToLocaleString(totalReceitas),
    totalReceitasDeducao,
    totalGeralReceitaEsfera,
  };
};
