import React from "react";
import { Page, View, Text } from "@react-pdf/renderer";

import { stylesPdf } from "../../../../../components/ComponenteDeRelatorio/Pdf/StyledPdfRelatorio";
import { PdfHeader } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfHeader";
import { PdfColunas } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfColunas";
import { PdfRodape } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfRodape";

import { FormatValueToLocaleString } from "../../../../../utils/FormatacaoDeDados/FormatValueToLocaleString";

export const RenderPdfDespesaQdd = (
  dados,
  opcoes,
  currentClient,
  userAuthData,
  dataClientInfos,
  currentYear,
  customData
) => {
  const optionReceitaQdr = opcoes.find(
    (option) => option.opcao === "Quadro Detalhado da Despesa - QDD"
  );

  const colNames = ["código", "especificações", "esfera", "total"];
  const styledCol = [
    { flexBasis: "145px", flexShrink: 1, flexGrow: 1, textAlign: "center" },
    { flexBasis: "100%", flexShrink: 1, flexGrow: 1, textAlign: "center" },
    {
      flexBasis: "105px",
      flexShrink: 1,
      flexGrow: 1,
      textAlign: "center",
    },
    {
      flexBasis: "140px",
      flexShrink: 1,
      flexGrow: 1,
      textAlign: "right",
    },
  ];

  const formatText = (text) => {
    return text.replace(/\n/g, " ");
  };

  const renderInfoUnidadeOrcamentaria = (item) => {
    return (
      <View
        style={[
          stylesPdf.colunas,
          { fontWeight: "bold", backgroundColor: "#a6a6a6", margin: "0 -3px" },
        ]}
        key={item.unidadeOrcamentariaId.id}
        fixed
      >
        <View style={[styledCol[0]]}>
          <Text
            style={[
              stylesPdf.textInfosRelatorio,
              {
                paddingRight: 0,
                paddingVertical: 2,
                textAlign: "left",
              },
            ]}
          >
            {item.codigoUnidadeOrcamentaria}
          </Text>
        </View>

        <View style={[styledCol[1]]}>
          <Text
            style={[
              stylesPdf.textInfosRelatorio,
              {
                paddingVertical: 2,
                textAlign: "left",
              },
            ]}
          >
            {item.tituloUnidadeOrcamentaria}
          </Text>
        </View>

        <View style={[styledCol[2]]}>
          <Text
            style={[
              stylesPdf.textInfosRelatorio,
              {
                paddingVertical: 2,
              },
            ]}
          >
            {`${""}`}
          </Text>
        </View>

        <View style={[styledCol[3]]}>
          <Text
            style={[
              stylesPdf.textInfosRelatorio,
              {
                textAlign: "right",
                paddingVertical: 2,
              },
            ]}
          >
            {`${FormatValueToLocaleString(
              parseFloat(item.totalAcoesDaUnidade)
            )}`}
          </Text>
        </View>
      </View>
    );
  };

  const renderInfoAcao = (item) => {
    return (
      <View
        style={[stylesPdf.colunas, { fontWeight: "bold", margin: "0 -3px" }]}
        key={item.acaoGovernamentalId}
      >
        <View
          style={[
            styledCol[0],
            {
              flexDirection: "row",
              justifyContent: "space-between",
              textAlign: "left",
            },
          ]}
          wrap={false}
        >
          <Text
            style={[
              stylesPdf.textInfosRelatorio,
              {
                paddingRight: 0,
                paddingVertical: 2,
                fontWeight: "normal",
              },
            ]}
          >
            {`${item.funcao.valor}.${item.subFuncao.valor}.${item.programaPlanejamentoId.numeroPrograma}`}
          </Text>
          <Text
            style={[
              stylesPdf.textInfosRelatorio,
              {
                paddingRight: 0,
                paddingVertical: 2,
              },
            ]}
          >
            {item.nAcao}
          </Text>
        </View>
        <View style={[styledCol[1]]} wrap={false}>
          <Text
            style={[
              stylesPdf.textInfosRelatorio,
              {
                paddingVertical: 2,
                textAlign: "left",
              },
            ]}
          >
            {item.titulo}
          </Text>
          <Text
            wrap={false}
            style={[
              stylesPdf.textInfosRelatorio,
              {
                paddingVertical: 2,
              },
            ]}
          >
            <Text style={{ fontWeight: "normal", textAlign: "justify" }}>
              {formatText(item.objetivos)}
            </Text>
          </Text>
        </View>
        <View style={[styledCol[2]]} wrap={false}>
          <Text
            style={[
              stylesPdf.textInfosRelatorio,
              {
                paddingVertical: 2,
              },
            ]}
          >
            {`${""}`}
          </Text>
        </View>
        <View style={[styledCol[3]]} wrap={false}>
          <Text
            style={[
              stylesPdf.textInfosRelatorio,
              {
                textAlign: "right",
                paddingVertical: 2,
              },
            ]}
          >
            {`${FormatValueToLocaleString(parseFloat(item.valorTotalDaAcao))}`}
          </Text>
        </View>
      </View>
    );
  };

  const renderInfosFonteDeRecurso = (item) => {
    return (
      <View
        style={[stylesPdf.colunas, { margin: "0 -3px" }]}
        key={item.fonteDeRecurso.id}
      >
        <View style={[styledCol[0]]} wrap={false}>
          <Text
            style={[
              stylesPdf.textInfosRelatorio,
              {
                paddingRight: 0,
                paddingVertical: 2,
                textAlign: "right",
              },
            ]}
          >
            {item.fonteDeRecurso.conta}
          </Text>
        </View>
        <View style={[styledCol[1]]} wrap={false}>
          <Text
            style={[
              stylesPdf.textInfosRelatorio,
              {
                paddingVertical: 2,
                textAlign: "left",
              },
            ]}
          >
            {item.fonteDeRecurso.titulo}
          </Text>
        </View>
        <View style={[styledCol[2]]} wrap={false}>
          <Text
            style={[
              stylesPdf.textInfosRelatorio,
              {
                paddingVertical: 2,
              },
            ]}
          >
            {`${""}`}
          </Text>
        </View>
        <View style={[styledCol[3]]} wrap={false}>
          <Text
            style={[
              stylesPdf.textInfosRelatorio,
              {
                textAlign: "right",
                paddingVertical: 2,
              },
            ]}
          >
            {`${FormatValueToLocaleString(parseFloat(item.totalPorFonte))}`}
          </Text>
        </View>
      </View>
    );
  };

  const renderInfosNaturezaDespesa = (item) => {
    return (
      <View
        style={[stylesPdf.colunas, { fontWeight: "bold", margin: "0 -3px" }]}
        key={item.id}
      >
        <View
          style={[
            styledCol[0],
            {
              flexDirection: "row",
              justifyContent: "space-between",
              textAlign: "left",
            },
          ]}
          wrap={false}
        >
          <Text
            style={[
              stylesPdf.textInfosRelatorio,
              {
                paddingVertical: 2,
              },
            ]}
          >
            {item.numeroFicha}
          </Text>
          <Text
            style={[
              stylesPdf.textInfosRelatorio,
              {
                paddingRight: 0,
                paddingVertical: 2,
              },
            ]}
          >
            {item.naturezaDaDespesa.codigo.slice(0, -3)}
          </Text>
        </View>
        <View style={[styledCol[1]]} wrap={false}>
          <Text
            style={[
              stylesPdf.textInfosRelatorio,
              {
                paddingVertical: 2,
                textAlign: "left",
              },
            ]}
          >
            {item.naturezaDaDespesa.nome}
          </Text>
        </View>
        <View style={[styledCol[2]]} wrap={false}>
          <Text
            style={[
              stylesPdf.textInfosRelatorio,
              {
                textAlign: "center",
                paddingVertical: 2,
              },
            ]}
          >
            {item.esfera}
          </Text>
        </View>
        <View style={[styledCol[3]]} wrap={false}>
          <Text
            style={[
              stylesPdf.textInfosRelatorio,
              {
                textAlign: "right",
                paddingVertical: 2,
              },
            ]}
          >
            {`${FormatValueToLocaleString(parseFloat(item.valor))}`}
          </Text>
        </View>
      </View>
    );
  };

  const renderInfosRelatorio = (item) => {
    return (
      <>
        {renderInfoUnidadeOrcamentaria(item)}

        {item.acoes.map((acao) => (
          <View key={acao.acaoGovernamentalId} style={{ marginBottom: "10px" }}>
            {renderInfoAcao(acao)}
            {acao.despesaFixadaQDD.map((ficha) => (
              <React.Fragment key={ficha.id}>
                {renderInfosFonteDeRecurso(ficha)}
                {ficha.fichasQDD.map((natureza) =>
                  renderInfosNaturezaDespesa(natureza)
                )}
              </React.Fragment>
            ))}
          </View>
        ))}

        {renderTotal(
          "subtotal",
          FormatValueToLocaleString(parseFloat(item.totalAcoesDaUnidade))
        )}
      </>
    );
  };

  const renderTotal = (typeTextTotal, total) => {
    return (
      <View
        wrap={false}
        style={[
          stylesPdf.divInferiorColuna,
          {
            fontWeight: "bold",
            fontSize: 10,
            textTransform: "uppercase",
            color: "white",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: 10,
            margin: "0 -3px",
          },
        ]}
      >
        <Text>{typeTextTotal}</Text>
        <Text>{total}</Text>
      </View>
    );
  };

  return (
    <Page orientation="portrait" size={"A4"} style={stylesPdf.page}>
      <PdfHeader
        currentClient={currentClient}
        plusText={"vazio"}
        nameRelatorio={
          "Quadro Detalhado da Despesa - QDD por Elemento de Despesa"
        }
        anoRelatorio={currentYear}
        dataClientInfos={dataClientInfos}
        customData={customData}
      />
      <View
        fixed
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          fontWeight: "bold",
          fontSize: "9px",
          padding: "0 4px",
        }}
      >
        <Text>Lei Federal 4.320/64</Text>
        <Text>R$ 1,00</Text>
      </View>
      <View style={[stylesPdf.colunas]} fixed>
        {optionReceitaQdr?.checkBoxes?.map(
          (each, index) =>
            each.selected && (
              <PdfColunas
                key={index}
                colName={colNames[index].toUpperCase()}
                styledCol={styledCol[index]}
                hideBar
              />
            )
        )}
      </View>
      <React.Fragment>
        <React.Fragment>
          {dados.data?.map((item, index) => (
            <View key={index} break={index !== 0}>
              {renderInfosRelatorio(item, index)}
            </View>
          ))}

          {renderTotal("total geral", dados.sum)}
        </React.Fragment>
      </React.Fragment>
      <PdfRodape currentClient={currentClient} userAuthData={userAuthData} />
    </Page>
  );
};
