import React, { useState } from "react";
import { CCol, ContainerToModal } from "../../../../components/Grid/CCol";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import { Row } from "react-bootstrap";
import styled from "styled-components";
import DespesaFixada from "./DespesaFixada";
import Saldos from "../../Components/Saldos";
import RowSelecaoAbasInferior from "../../../../components/Grid/RowSelecaoAbasInferior";

export const RowInfosAcao = styled(Row)`
  align-content: baseline;
  min-height: ${({ minHeight }) => minHeight || "80px"};
  margin: 0px;
  border-radius: 10px;
  background-color: #ebeaea;
  padding: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  resize: vertical;
  border-radius: 5px;
  border: 1px solid #ccc;
  position: relative;

  span {
    color: #73767e;
  }

  p {
    margin: 0px;
    margin-left: 10px;
    color: #515c70;
    font-weight: 500;
    font-size: 15.5px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  span + p {
    display: inline;
  }
`;

const QDD = () => {
  const [abaSelecionada, setAbaSelecionada] = useState({
    nomeAba: "Saldos",
    numeroAba: 1,
  });

  return (
    <ContainerToModal
      id={"pags_responsividade_padding_geral"}
      fluid
      className={"container_conteudo conteudo_pagina"}
    >
      <HeaderCadastros
        NomePaginaTitulo={"LOA"}
        PaginaSubtitulo={"QDD - Quadro Detalhado da Despesa"}
        RowTitle={"QDD - Quadro Detalhado da Despesa"}
        BotaoSaveAtivo={false}
        BotaoNovoAtivo={false}
      />
      <Row className="row_form mt-1">
        <CCol>
          <FloatingLabelInput
            label={"Número de Ação"}
            placeholder={"Ação"}
            disabled
          />
        </CCol>
      </Row>
      <Row className="row_form_2" style={{ marginTop: "0px" }}>
        <CCol md={12} style={{ marginTop: "-14px" }}>
          <RowInfosAcao>
            <CCol md={7}>
              <span>Unidade Orçamentária: </span> <p>{"Teste"}</p>
            </CCol>
            <CCol md={5}>
              <span>Função: </span>
              <p>{"Teste"}</p>
            </CCol>
            <CCol md={7} style={{ marginTop: "5px" }}>
              <span>Programa: </span>
              <p>{"Teste"}</p>
            </CCol>
            <CCol md={5} style={{ marginTop: "5px" }}>
              <span>Subfunção: </span>
              <p>{"Teste"}</p>
            </CCol>
          </RowInfosAcao>
        </CCol>
      </Row>
      <Row className="row_form_2" style={{ marginTop: "0px" }}>
        <CCol md={12} style={{ marginTop: "-14px" }}>
          <RowInfosAcao minHeight="50px">
            <CCol md={12}>
              <span>Unidade Orçamentária: </span> <p>{"Teste"}</p>
            </CCol>
          </RowInfosAcao>
        </CCol>
      </Row>
      <Row className="mt-3">
        <CCol
          className="d-flex align-items-center p-2 header_body_conteudo"
          md={12}
        >
          <span
            style={{
              fontSize: "16.5px",
              fontWeight: "600",
              marginLeft: "10px",
            }}
          >
            Despesa Fixada
          </span>
        </CCol>
      </Row>
      <DespesaFixada />
      <RowSelecaoAbasInferior
        abas={["Procedimentos Contábeis", "Saldos"]}
        abaSelecionada={abaSelecionada}
        setAbaSelecionada={setAbaSelecionada}
        style={{ marginTop: "-15px" }}
      />
      {abaSelecionada.numeroAba === 1 && (
        <>
          <Row className="mt-3">
            <CCol
              className="d-flex align-items-center p-2 header_body_conteudo"
              md={12}
            >
              <span
                style={{
                  fontSize: "16.5px",
                  fontWeight: "600",
                  marginLeft: "10px",
                }}
              >
                Saldos
              </span>
            </CCol>
          </Row>
          <Saldos
            data={{
              Fixado: "R$ 20.000,00",
              Empenhado: "R$ 20.000,00",
              Especiais: "R$ 20.000,00",
              Anulado: "R$ 20.000,00",
              Suplementado: "R$ 20.000,00",
              Pago: "R$ 20.000,00",
              Extraordinário: "R$ 20.000,00",
              Saldo: "R$ 20.000,00",
            }}
          />
        </>
      )}
      <div style={{ minHeight: "40px" }}> </div>
    </ContainerToModal>
  );
};

export default QDD;
