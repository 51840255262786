import React from "react";
import { Page, View, Text } from "@react-pdf/renderer";

import { stylesPdf } from "../../../../../components/ComponenteDeRelatorio/Pdf/StyledPdfRelatorio";
import { PdfHeader } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfHeader";
import { PdfColunas } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfColunas";
import { PdfInfos } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfInfos";
import { PdfRodape } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfRodape";
import { FormatValueToLocaleString } from "../../../../../utils/FormatacaoDeDados/FormatValueToLocaleString";

export const RenderPdfReceitaCatEconOrgao = (
  dados,
  opcoes,
  currentClient,
  userAuthData,
  dataClientInfos,
  currentYear,
  customData
) => {
  console.log(dados);
  console.log(opcoes);
  const codigosSectionRelatorio = ["1.0.0.0.00.0.0", "2.0.0.0.00.0.0"];
  const colNames = [
    "Código",
    "Especificações",
    "Desdobramento",
    "Fonte",
    "Categoria Econômica",
  ];
  const styledCol = [
    { flexBasis: "150px", flexShrink: 1, flexGrow: 1, textAlign: "center" },
    { flexBasis: "100%", flexShrink: 1, flexGrow: 1, textAlign: "center" },
    {
      flexBasis: "225px",
      flexShrink: 1,
      flexGrow: 1,
      textAlign: "center",
      
    },
    {
      flexBasis: "160px",
      flexShrink: 1,
      flexGrow: 1,
      textAlign: "right",
      paddingRight: 2,
      
    },
    {
      flexBasis: "160px",
      flexShrink: 1,
      flexGrow: 1,
      textAlign: "center",
      
    },
  ];

  const normalizeString = (str) => {
    return str.endsWith(".") ? str.slice(0, -1) : str;
  };

  const calcularTotalReceitasComDeducao = (naturezas) => {
    if (!naturezas.length) return 0;

    const receitasComDeducao = naturezas.filter((item) =>
      codigosSectionRelatorio.includes(item.codigo)
    );

    const totalReceitasComDeducao = receitasComDeducao.reduce((acc, item) => {
      return (acc += item.valor);
    }, 0);
    return FormatValueToLocaleString(totalReceitasComDeducao);
  };

  const renderSectionUnidade = (codigo, titulo) => {
    return (
      <View
        style={[
          stylesPdf.colunas,
          {
            gap: 0,
            backgroundColor: "#a6a6a6",
            fontWeight: "bold",
            marginBottom: 10,
          },
        ]}
      >
        {opcoes[1]?.checkBoxes[0].selected && (
          <PdfInfos
            pdfInfo={codigo || ""}
            styledCol={[
              styledCol[0],
              {
                padding: "2px 0",
                textAlign: "right",
                
              },
            ]}
          />
        )}
        {opcoes[1]?.checkBoxes[1].selected && (
          <PdfInfos
            pdfInfo={titulo || ""}
            styledCol={[styledCol[1], { padding: "2px 0" ,textAlign: "left",}]}
          />
        )}
        {opcoes[1]?.checkBoxes[2].selected && (
          <PdfInfos
            pdfInfo={""}
            styledCol={[
              styledCol[2],
              {
                padding: "2px 0",
                textAlign: "right",
                
              },
            ]}
          />
        )}
        {opcoes[1]?.checkBoxes[3].selected && (
          <PdfInfos
            pdfInfo={""}
            styledCol={[
              styledCol[3],
              {
                padding: "2px 0",
                textAlign: "center",
                
              },
              
            ]}
          />
        )}
        {opcoes[1]?.checkBoxes[4].selected && (
          <PdfInfos
            pdfInfo={""}
            styledCol={[
              styledCol[4],
              {
                padding: "2px 0",
                textAlign: "right",
                
              },
            ]}
          />
        )}
      </View>
    );
  };

  const renderReceitas = (natureza, key) => {
    const naturezaSection = codigosSectionRelatorio.includes(natureza.codigo);
    const fonteRecurso = natureza.codigo < codigosSectionRelatorio[0].length;

    return (
      <React.Fragment key={key}>
        <View
          style={[
            stylesPdf.colunas,
            {
              gap: 0,
              backgroundColor: naturezaSection ? "#a6a6a6" : "transparent",
              fontWeight: naturezaSection | fonteRecurso ? "bold" : "normal",
            },
          ]}
        >
          {opcoes[1]?.checkBoxes[0].selected && (
            <PdfInfos
              pdfInfo={natureza.codigo || ""}
              styledCol={[
                styledCol[0],
                {
                  padding: "2px 0",
                  textAlign: "right",
                  
                },
              ]}
            />
          )}
          {opcoes[1]?.checkBoxes[1].selected && (
            <PdfInfos
              pdfInfo={normalizeString(natureza.especificacao) || ""}
              styledCol={[styledCol[1], { padding: "2px 0",textAlign: "left", }]}
            />
          )}
          {opcoes[1]?.checkBoxes[2].selected && (
            <PdfInfos
              pdfInfo={
                natureza.desdobramento
                  ? FormatValueToLocaleString(
                      parseFloat(natureza.desdobramento)
                    )
                  : ""
              }
              styledCol={[styledCol[2],
                {
                  padding: "2px 0",
                  textAlign: "right",
                  // flexBasis: "180px",
                  // flexShrink: 1,
                  // flexGrow: 1,
                  
                },
              ]}
            />
          )}
          {opcoes[1]?.checkBoxes[3].selected && (
            <PdfInfos
              pdfInfo={
                natureza.fonte ? FormatValueToLocaleString(natureza.fonte) : ""
              }
              styledCol={[
                styledCol[3],
                {
                  padding: "2px 0",
                  textAlign: "right",
                  fontWeight: "bold",
                  
                },
              ]}
            />
          )}
          {opcoes[1]?.checkBoxes[4].selected && (
            <PdfInfos
              pdfInfo={
                natureza.catEcon
                  ? FormatValueToLocaleString(natureza.catEcon)
                  : ""
              }
              styledCol={[
                styledCol[4],
                {
                  padding: "2px 0",
                  textAlign: "right",
                  
                },
              ]}
            />
          )}
        </View>
      </React.Fragment>
    );
  };
  const renderTotal = (nameTotalType,total) => {
    return (
      <>
        <View
          wrap={false}
          style={[
            stylesPdf.divInferiorColuna,
            {
              fontWeight: "bold",
              fontSize: 10,
              textTransform: "uppercase",
              color: "white",
              flexDirection: "row",
              justifyContent: "space-between",
              marginBottom: 10,
            },
          ]}
        >
          <Text>{nameTotalType}</Text>
          <Text>{total}</Text>
        </View>
        <View break />
      </>
    );
  };

  return (
    <Page orientation="portrait" size={"A4"} style={stylesPdf.page}>
      <PdfHeader
        currentClient={currentClient}
        nameRelatorio={"Receita Segundo as Categorias Econômicas por Órgão"}
        plusText={"Anexo II"}
        anoRelatorio={currentYear}
        dataClientInfos={dataClientInfos}
        customData={customData}
      />
      <View
        fixed
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          fontWeight: "bold",
          fontSize: "9px",
          padding: "0 4px",
        }}
      >
        <Text>Lei Federal 4.320/64</Text>
        <Text>R$ 1,00</Text>
      </View>
      <View style={[stylesPdf.colunas]} fixed>
        {opcoes[1]?.checkBoxes?.map(
          (each, index) =>
            each.selected && (
              <PdfColunas
                key={index}
                colName={colNames[index].toUpperCase()}
                styledCol={styledCol[index]}
                hideBar
              />
            )
        )}
      </View>
      <View style={stylesPdf.divRelatorio}>
        <View style={{ margin: "0 -3px" }}>
          {dados?.data?.map((item, itemIndex) => (
            <React.Fragment key={itemIndex}>
              {item.naturezas.length > 0 && (
                <View fixed={dados.data.length === 1}>
                  {renderSectionUnidade(
                    item.infoCliente.codigo,
                    item.infoCliente.especificacao
                  )}
                </View>
              )}
              {item.naturezas.map((natureza, naturezaIndex) =>
                renderReceitas(natureza, `${itemIndex}-${naturezaIndex}`)
              )}
              <View style={{ marginVertical: 5 }} />
              {item.naturezasDeducao.length > 0 && (
                <>
                  <View
                    wrap={false}
                    style={[
                      stylesPdf.barTitleInfos,
                      {
                        gap: 0,
                        // marginBottom: 10,
                        fontSize: 9,
                        backgroundColor: "#a6a6a6",
                      },
                    ]}
                  >
                    <View
                      style={[
                        stylesPdf.containerTitleInfos,
                        { paddingLeft: 0 },
                      ]}
                    >
                      <Text style={stylesPdf.textInfosRelatorio}>
                        Contribuição para Formação do FUNDEB - DEDUÇÃO
                      </Text>
                    </View>
                    <Text
                      style={[
                        stylesPdf.textTotalInfos,
                        {
                          color: "#000",
                          paddingRight: 8,
                        },
                      ]}
                    >
                      {calcularTotalReceitasComDeducao(item.naturezasDeducao)}
                    </Text>
                  </View>
                  {item.naturezasDeducao.map((natureza, indexNatDeducao) =>
                    renderReceitas(natureza, `${itemIndex}-${indexNatDeducao}`)
                  )}
                  {renderTotal(
                    dados.data.length === 1 ? 'total' : 'subtotal',FormatValueToLocaleString(item.infoCliente.total)
                  )}
                </>
              )}
            </React.Fragment>
          ))}

          {dados.data.length > 1 && renderTotal('total geral',dados.sum)}
        </View>
      </View>
      <PdfRodape currentClient={currentClient} userAuthData={userAuthData} />
    </Page>
  );
};
