import React, { useEffect, useState } from "react";
import {
  CCol,
  CColButtonsAcoes2Icons,
  RowAninhada,
  RowForm,
  RowToModal,
} from "../../../../components/Grid/CCol";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import MyAutoComplete from "../../../../components/Inputs/MyAutoComplete";
import InputData from "../../../../components/Grid/InputData";
import { OptionsStyle } from "../../../Paginas_Sistemas_Planejamento/Cadastros/FonteDeRecursos/FonteDeRecursos";
import {
  FormControlListaDeInputs,
  PTitulosInputs,
} from "../../../../styles/StyledComponents/ListaDeInputs";
import InputFormatRealBrasileiro from "../../../../components/Grid/InputFormatRealBrasileiro";
import api from "../../../../utils/api";
import { ReturnDataAtual } from "../../../../utils/Gerais/ReturnDataAtual";
import { toast, ToastContainer } from "react-toastify";
import IconAdicionar from "../../../../components/ListaDeInputs/IconAdicionar";
import IconDelete from "../../../../components/ListaDeInputs/IconDelete";

const tiposDeVinculo = [
  { label: "00 - Demais", value: "00" },
  { label: "10 - Saúde", value: "10" },
  { label: "12 - Educação", value: "12" },
  { label: "08 - Assistência Social", value: "08" },
  { label: "09 - Previdência Social", value: "09" },
];

const tiposConta = [
  { label: "Conta Corrente", value: "1" },
  { label: "Conta Corrente - Fopag", value: "2" },
  { label: "Conta Investimento - Títulos Públicos", value: "3" },
  { label: "Conta Investimento - Poupança", value: "4" },
  { label: "Conta Investimento - Fundo de Investimento", value: "5" },
  { label: "Conta Investimento - CDB", value: "6" },
  {
    label:
      "Conta Investimento - Outras Aplicações Financeiras de Liquidez Imediata",
    value: "7",
  },
];

const ContaBancariaComponent = ({
  abaSecundariaSelecionada,
  setAbaSecundariaSelecionada,
}) => {
  const dataAtual = ReturnDataAtual();
  const ano = dataAtual.split("/")[2];
  const [todasFontesDeRceursos, setTodasFontesDeRecursos] = useState([]);
  const [fontesDeRecursosFiltradas, setFontesDeRecursosFiltradas] = useState(
    []
  );
  const [fonteParaAdicionar, setFonteParaAdicionar] = useState({
    id: null,
    valor: 0,
  });
  const [valuesContaBancaria, setValuesContaBancaria] = useState({
    agenciaId: "",
    data: dataAtual,
    numeroConta: "",
    digito: "",
    tipoDeConta: "1",
    tipoDeVinculo: "00",
    fontesDeRecursos: [],
  });

  //
  const [inputFonteValue, setInputFonteValue] = useState("");

  const fetchFontesDeRecursos = async () => {
    try {
      const resp = await api.get(`fonteDeRecurso/por-ano/${ano}`);
      const data = resp.data;

      setTodasFontesDeRecursos(
        data.map((item) => ({
          id: item.id,
          conta: item.conta,
          titulo: item.titulo,
          descricao: item.descricao,
          funcao: item.funcao,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeVinculo = (option) => {
    setValuesContaBancaria((prev) => ({
      ...prev,
      tipoDeVinculo: option.value,
    }));
    setValuesContaBancaria((prev) => ({
      ...prev,
      fontesDeRecursos: [],
    }));
  };

  const adicionarFonteDeRecursos = () => {
    console.log(Object.values(fonteParaAdicionar));

    if (
      Object.values(fonteParaAdicionar).every(
        (item) => item !== 0 && item !== "" && item !== null
      ) &&
      !valuesContaBancaria.fontesDeRecursos.some(
        (item) => item.id === fonteParaAdicionar.id
      )
    ) {
      setValuesContaBancaria((prev) => ({
        ...prev,
        fontesDeRecursos: [...prev.fontesDeRecursos, fonteParaAdicionar],
      }));
    } else if (
      valuesContaBancaria.fontesDeRecursos.some(
        (item) => item.id === fonteParaAdicionar.id
      )
    ) {
      toast.error("Essa fonte de recursos já foi adicionada na lista.");
    } else {
      toast.error(
        "Preencha todos os campos corretamente para adicionar uma fonte de recursos."
      );
    }
  };

  const deletarFonte = (id) => {
    setValuesContaBancaria((prev) => ({
      ...prev,
      fontesDeRecursos: prev.fontesDeRecursos.filter((item) => item.id !== id),
    }));
  };

  const handleSelectFonte = (option) => {
    setFonteParaAdicionar((prev) => ({
      ...prev,
      id: option.id,
    }));
  };

  useEffect(() => {
    fetchFontesDeRecursos();

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (todasFontesDeRceursos.length > 0) {
      const arrayFiltrado = todasFontesDeRceursos.filter((item) => {
        const tipoCleaned = item.funcao.replace(/[^0-9]/g, "");

        return tipoCleaned === valuesContaBancaria.tipoDeVinculo;
      });

      console.log(arrayFiltrado);

      setFontesDeRecursosFiltradas(arrayFiltrado);
    }
  }, [valuesContaBancaria.tipoDeVinculo, todasFontesDeRceursos]);

  return (
    <>
      <ToastContainer />
      <RowToModal className={"row_form mt-1"}>
        <CCol>
          <MyAutoComplete
            labelInput="Agência"
            placeholder="Agência"
            options={[{ label: "Teste", value: 1 }]}
            labelFormat={(option) => `${option.label} - ${option.value}`}
            style={{ height: "38px" }}
          />
        </CCol>
        <CCol md={2}>
          <InputData externalValue={valuesContaBancaria.data} />
        </CCol>
      </RowToModal>
      <RowToModal className={"row_form"} style={{ marginTop: "-15px" }}>
        <CCol md={2}>
          <FloatingLabelInput label="Nº Conta" placeholder="Nº Conta" />
        </CCol>
        <CCol md={1}>
          <FloatingLabelInput label="D" placeholder="D" />
        </CCol>
        <CCol>
          <FloatingLabelInput label="Nome" placeholder="Nome" />
        </CCol>
      </RowToModal>
      <RowForm style={{ marginTop: "-15px" }}>
        <CCol>
          <FloatingLabelInput
            tipo="input_select"
            new
            options={tiposConta}
            value={valuesContaBancaria.tipoDeConta}
            label={"Tipo de Conta"}
          />
        </CCol>
        <CCol md={3}>
          <FloatingLabelInput
            tipo="input_select"
            new
            options={tiposDeVinculo}
            onSelect={handleChangeVinculo}
            value={valuesContaBancaria.tipoDeVinculo}
            label={"Tipo de Vinculo"}
          />
        </CCol>
      </RowForm>

      <RowToModal
        style={{ backgroundColor: "#fff", borderRadius: "0px 0px 5px 5px" }}
        className={"mt-1"}
      >
        <CCol style={{ display: "flex", alignItems: "center", padding: "0px" }}>
          <OptionsStyle
            selecionado={abaSecundariaSelecionada === 0}
            onClick={() => setAbaSecundariaSelecionada(0)}
          >
            Fonte de Recursos / CO
          </OptionsStyle>
          <OptionsStyle
            selecionado={abaSecundariaSelecionada === 1}
            onClick={() => setAbaSecundariaSelecionada(1)}
          >
            Outras Informações
          </OptionsStyle>
        </CCol>
      </RowToModal>

      {abaSecundariaSelecionada === 0 && (
        <>
          <RowToModal
            className={"row_form"}
            style={{
              marginTop: "12px",
              borderRadius: "5px 5px 0px 0px",
              padding: "8px 10px",
            }}
          >
            <CCol
              style={{
                fontSize: "16px",
                color: "#515c70",
                fontWeight: "700",
              }}
            >
              Fonte de Recursos / CO
            </CCol>
          </RowToModal>

          <RowForm $borderRadiusType={2} className={"mt-1"}>
            <CCol>
              <PTitulosInputs>
                Orientações para utilização Fontes ou Destinações de Recursos -
                Exclusivas da Conta Bancária
              </PTitulosInputs>
            </CCol>
            <CCol md={2}>
              <PTitulosInputs>Valor</PTitulosInputs>
            </CCol>
            <CColButtonsAcoes2Icons md={1}>
              <PTitulosInputs>Ação</PTitulosInputs>
            </CColButtonsAcoes2Icons>

            <RowAninhada>
              <CCol>
                <MyAutoComplete
                  radiusborder={
                    valuesContaBancaria.fontesDeRecursos.length > 0
                      ? "5px 5px 0px 0px "
                      : "5px"
                  }
                  inputValue={inputFonteValue}
                  setInputValue={setInputFonteValue}
                  options={fontesDeRecursosFiltradas}
                  labelFormat={(option) => `${option.conta} - ${option.titulo}`}
                  onOptionSelect={handleSelectFonte}
                />
              </CCol>
              <CCol md={2}>
                <InputFormatRealBrasileiro
                  radiusborder={
                    valuesContaBancaria.fontesDeRecursos.length > 0
                      ? "5px 5px 0px 0px "
                      : "5px"
                  }
                  listaDeInputs={true}
                  externalValue={fonteParaAdicionar.valor}
                  onChange={(e, valor) =>
                    setFonteParaAdicionar((prev) => ({
                      ...prev,
                      valor: valor,
                    }))
                  }
                />
              </CCol>
              <CColButtonsAcoes2Icons md={1}>
                <IconAdicionar
                  onClick={adicionarFonteDeRecursos}
                  height="28"
                  style={{
                    cursor: "pointer",
                    marginLeft: "5px",
                    marginTop: "1px",
                  }}
                />
              </CColButtonsAcoes2Icons>
              {/* <button
                onClick={() =>
                  console.log(fontesDeRecursos, todasFontesDeRceursos)
                }
              >
                console
              </button> */}
            </RowAninhada>

            {valuesContaBancaria.fontesDeRecursos.map((fonte, index, array) => (
              <RowAninhada key={index} style={{ marginTop: "-1px" }}>
                <CCol>
                  <FormControlListaDeInputs
                    radiusborder={
                      array.length - 1 === index ? "0px 0px 5px 5px" : "0px"
                    }
                    value={`${
                      todasFontesDeRceursos.find((item) => item.id === fonte.id)
                        ?.conta
                    } - ${
                      todasFontesDeRceursos.find((item) => item.id === fonte.id)
                        ?.titulo
                    }`}
                    disabled
                  />
                </CCol>
                <CCol md={2}>
                  <InputFormatRealBrasileiro
                    radiusborder={
                      array.length - 1 === index ? "0px 0px 5px 5px" : "0px"
                    }
                    listaDeInputs
                    externalValue={fonte.valor}
                  />
                </CCol>
                <CColButtonsAcoes2Icons md={1}>
                  <IconDelete
                    height="28"
                    onClick={() => deletarFonte(fonte.id)}
                    style={{
                      cursor: "pointer",
                      marginLeft: "5px",
                      marginTop: "1px",
                    }}
                  />
                </CColButtonsAcoes2Icons>
              </RowAninhada>
            ))}
          </RowForm>
        </>
      )}

      {abaSecundariaSelecionada === 1 && (
        <>
          <RowForm
            style={{
              marginTop: "12px",
              borderRadius: "5px 5px 0px 0px",
              padding: "8px 10px",
            }}
          >
            <CCol
              style={{
                fontSize: "16px",
                color: "#515c70",
                fontWeight: "700",
              }}
            >
              Outras Informações
            </CCol>
          </RowForm>

          <RowForm className="mt-1">
            <CCol>
              <MyAutoComplete
                labelInput="Conta Corrente"
                placeholder="Conta Corrente"
                options={[{ label: "Teste", value: 1 }]}
                labelFormat={(option) => `${option.label} - ${option.value}`}
                style={{ height: "38px" }}
              />
            </CCol>
            <CCol md={3}>
              <MyAutoComplete
                labelInput="Convênio"
                placeholder="Convênio"
                options={[{ label: "Teste", value: 1 }]}
                labelFormat={(option) => `${option.label} - ${option.value}`}
                style={{ height: "38px" }}
              />
            </CCol>
            <CCol md={2}>
              <InputData />
            </CCol>
          </RowForm>
        </>
      )}
    </>
  );
};

export default ContaBancariaComponent;
