import React from "react";
import { Page, View, Text } from "@react-pdf/renderer";

import { stylesPdf } from "../../../../../components/ComponenteDeRelatorio/Pdf/StyledPdfRelatorio";
import { PdfHeader } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfHeader";
import { PdfInfos } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfInfos";
import { PdfRodape } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfRodape";
import { FormatValueToLocaleString } from "../../../../../utils/FormatacaoDeDados/FormatValueToLocaleString";

const mockData = [
  {
    infoUnidade: {
      receitas: [
        {
          receitaInfos: {
            nameReceita: "Receitas Correntes ( I )",
            totalLdo: "25.000,00",
            totalPloa: "25.000,00",
            diferenca: {
              TotalReal: "25.000,00",
              porcentagem: "25",
            },
          },
          despesas: [
            {
              titulo: "Impostos, Taxas e Contribuições de Melhoria ",
              despesaInfos: {
                totalLdo: "25.000,00",
                totalPloa: "25.000,00",
                diferenca: {
                  TotalReal: "25.000,00",
                  porcentagem: "25",
                },
              },
            },
            {
              titulo: "Contribuições ",
              despesaInfos: {
                totalLdo: "25.000,00",
                totalPloa: "25.000,00",
                diferenca: {
                  TotalReal: "25.000,00",
                  porcentagem: "25",
                },
              },
            },
            {
              titulo: "Receita Patrimonial ",
              despesaInfos: {
                totalLdo: "25.000,00",
                totalPloa: "25.000,00",
                diferenca: {
                  TotalReal: "25.000,00",
                  porcentagem: "25",
                },
              },
            },
            {
              titulo: "Transferências Correntes",
              despesaInfos: {
                totalLdo: "25.000,00",
                totalPloa: "25.000,00",
                diferenca: {
                  TotalReal: "25.000,00",
                  porcentagem: "25",
                },
              },
            },
            {
              titulo: "Outras Receitas Correntes",
              despesaInfos: {
                totalLdo: "25.000,00",
                totalPloa: "25.000,00",
                diferenca: {
                  TotalReal: "25.000,00",
                  porcentagem: "25",
                },
              },
            },
          ],
        },
        {
          receitaInfos: {
            nameReceita: "Receitas de Capital ( II )",
            totalLdo: "25.000,00",
            totalPloa: "25.000,00",
            diferenca: {
              TotalReal: "25.000,00",
              porcentagem: "25",
            },
          },
          despesas: [
            {
              titulo: "Impostos, Taxas e Contribuições de Melhoria ",
              despesaInfos: {
                totalLdo: "25.000,00",
                totalPloa: "25.000,00",
                diferenca: {
                  TotalReal: "25.000,00",
                  porcentagem: "25",
                },
              },
            },
            {
              titulo: "Contribuições ",
              despesaInfos: {
                totalLdo: "25.000,00",
                totalPloa: "25.000,00",
                diferenca: {
                  TotalReal: "25.000,00",
                  porcentagem: "25",
                },
              },
            },
            {
              titulo: "Receita Patrimonial ",
              despesaInfos: {
                totalLdo: "25.000,00",
                totalPloa: "25.000,00",
                diferenca: {
                  TotalReal: "25.000,00",
                  porcentagem: "25",
                },
              },
            },
            {
              titulo: "Transferências Correntes",
              despesaInfos: {
                totalLdo: "25.000,00",
                totalPloa: "25.000,00",
                diferenca: {
                  TotalReal: "25.000,00",
                  porcentagem: "25",
                },
              },
            },
            {
              titulo: "Outras Receitas Correntes",
              despesaInfos: {
                totalLdo: "25.000,00",
                totalPloa: "25.000,00",
                diferenca: {
                  TotalReal: "25.000,00",
                  porcentagem: "25",
                },
              },
            },
          ],
        },
        {
          receitaInfos: {
            nameReceita: "Receitas Financeiras ( IV )",
            totalLdo: "25.000,00",
            totalPloa: "25.000,00",
            diferenca: {
              TotalReal: "25.000,00",
              porcentagem: "25",
            },
          },
          despesas: [
            {
              titulo: "Aplicação Financeira ",
              despesaInfos: {
                totalLdo: "25.000,00",
                totalPloa: "25.000,00",
                diferenca: {
                  TotalReal: "25.000,00",
                  porcentagem: "25",
                },
              },
            },
            {
              titulo: "Operações de Crédito ",
              despesaInfos: {
                totalLdo: "25.000,00",
                totalPloa: "25.000,00",
                diferenca: {
                  TotalReal: "25.000,00",
                  porcentagem: "25",
                },
              },
            },
            {
              titulo: "Alienação de Bens ",
              despesaInfos: {
                totalLdo: "25.000,00",
                totalPloa: "25.000,00",
                diferenca: {
                  TotalReal: "25.000,00",
                  porcentagem: "25",
                },
              },
            },
            {
              titulo: "Amortização de Empréstimos",
              despesaInfos: {
                totalLdo: "25.000,00",
                totalPloa: "25.000,00",
                diferenca: {
                  TotalReal: "25.000,00",
                  porcentagem: "25",
                },
              },
            },
            {
              titulo: "Outras Receitas de Capital",
              despesaInfos: {
                totalLdo: "25.000,00",
                totalPloa: "25.000,00",
                diferenca: {
                  TotalReal: "25.000,00",
                  porcentagem: "25",
                },
              },
            },
          ],
        },
      ],
      despesas: [
        {
          receitaInfos: {
            nameReceita: "Despesas Correntes ( I )",
            totalLdo: "25.000,00",
            totalPloa: "25.000,00",
            diferenca: {
              TotalReal: "25.000,00",
              porcentagem: "25",
            },
          },
          despesas: [
            {
              titulo: "Impostos, Taxas e Contribuições de Melhoria ",
              despesaInfos: {
                totalLdo: "25.000,00",
                totalPloa: "25.000,00",
                diferenca: {
                  TotalReal: "25.000,00",
                  porcentagem: "25",
                },
              },
            },
            {
              titulo: "Contribuições ",
              despesaInfos: {
                totalLdo: "25.000,00",
                totalPloa: "25.000,00",
                diferenca: {
                  TotalReal: "25.000,00",
                  porcentagem: "25",
                },
              },
            },
            {
              titulo: "Receita Patrimonial ",
              despesaInfos: {
                totalLdo: "25.000,00",
                totalPloa: "25.000,00",
                diferenca: {
                  TotalReal: "25.000,00",
                  porcentagem: "25",
                },
              },
            },
            {
              titulo: "Transferências Correntes",
              despesaInfos: {
                totalLdo: "25.000,00",
                totalPloa: "25.000,00",
                diferenca: {
                  TotalReal: "25.000,00",
                  porcentagem: "25",
                },
              },
            },
            {
              titulo: "Outras Receitas Correntes",
              despesaInfos: {
                totalLdo: "25.000,00",
                totalPloa: "25.000,00",
                diferenca: {
                  TotalReal: "25.000,00",
                  porcentagem: "25",
                },
              },
            },
          ],
        },
        {
          receitaInfos: {
            nameReceita: "Despesas de Capital ( II )",
            totalLdo: "25.000,00",
            totalPloa: "25.000,00",
            diferenca: {
              TotalReal: "25.000,00",
              porcentagem: "25",
            },
          },
          despesas: [
            {
              titulo: "Impostos, Taxas e Contribuições de Melhoria ",
              despesaInfos: {
                totalLdo: "25.000,00",
                totalPloa: "25.000,00",
                diferenca: {
                  TotalReal: "25.000,00",
                  porcentagem: "25",
                },
              },
            },
            {
              titulo: "Contribuições ",
              despesaInfos: {
                totalLdo: "25.000,00",
                totalPloa: "25.000,00",
                diferenca: {
                  TotalReal: "25.000,00",
                  porcentagem: "25",
                },
              },
            },
            {
              titulo: "Receita Patrimonial ",
              despesaInfos: {
                totalLdo: "25.000,00",
                totalPloa: "25.000,00",
                diferenca: {
                  TotalReal: "25.000,00",
                  porcentagem: "25",
                },
              },
            },
            {
              titulo: "Transferências Correntes",
              despesaInfos: {
                totalLdo: "25.000,00",
                totalPloa: "25.000,00",
                diferenca: {
                  TotalReal: "25.000,00",
                  porcentagem: "25",
                },
              },
            },
            {
              titulo: "Outras Receitas Correntes",
              despesaInfos: {
                totalLdo: "25.000,00",
                totalPloa: "25.000,00",
                diferenca: {
                  TotalReal: "25.000,00",
                  porcentagem: "25",
                },
              },
            },
          ],
        },
        {
          receitaInfos: {
            nameReceita: "Reserva de Contingência ( III )",
            totalLdo: "25.000,00",
            totalPloa: "25.000,00",
            diferenca: {
              TotalReal: "25.000,00",
              porcentagem: "25",
            },
          },
          despesas: [
            {
              titulo: "Aplicação Financeira ",
              despesaInfos: {
                totalLdo: "25.000,00",
                totalPloa: "25.000,00",
                diferenca: {
                  TotalReal: "25.000,00",
                  porcentagem: "25",
                },
              },
            },
            {
              titulo: "Operações de Crédito ",
              despesaInfos: {
                totalLdo: "25.000,00",
                totalPloa: "25.000,00",
                diferenca: {
                  TotalReal: "25.000,00",
                  porcentagem: "25",
                },
              },
            },
            {
              titulo: "Alienação de Bens ",
              despesaInfos: {
                totalLdo: "25.000,00",
                totalPloa: "25.000,00",
                diferenca: {
                  TotalReal: "25.000,00",
                  porcentagem: "25",
                },
              },
            },
            {
              titulo: "Amortização de Empréstimos",
              despesaInfos: {
                totalLdo: "25.000,00",
                totalPloa: "25.000,00",
                diferenca: {
                  TotalReal: "25.000,00",
                  porcentagem: "25",
                },
              },
            },
            {
              titulo: "Outras Receitas de Capital",
              despesaInfos: {
                totalLdo: "25.000,00",
                totalPloa: "25.000,00",
                diferenca: {
                  TotalReal: "25.000,00",
                  porcentagem: "25",
                },
              },
            },
          ],
        },
        {
          receitaInfos: {
            nameReceita: "Despesas Financeiras ( V )",
            totalLdo: "25.000,00",
            totalPloa: "25.000,00",
            diferenca: {
              TotalReal: "25.000,00",
              porcentagem: "25",
            },
          },
          despesas: [
            {
              titulo: "Aplicação Financeira ",
              despesaInfos: {
                totalLdo: "25.000,00",
                totalPloa: "25.000,00",
                diferenca: {
                  TotalReal: "25.000,00",
                  porcentagem: "25",
                },
              },
            },
            {
              titulo: "Operações de Crédito ",
              despesaInfos: {
                totalLdo: "25.000,00",
                totalPloa: "25.000,00",
                diferenca: {
                  TotalReal: "25.000,00",
                  porcentagem: "25",
                },
              },
            },
            {
              titulo: "Alienação de Bens ",
              despesaInfos: {
                totalLdo: "25.000,00",
                totalPloa: "25.000,00",
                diferenca: {
                  TotalReal: "25.000,00",
                  porcentagem: "25",
                },
              },
            },
            {
              titulo: "Amortização de Empréstimos",
              despesaInfos: {
                totalLdo: "25.000,00",
                totalPloa: "25.000,00",
                diferenca: {
                  TotalReal: "25.000,00",
                  porcentagem: "25",
                },
              },
            },
            {
              titulo: "Outras Receitas de Capital",
              despesaInfos: {
                totalLdo: "25.000,00",
                totalPloa: "25.000,00",
                diferenca: {
                  TotalReal: "25.000,00",
                  porcentagem: "25",
                },
              },
            },
          ],
        },
      ],
    },
  },
];

export const RenderPdfDespesaCompatibilidade = (
  dados,
  opcoes,
  currentClient,
  userAuthData,
  dataClientInfos,
  currentYear,
  customData
) => {
  const styledCol = [
    { flexBasis: "100%", flexShrink: 1, flexGrow: 1 },
    {
      flexBasis: "150px",
      flexShrink: 1,
      flexGrow: 1,
      textAlign: "center",
    },
    {
      flexBasis: "150px",
      flexShrink: 1,
      flexGrow: 1,
      textAlign: "center",
    },
    {
      flexBasis: "250px",
      flexShrink: 1,
      flexGrow: 1,
      textAlign: "center",
    },
  ];

  const renderHeader = (headerName) => {
    return (
      <>
        <View style={[stylesPdf.coluna, styledCol[0]]}>
          <View style={[stylesPdf.divTituloColuna, { paddingHorizontal: 8 }]}>
            <Text style={stylesPdf.textTituloColuna}>{headerName}</Text>
          </View>
        </View>
        <View style={[stylesPdf.coluna, styledCol[1]]}>
          <View style={[stylesPdf.divTituloColuna, { paddingHorizontal: 8 }]}>
            <Text style={stylesPdf.textTituloColuna}>LDO</Text>
          </View>
        </View>
        <View style={[stylesPdf.coluna, styledCol[2]]}>
          <View style={[stylesPdf.divTituloColuna, { paddingHorizontal: 8 }]}>
            <Text style={stylesPdf.textTituloColuna}>PLOA</Text>
          </View>
        </View>
        <View style={[stylesPdf.coluna, styledCol[3]]}>
          <View style={[stylesPdf.divTituloColuna, { paddingHorizontal: 8 }]}>
            <View>
              <Text
                style={[
                  stylesPdf.textTituloColuna,
                  { borderBottomColor: "#FFF", borderBottomWidth: 1 },
                ]}
              >
                DIFERENÇA
              </Text>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  paddingHorizontal: 2,
                }}
              >
                <Text style={stylesPdf.textTituloColuna}>R$</Text>
                <Text style={stylesPdf.textTituloColuna}>%</Text>
              </View>
            </View>
          </View>
        </View>
      </>
    );
  };

  const renderOrcamentos = (orcamento) => {
    return (
      <View
        style={[
          stylesPdf.colunas,
          {
            gap: 0,
          },
        ]}
      >
        <PdfInfos
          pdfInfo={orcamento.titulo}
          styledCol={[
            styledCol[0],
            {
              padding: "2px 0",
            },
          ]}
        />
        <PdfInfos
          pdfInfo={orcamento.despesaInfos.totalLdo}
          styledCol={[styledCol[1], { padding: "2px 0", textAlign: "right" }]}
        />
        <PdfInfos
          pdfInfo={orcamento.despesaInfos.totalPloa}
          styledCol={[
            styledCol[2],
            {
              padding: "2px 0",
              textAlign: "right",
            },
          ]}
        />
        <View
          style={[
            styledCol[3],
            {
              flexDirection: "row",
              justifyContent: "space-between",
              paddingHorizontal: 0,
              paddingVertical: 2,
            },
          ]}
          wrap={false}
        >
          <Text style={stylesPdf.textInfosRelatorio}>
            {orcamento.despesaInfos.diferenca.TotalReal}
          </Text>
          <Text style={stylesPdf.textInfosRelatorio}>
            {orcamento.despesaInfos.diferenca.porcentagem}
          </Text>
        </View>
      </View>
    );
  };

  const renderOrcamentoSection = (orcamentoInfo) => {
    return (
      <View
        style={[
          stylesPdf.colunas,
          {
            gap: 0,
            backgroundColor: "#a6a6a6",
            fontWeight: "bold",
          },
        ]}
      >
        <PdfInfos
          pdfInfo={orcamentoInfo.nameReceita}
          styledCol={[
            styledCol[0],
            {
              padding: "2px 0",
            },
          ]}
        />

        <PdfInfos
          pdfInfo={orcamentoInfo.totalLdo}
          styledCol={[styledCol[1], { padding: "2px 0", textAlign: "right" }]}
        />
        <PdfInfos
          pdfInfo={orcamentoInfo.totalPloa}
          styledCol={[
            styledCol[2],
            {
              padding: "2px 0",
              textAlign: "right",
            },
          ]}
        />
        <View
          style={[
            styledCol[3],
            {
              flexDirection: "row",
              justifyContent: "space-between",
              paddingHorizontal: 0,
              paddingVertical: 2,
            },
          ]}
          wrap={false}
        >
          <Text style={stylesPdf.textInfosRelatorio}>
            {orcamentoInfo.diferenca.TotalReal}
          </Text>
          <Text style={stylesPdf.textInfosRelatorio}>
            {orcamentoInfo.diferenca.porcentagem}
          </Text>
        </View>
      </View>
    );
  };

  const renderTotal = (typeReceitaTotal) => {
    return (
      <>
        <View
          wrap={false}
          style={[
            stylesPdf.divInferiorColuna,
            {
              fontWeight: "bold",
              fontSize: 10,
              textTransform: "uppercase",
              color: "white",
              flexDirection: "row",
              justifyContent: "space-between",
              marginBottom: 10,
              margin: 0,
              paddingHorizontal: 0,
              paddingLeft: 10,
            },
          ]}
        >
          <Text>{`receita total${typeReceitaTotal}`}</Text>
          <View style={{ flexDirection: "row" }}>
            <Text
              style={{
                width: 77,
                textAlign: "right",
                paddingRight: 8,
              }}
            >
              {FormatValueToLocaleString(10000)}
            </Text>
            <Text
              style={{
                width: 77,
                textAlign: "right",
                paddingRight: 8,
              }}
            >
              {FormatValueToLocaleString(10000)}
            </Text>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                width: 127,
              }}
            >
              <Text
                style={{
                  textAlign: "right",
                  paddingRight: 8,
                }}
              >
                {FormatValueToLocaleString(10000)}
              </Text>
              <Text
                style={{
                  textAlign: "right",
                  paddingRight: 8,
                }}
              >
                {FormatValueToLocaleString(10000)}
              </Text>
            </View>
          </View>
        </View>
      </>
    );
  };

  return (
    <Page orientation="portrait" size={"A4"} style={[stylesPdf.page]}>
      <PdfHeader
        currentClient={currentClient}
        nameRelatorio={"Orçamentos com os Objetivos e Metas"}
        nameProjeto={"Demonstrativo da Compatibilidade da Programação dos"}
        anoRelatorio={currentYear}
        plusText={"vazio"}
        dataClientInfos={dataClientInfos}
        customData={customData}
      />
      <View
        fixed
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          fontWeight: "bold",
          fontSize: "9px",
          padding: "0 4px",
        }}
      >
        <Text>LRF art. 5º, Inciso I</Text>
        <Text>R$ 1,00</Text>
      </View>
      {/* <View style={[stylesPdf.colunas]} fixed>
        {renderHeader("RECEITA")}
      </View> */}
      <View style={stylesPdf.divRelatorio}>
        <View>
          {mockData.map((unidade, unidadeIndex) => (
            <React.Fragment key={unidadeIndex}>
              <View style={[stylesPdf.colunas, { backgroundColor: "#515c70" }]}>
                {renderHeader("RECEITA")}
              </View>
              {unidade.infoUnidade.receitas.map((receita, receitaIndex) => (
                <React.Fragment key={receitaIndex}>
                  {renderOrcamentoSection(receita.receitaInfos)}
                  {receita.despesas.map((despesa, despesaIndex) => (
                    <React.Fragment key={despesaIndex}>
                      {renderOrcamentos(despesa)}
                    </React.Fragment>
                  ))}
                  {receita.receitaInfos.nameReceita ===
                    "Receitas de Capital ( II )" &&
                    renderTotal("( III ) = ( I ) + ( II )")}
                  {receita.receitaInfos.nameReceita ===
                    "Receitas Financeiras ( IV )" &&
                    renderTotal("( V ) = ( III ) + ( IV )")}
                </React.Fragment>
              ))}
              <View style={[stylesPdf.colunas, { backgroundColor: "#515c70" }]}>
                {renderHeader("DESPESA")}
              </View>
              {unidade.infoUnidade.despesas.map((despesas, receitaIndex) => (
                <React.Fragment key={receitaIndex}>
                  {renderOrcamentoSection(despesas.receitaInfos)}
                  {despesas.despesas.map((despesa, despesaIndex) => (
                    <React.Fragment key={despesaIndex}>
                      {renderOrcamentos(despesa)}
                    </React.Fragment>
                  ))}
                  {despesas.receitaInfos.nameReceita ===
                    "Reserva de Contingência ( III )" &&
                    renderTotal("( IV ) = ( I ) + ( II ) + ( III )")}
                  {despesas.receitaInfos.nameReceita ===
                    "Despesas Financeiras ( V )" &&
                    renderTotal("(VI ) = ( IV ) - ( V )")}
                </React.Fragment>
              ))}
              <View style={{ marginBottom: 5 }} />
            </React.Fragment>
          ))}
        </View>
      </View>
      <PdfRodape currentClient={currentClient} userAuthData={userAuthData} />
    </Page>
  );
};
