import api from "../../../../../../utils/api";
import { getUrl } from "../getUrlConsolidado";

export const getDespesaDemonstrativoAcao = async (
  clienteId,
  entidadesGestoras,
  ano = 2024
) => {
  const url = getUrl(clienteId, entidadesGestoras);

  try {
    const response = await api.get(
      `qdd/relatorio/porAcao/${url.idsUrl}/${ano}?${url.filterConsolidado}`
    );
    return formattedData(response.data);
  } catch (error) {
    console.error("Erro na requisição:", error);
    return {
      error: "error",
      name: "Demonstrativo da Despesa por Ações Governamentais",
    };
  }
};



const formattedData = (acoes) => {
  const filterAcoes = acoes.filter(
    (acao) => Object.keys(acao.Acoes).length > 0
  );

  filterAcoes.sort((firstAcao, secondAcao) => {
    const acaoA = firstAcao.tipoAcao[0];
    const acaoB = secondAcao.tipoAcao[0];
    return acaoA - acaoB;
  });

  const totalGeral = filterAcoes.reduce((acc, acao) => {
    return (acc += acao.total);
  }, 0);

  return {
    filterAcoes,
    total: totalGeral,
  };
};
