import React from "react";
import { Page, View, Text } from "@react-pdf/renderer";

import { stylesPdf } from "../../../../../components/ComponenteDeRelatorio/Pdf/StyledPdfRelatorio";
import { PdfHeader } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfHeader";
import { PdfColunas } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfColunas";
import { PdfInfos } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfInfos";
import { PdfRodape } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfRodape";
import { FormatValueToLocaleString } from "../../../../../utils/FormatacaoDeDados/FormatValueToLocaleString";

export const RenderPdfReceitaQdr = (
  dados,
  opcoes,
  currentClient,
  userAuthData,
  dataClientInfos,
  currentYear,
  customData
) => {
  const optionReceitaQdr = opcoes.find(
    (option) => option.opcao === "Quadro Detalhado da Receita - QDR"
  );

  const codigosSectionRelatorio = ["1.0.0.0.00.0.0", "2.0.0.0.00.0.0"];
  const colNames = ["código", "especificações", "total"];
  const styledCol = [
    { flexBasis: "105px", flexShrink: 1, flexGrow: 1, textAlign: "center" },
    { flexBasis: "100%", flexShrink: 1, flexGrow: 1, textAlign: "center" },
    {
      flexBasis: "110px",
      flexShrink: 1,
      flexGrow: 1,
      textAlign: "center",
    },
  ];

  const normalizeString = (str) => {
    return str.endsWith(".") ? str.slice(0, -1) : str;
  };

  const renderReceitas = (natureza, key) => {
    const naturezaSection = codigosSectionRelatorio.includes(natureza.codigo);
    const fonteRecurso =
      natureza.codigo.length < codigosSectionRelatorio[0].length;

    return (
      <React.Fragment key={key}>
        <View
          style={[
            stylesPdf.colunas,
            {
              gap: 0,
              backgroundColor: naturezaSection ? "#a6a6a6" : "transparent",
              fontWeight: naturezaSection | fonteRecurso ? "bold" : "normal",
            },
          ]}
        >
          {optionReceitaQdr?.checkBoxes[0].selected && (
            <PdfInfos
              pdfInfo={natureza.codigo || ""}
              styledCol={[
                styledCol[0],
                {
                  padding: "2px 0",
                  textAlign: "right",
                },
              ]}
            />
          )}
          {optionReceitaQdr?.checkBoxes[1].selected && (
            <PdfInfos
              pdfInfo={normalizeString(natureza.especificacao) || ""}
              styledCol={[
                styledCol[1],
                { padding: "2px 0", textAlign: "left" },
              ]}
            />
          )}
          {optionReceitaQdr?.checkBoxes[2].selected && (
            <PdfInfos
              pdfInfo={
                natureza.valor
                  ? FormatValueToLocaleString(parseFloat(natureza.valor))
                  : ""
              }
              styledCol={[
                styledCol[2],
                {
                  padding: "2px 0",
                  textAlign: "right",
                },
              ]}
            />
          )}
        </View>
      </React.Fragment>
    );
  };

  return (
    <Page orientation="portrait" size={"A4"} style={stylesPdf.page}>
      <PdfHeader
        currentClient={currentClient}
        plusText={"vazio"}
        nameRelatorio={"Quadro Detalhado da Receita - QDR"}
        anoRelatorio={currentYear}
        dataClientInfos={dataClientInfos}
        customData={customData}
      />
      <View
        fixed
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          fontWeight: "bold",
          fontSize: "9px",
          padding: "0 4px",
        }}
      >
        <Text>Lei Federal 4.320/64</Text>
        <Text>R$ 1,00</Text>
      </View>
      <View style={[stylesPdf.colunas]} fixed>
        {optionReceitaQdr?.checkBoxes?.map(
          (each, index) =>
            each.selected && (
              <PdfColunas
                key={index}
                colName={colNames[index].toUpperCase()}
                styledCol={styledCol[index]}
                hideBar
              />
            )
        )}
      </View>
      <View style={stylesPdf.divRelatorio}>
        <View style={{ margin: "0 -3px" }}>
          {dados.data.naturezas.map((natureza, naturezaIndex) =>
            renderReceitas(natureza, naturezaIndex)
          )}
          <View style={{ marginVertical: 5 }} />
          {dados.data.naturezasDeducao.length > 0 && (
            <>
              <View
                wrap={false}
                style={[
                  stylesPdf.barTitleInfos,
                  {
                    gap: 0,
                    marginBottom: 10,
                    fontSize: 9,
                    backgroundColor: "#a6a6a6",
                    justifyContent: "space-between",
                    paddingVertical: 2,
                  },
                ]}
              >
                <View>
                  <Text style={[stylesPdf.textInfosRelatorio]}>
                    Contribuição para Formação do FUNDEB - DEDUÇÃO
                  </Text>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    flex: 1,
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <Text
                    style={[
                      stylesPdf.textInfosRelatorio,
                      {
                        color: "#000",
                        paddingRight: 8,
                      },
                    ]}
                  >
                    {dados.totalReceitasDeducao
                      ? FormatValueToLocaleString(dados.totalReceitasDeducao)
                      : ""}
                  </Text>
                </View>
              </View>
              {dados.data.naturezasDeducao.map((natureza, indexNatDeducao) =>
                renderReceitas(natureza, indexNatDeducao)
              )}
              <View style={{ marginVertical: 5 }} />
            </>
          )}

          <View
            wrap={false}
            style={[
              stylesPdf.divInferiorColuna,
              {
                fontWeight: "bold",
                fontSize: 10,
                textTransform: "uppercase",
                color: "white",
                flexDirection: "row",
                justifyContent: "space-between",
              },
            ]}
          >
            <Text>TOTAL</Text>
            <Text>{dados.sum}</Text>
          </View>
        </View>
      </View>
      <PdfRodape currentClient={currentClient} userAuthData={userAuthData} />
    </Page>
  );
};
