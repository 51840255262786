//import { calcularTotalReceitasComDeducao } from "../../../../LOA_QDR/RELATORIO_QDR/calcularReceitas";
import api from "../../../../../../utils/api";
import { getUrl } from "../getUrlConsolidado";

export const getDespesaSegCEconomica = async (clientes, ano = 2024,entidadesGestoras) => {

  const url = getUrl(clientes, entidadesGestoras);
  try {
    const response = await api.get(
      `/naturezaDaDespesa/relatorio/${url.idsUrl}/${ano}${url.filterConsolidado}`
    );
    return processAllData(response.data);
  } catch (error) {
    console.error("Erro na requisição:", error);
    return {
      error: "error",
      name: "Anexo II - Despesa Segundo as Categorias Econômicas",
    };
  }
};

const processAllData = (naturezaDaDespesa) => {
  let sumValues = 0;
  const processItem = (item, sum = "sum") => {
    const seccoes = item.codigo
      .split(".")
      .filter((secao) => parseInt(secao) !== 0);
    let desdobramento = null,
      fonte = null,
      catEcon = null;

    if (seccoes.length === 1) {
      catEcon = item.valor;
      if (sum === "reduce") {
        sumValues -= parseFloat(item.valor);
      } else {
        sumValues += parseFloat(item.valor);
      }
    } else if (seccoes.length === 2) {
      fonte = item.valor;
    } else if (seccoes.length > 2) {
      desdobramento = item.valor;
    }

    const resultado = [
      {
        codigo: item.codigo,
        especificacao: item.nome,
        desdobramento: desdobramento,
        fonte: fonte,
        catEcon: catEcon,
        valor: item.valor,
      },
    ];

    if (item.fontes && item.fontes.length > 0) {
      const recursos = [];
      item.fontes.forEach((recurso) => {
        const found = recursos.find((r) => r.codigo === recurso.conta);

        const totalFonte = recurso.totalFiscal + recurso.totalSeguridade;

        if (found) {
          found.desdobramento += totalFonte;
        } else {
          recursos.push({
            codigo: recurso.conta,
            especificacao: recurso.tituloFonte,
            desdobramento: totalFonte,
            fonte: null,
            catEcon: null,
          });
        }
      });
      const filteredRec = recursos.sort((a, b) => {
        if (a.codigo < b.codigo) return -1;
        if (a.codigo > b.codigo) return 1;
        return 0;
      });
      resultado.push(...filteredRec);
    }
    return resultado;
  };

  const getFilteredData = (userData) => {
    const data = userData.naturezaConsolidadas?.flatMap((item) =>
      processItem(item, "sum")
    );
    return data;
  };
  const dadosFiltrados = [].concat(
    ...Object.entries(naturezaDaDespesa?.contas?.relatorios).map(
      ([_, userData]) => {
        return getFilteredData(userData);
      }
    )
  );

  console.log(dadosFiltrados);
  

  const somaFormatada = sumValues;
  return {
    data: dadosFiltrados,
    sum: somaFormatada,
  };
};
