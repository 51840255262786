import api from "../../../../../../utils/api";
import { FormatValueToLocaleString } from "../../../../../../utils/FormatacaoDeDados/FormatValueToLocaleString";

import { calcularTotalUnidade } from "../../../../LOA_QDD/Relatorio/calcularTotalUnidade";
import { getUrl } from "../getUrlConsolidado";

export const getDespesaQdd = async (clientes, ano = 2024, entidadesGestoras) => {
  const url = getUrl(clientes, entidadesGestoras);
  try {
    const response = await api.get(`qdd/relatorio-qdd/${url.idsUrl}/${ano}${url.filterConsolidado}`);
    console.log("response.data", response.data);
    return processAllData(response.data);
  } catch (error) {
    console.error("Erro na requisição:", error);
    return {
      error: "error",
      name: "Quadro Detalhado da Despesa - QDD por Elemento de Despesa",
    };
  }
};

export const processAllData = (dataQddRelatorioCatEconOrgao) => {
  dataQddRelatorioCatEconOrgao.sort((unidadeA, unidadeB) => {
    const codigoUnidadeA = parseFloat(
      unidadeA.codigoUnidadeOrcamentaria.replace(".", "")
    );
    const codigoUnidadeB = parseFloat(
      unidadeB.codigoUnidadeOrcamentaria.replace(".", "")
    );

    return codigoUnidadeA - codigoUnidadeB;
  });

  dataQddRelatorioCatEconOrgao.forEach((unidade) => {
    unidade.acoes.sort((acaoA, acaoB) => parseInt(acaoA.nAcao) - parseInt(acaoB.nAcao));
  });

  const totalUnidades = FormatValueToLocaleString(
    calcularTotalUnidade(dataQddRelatorioCatEconOrgao)
  );
  console.log(dataQddRelatorioCatEconOrgao);
  

  return {
    data: dataQddRelatorioCatEconOrgao,
    sum: totalUnidades,
  };
};
