import React, { useState } from "react";
import { CCol, ContainerToModal } from "../../../../components/Grid/CCol";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import { Row } from "react-bootstrap";
import styled from "styled-components";
import ReceitaPrevista from "./ReceitaPrevista";
import Saldos from "../../Components/Saldos";
import RowSelecaoAbasInferior from "../../../../components/Grid/RowSelecaoAbasInferior";

export const RowInfosAcao = styled(Row)`
  align-content: baseline;
  min-height: ${({ minHeight }) => minHeight || "80px"};
  margin: 0px;
  border-radius: 10px;
  background-color: #ebeaea;
  padding: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  resize: vertical;
  border-radius: 5px;
  border: 1px solid #ccc;
  position: relative;

  span {
    color: #73767e;
  }

  p {
    margin: 0px;
    margin-left: 10px;
    color: #515c70;
    font-weight: 500;
    font-size: 15.5px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  span + p {
    display: inline;
  }
`;

const QDR = () => {
  const [abaSelecionada, setAbaSelecionada] = useState({
    nomeAba: "Saldos",
    numeroAba: 1,
  });

  return (
    <ContainerToModal
      id={"pags_responsividade_padding_geral"}
      fluid
      className={"container_conteudo conteudo_pagina"}
    >
      <HeaderCadastros
        NomePaginaTitulo={"LOA"}
        PaginaSubtitulo={"QDR - Quadro Detalhado da Receita"}
        RowTitle={"QDR - Quadro Detalhado da Receita"}
        BotaoSaveAtivo={false}
        BotaoNovoAtivo={false}
      />

      <Row className="row_form mt-1">
        <CCol>
          <FloatingLabelInput
            tipo="input_select"
            placeholder="Unidade Orçamentária Gestora"
            options={[
              {
                label: "Unidade Orçamentária Gestora",
                value: "Unidade Orçamentária Gestora0",
              },
              {
                label: "Unidade Orçamentária Gestora",
                value: "Unidade Orçamentária Gestora1",
              },
            ]}
            new
          />
        </CCol>
      </Row>
      <Row className="mt-3">
        <CCol
          className="d-flex align-items-center p-2 header_body_conteudo"
          md={12}
        >
          <span
            style={{
              fontSize: "16.5px",
              fontWeight: "600",
              marginLeft: "10px",
            }}
          >
            Receita Prevista
          </span>
        </CCol>
      </Row>
      <ReceitaPrevista />
      <RowSelecaoAbasInferior
        abas={["Procedimentos Contábeis", "Saldos"]}
        abaSelecionada={abaSelecionada}
        setAbaSelecionada={setAbaSelecionada}
        style={{ marginTop: "-15px" }}
      />
      {abaSelecionada.numeroAba === 1 && (
        <>
          <Row className="mt-3">
            <CCol
              className="d-flex align-items-center p-2 header_body_conteudo"
              md={12}
            >
              <span
                style={{
                  fontSize: "16.5px",
                  fontWeight: "600",
                  marginLeft: "10px",
                }}
              >
                Saldos
              </span>
            </CCol>
          </Row>
          <Saldos
            data={{
              Previsto: "R$ 20.000,00",
              Arrecadada: "R$ 20.000,00",
              Anulada: "R$ 20.000,00",
              "Saldo Atualizado": "R$ 20.000,00",
            }}
          />
        </>
      )}
      <div style={{ minHeight: "40px" }}> </div>
    </ContainerToModal>
  );
};

export default QDR;
