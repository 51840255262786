import { FormatValueToLocaleString } from "../../../../../../utils/FormatacaoDeDados/FormatValueToLocaleString";
import { calcularTotalReceitas } from "../../../../LOA_QDR/RELATORIO_QDR/calcularReceitas";
import api from "../../../../../../utils/api";
import { getUrl } from "../getUrlConsolidado";

export const getReceitasCatEconOrgao = async (
  clientes,
  entidadesGestoras,
  ano = 2024
) => {
  const url = getUrl(clientes, entidadesGestoras);
  try {
    const response = await api.get(
      `qdr/relatorio/${url.idsUrl}/${ano}?${url.filterConsolidado.replace(
        /\?/g,
        ""
      )}&separarPorUnidade=1`
    );
    console.log(clientes);
    console.log("response.data", response.data);
    // return processAllData(response.data, entidades);
  } catch (error) {
    console.error("Erro na requisição:", error);
    return {
      error: "error",
      name: "Anexo II - Receita Segundo as Categorias Econômicas por Órgão",
    };
  }
};

const orderFonts = (naturezas) => {
  return naturezas.map((natureza) => {
    const { qdr } = natureza;

    if (qdr && qdr !== "vazio" && qdr.fontesDeRecursos) {
      qdr.fontesDeRecursos.sort((a, b) => a.conta.localeCompare(b.conta));
    }

    return natureza;
  });
};

export const processAllData = (dataQdrRelatorioCatEconOrgao, entidades) => {
  console.log(dataQdrRelatorioCatEconOrgao);

  const codigosSectionRelatorio = ["1.0.0.0.00.0.0", "2.0.0.0.00.0.0"];
  const fonteComDeducao = 3;
  const fonteSemDeducao = 1;

  const processItem = (item, deducao) => {
    const seccoes = item.nr.split(".").filter((secao) => parseInt(secao) !== 0);
    let desdobramento = null,
      fonte = null,
      catEcon = null;

    if (seccoes.length === 1) {
      catEcon = item.valor;
    } else if (seccoes.length === 2) {
      fonte = item.valor;
    } else if (seccoes.length > 2) {
      desdobramento = item.valor;
    }

    const resultado = [
      {
        codigo: item.nr,
        especificacao: item.especificacao,
        desdobramento: desdobramento,
        fonte: fonte,
        catEcon: catEcon,
        valor: item.valor,
      },
    ];

    if (
      item.qdr &&
      item.qdr.fontesDeRecursos &&
      item.qdr.fontesDeRecursos.length > 0
    ) {
      const recursos = item.qdr.fontesDeRecursos
        .map((recurso) => {
          if (recurso.tipo === deducao) {
            return {
              codigo: recurso.conta,
              especificacao: recurso.titulo,
              desdobramento: recurso.valorPrevisto,
              fonte: null,
              catEcon: null,
              valor: recurso.valorPrevisto,
            };
          }
          return null;
        })
        .filter((recurso) => recurso !== null);
      resultado.push(...recursos);
    }

    return resultado;
  };

  const dadosFiltrados = Object.keys(dataQdrRelatorioCatEconOrgao)
    .map((key) => {
      const clienteData = dataQdrRelatorioCatEconOrgao[key];

      if (!clienteData.naturezas.length && !clienteData.naturezasDeducao.length)
        return null;

      const receitas = {
        naturezas: clienteData.naturezas,
        naturezasDeducao: clienteData.naturezasDeducao,
      };

      return {
        infoCliente: {
          codigo: clienteData.infoCliente[0].id,
          especificacao: clienteData.infoCliente[0].pessoaJuridica.nomeFantasia,
          tipoNaturezaJuridica: clienteData.infoCliente[0].tipoNaturezaJuridica,
          total: calcularTotalReceitas(receitas, codigosSectionRelatorio),
        },
        naturezas: orderFonts(clienteData.naturezas).flatMap((natureza) =>
          processItem(natureza, fonteSemDeducao)
        ),
        naturezasDeducao: orderFonts(clienteData.naturezasDeducao).flatMap(
          (natureza) => processItem(natureza, fonteComDeducao)
        ),
      };
    })
    .filter((data) => data !== null);
  console.log(dadosFiltrados);

  dadosFiltrados.sort((a, b) => {
    const tipoA = parseInt(a.infoCliente.tipoNaturezaJuridica[0]);
    const tipoB = parseInt(b.infoCliente.tipoNaturezaJuridica[0]);
    return tipoA - tipoB;
  });
  const totalReceitas = Object.keys(dataQdrRelatorioCatEconOrgao).reduce(
    (acc, key) => {
      const clienteData = dataQdrRelatorioCatEconOrgao[key];
      const receitas = {
        naturezas: clienteData.naturezas,
        naturezasDeducao: clienteData.naturezasDeducao,
      };

      const total = calcularTotalReceitas(receitas, codigosSectionRelatorio);

      return acc + total;
    },
    0
  );

  console.log(entidades);

  const dataFormated = dadosFiltrados.filter((cliente) =>
    entidades.ids.includes(cliente.infoCliente.codigo)
  );

  console.log(dataFormated);

  return {
    data: dataFormated,
    sum: FormatValueToLocaleString(totalReceitas),
  };
};
