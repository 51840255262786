import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Container } from "react-bootstrap";
import HeaderCadastros from "../../../components/HeaderCadastros/HeaderCadastros";
import { CCol, RowForm } from "../../../components/Grid/CCol";
import InputSearch from "../../../components/Inputs/InputSearch";
import styled from "styled-components";
import {
  chavesDoObjetoExpandido,
  chavesDoObjetoMinimizado,
  titulosEstendido,
  titulosMinimizado,
} from "./utils/dados";
import api from "../../../utils/api";
import { CurrentClientContext } from "../../../contexts/CurrentClientContext";
import { ReturnDataAtual } from "../../../utils/Gerais/ReturnDataAtual";
import {
  ReturnColorContaPCASP,
  ReturnColorContaPCASPAlternative,
} from "../../../utils/Gerais/ReturnColorContaPCASP";
import { FormatValueToLocaleString } from "../../../utils/FormatacaoDeDados/FormatValueToLocaleString";
import LoadingComponent from "../../../components/AssetsComponents.jsx/LoadingComponent";

const CheckBoxContainer = styled.div`
  display: flex;
  align-items: center;

  input {
    flex-shrink: 0;
    width: 15px;
    height: 15px;
    margin-right: 5px;
    accent-color: green;
  }

  label {
    color: #515c70;
    font-size: 14px;
    font-weight: 500;
  }
`;

const Titles = styled.div`
  padding: 0px 10px;
  display: flex;
  width: ${({ $extendido }) => ($extendido ? "3180px" : "100%")};
  overflow-y: hidden;

  h6 {
    flex-basis: 10rem;
    font-size: 12px;
    color: #515c70;
    font-weight: 600;
    margin: 0;
    padding: 0 5px;
  }

  h6.grow {
    flex-grow: 1;
    flex-basis: 20rem;
  }
`;

const ArrowDiv = styled.div`
  flex-basis: 1rem;
`;

const TableContent = styled.div`
  width: ${({ $extendido }) => ($extendido ? "3180px" : "100%")};
`;

const TableRow = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 5px;
  background-color: ${({ $bgColor }) => ($bgColor ? $bgColor : null)};
  margin-bottom: ${({ $marginBottom }) =>
    $marginBottom ? $marginBottom : null};
  border-radius: ${({ $borderRadius }) =>
    $borderRadius ? $borderRadius : "0px"};

  p {
    flex-basis: 10rem;
    font-size: 14px;
    color: #515c70;
    font-weight: 600;
    padding: 0 5px;
    margin: 0;
  }

  p.grow {
    flex-grow: 1;
    flex-basis: 20rem;
  }
`;

const ColTabela = styled(CCol)`
  padding-right: 0px;
  overflow-x: auto;
  overflow-y: auto;
  max-height: 550px;

  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const CollapseDiv = styled.div`
  max-height: ${({ $expandida }) => ($expandida ? "auto" : "0px")};
  overflow-y: hidden;
`;

const ConsultaPCASP = () => {
  //states
  const [titulosDaTabela, setTitulosDaTabela] = useState(titulosEstendido);
  const [chavesDoObjeto, setChavesDoObjeto] = useState(chavesDoObjetoExpandido);
  const [contasPcasp, setContasPcasp] = useState([]);
  const [indexContaExpandida, setIndexContaExpandida] = useState(-1);
  const [loading, setLoading] = useState(true);
  const [pcaspExtendido, setPcaspExtendido] = useState(true);
  const [apenasValorizaveis, setApenasValorizaveis] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [contasFiltradas, setContasFiltradas] = useState([]);

  //contexts
  const { currentClient } = useContext(CurrentClientContext);

  //consts
  const contasPai = [1, 2, 3, 4, 5, 6, 7, 8];
  const ano = ReturnDataAtual().split("/")[2];

  //refs
  const titulosRef = useRef(null);
  const tabelaRef = useRef(null);

  const fetchPcasp = useCallback(async () => {
    setLoading(true);

    try {
      const response = await api.get(
        `pcasp/${currentClient.clienteId}/${ano}/todos?limit=10000`
      );
      console.log(response.data);
      const contas = response.data.pcasp;
      console.log(contas);
      setContasPcasp(contas);
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  }, [currentClient.clienteId, ano]);

  useEffect(() => {
    fetchPcasp();
  }, [fetchPcasp]);

  useEffect(() => {
    if (pcaspExtendido) {
      setTitulosDaTabela(titulosEstendido);
      setChavesDoObjeto(chavesDoObjetoExpandido);
    } else {
      setTitulosDaTabela(titulosMinimizado);
      setChavesDoObjeto(chavesDoObjetoMinimizado);
    }
  }, [pcaspExtendido]);

  const handleClickRow = (index) => {
    if (indexContaExpandida === index) {
      setIndexContaExpandida(-1);
    } else {
      setIndexContaExpandida(index);
    }
  };

  const trataValorDoItem = (valor) => {
    if (!valor) {
      return "-";
    }

    if (valor && typeof valor === "number") {
      return FormatValueToLocaleString(valor);
    }

    return valor;
  };

  const handleSearch = (e) => {
    setSearchValue(e.target.value);

    if (e.target.value === "") {
      setContasFiltradas([]);
      return;
    }

    console.log(e.target.value);

    const cleanedValue = e.target.value.replace(/\./g, "").toLowerCase();

    const arrayFiltrado = contasPcasp.filter(
      (item) =>
        item.conta.replace(/\./g, "").includes(cleanedValue) ||
        item.titulo.toLowerCase().includes(cleanedValue)
    );

    console.log(arrayFiltrado);

    setContasFiltradas(arrayFiltrado);
  };

  return (
    <Container
      id="pags_responsividade_padding_geral"
      fluid
      className="container_conteudo conteudo_pagina"
    >
      <HeaderCadastros
        NomePaginaTitulo={"PCASP"}
        PaginaSubtitulo={"Consulta"}
        RowTitle={"Planos de Contas Aplicado ao Setor Público"}
        ButtonSaveFunction={() => {
          setTitulosDaTabela((prev) => prev);
          setChavesDoObjeto((prev) => prev);
        }}
      />

      <RowForm className="mt-1">
        {/* <button onClick={filterPcasp}>Teste filtro</button> */}
        <CCol
          style={{
            display: "flex",
            alignItems: "center",
            gap: "15px",
            flexWrap: "wrap",
          }}
        >
          <CheckBoxContainer>
            <input
              type="checkbox"
              checked={pcaspExtendido}
              onChange={(e) => setPcaspExtendido(e.target.checked)}
            />
            <label>PCASP Estendido</label>
          </CheckBoxContainer>
          <CheckBoxContainer>
            <input
              type="checkbox"
              checked={apenasValorizaveis}
              onChange={(e) => setApenasValorizaveis(e.target.checked)}
            />
            <label>Contas Valorizáveis</label>
          </CheckBoxContainer>
        </CCol>
        <CCol md={3}>
          <InputSearch onChange={handleSearch} value={searchValue} />
        </CCol>
      </RowForm>

      {loading && (
        <RowForm className="mt-1">
          <LoadingComponent />
        </RowForm>
      )}

      {!loading && (
        <>
          <RowForm className="mt-1">
            <CCol style={{ overflowX: "hidden" }} ref={titulosRef}>
              <Titles $extendido={pcaspExtendido}>
                <ArrowDiv></ArrowDiv>
                {titulosDaTabela.map((item, index) => (
                  <h6 key={index} className={item.expandido ? "grow" : ""}>
                    {item.titulo}
                  </h6>
                ))}
              </Titles>
            </CCol>
          </RowForm>

          {contasPcasp.length > 0 && contasFiltradas.length === 0 && (
            <RowForm style={{ marginTop: "-14px" }}>
              <ColTabela
                ref={tabelaRef}
                onScroll={() => {
                  titulosRef.current.scrollLeft = tabelaRef.current.scrollLeft;
                }}
              >
                <TableContent $extendido={pcaspExtendido}>
                  {contasPai
                    .filter((numeroInicial) => {
                      if (apenasValorizaveis) {
                        const conta = contasPcasp.find(
                          (item) => parseInt(item.conta[0]) === numeroInicial
                        );

                        return (
                          parseFloat(conta.saldoAnterior) > 0 ||
                          parseFloat(conta.debitoDaContaPcasp) > 0 ||
                          parseFloat(conta.creditoDaContaPcasp) > 0 ||
                          parseFloat(conta.saldoDaContaPcasp) > 0
                        );
                      }

                      return true;
                    })
                    .map((numero, index) => {
                      const itemPai = contasPcasp.find(
                        (item) => parseInt(item.conta[0]) === numero
                      );
                      const contasFilhas = contasPcasp.filter(
                        (item, index) =>
                          parseInt(item.conta[0]) === numero && index !== 0
                      );

                      return (
                        <React.Fragment key={index}>
                          <TableRow
                            onClick={() => handleClickRow(index)}
                            style={{ cursor: "pointer" }}
                            $borderRadius={
                              indexContaExpandida === index
                                ? "10px 10px 0px 0px"
                                : "10px"
                            }
                            $bgColor={ReturnColorContaPCASPAlternative(
                              itemPai?.conta[0]
                            )}
                            $marginBottom={
                              index === indexContaExpandida ? "0px" : "5px"
                            }
                          >
                            <ArrowDiv>{">"}</ArrowDiv>
                            {chavesDoObjeto.map((chave, indexChave) => (
                              <p
                                className={chave.expandido ? "grow" : ""}
                                key={indexChave}
                                style={
                                  chave.titulo === "contaCorrente"
                                    ? {
                                        flexBasis: "11rem",
                                        flexShrink: "0",
                                      }
                                    : null
                                }
                              >
                                {trataValorDoItem(itemPai[chave.titulo])}
                              </p>
                            ))}
                          </TableRow>
                          <CollapseDiv
                            $expandida={indexContaExpandida === index}
                          >
                            {contasFilhas
                              .filter((conta) => {
                                if (apenasValorizaveis) {
                                  return (
                                    parseFloat(conta.saldoAnterior) > 0 ||
                                    parseFloat(conta.debitoDaContaPcasp) > 0 ||
                                    parseFloat(conta.creditoDaContaPcasp) > 0 ||
                                    parseFloat(conta.saldoDaContaPcasp) > 0
                                  );
                                }

                                return conta;
                              })
                              .map(
                                (contaFilha, indexContaFilha, arrayFilha) => (
                                  <TableRow
                                    key={indexContaFilha}
                                    $bgColor={ReturnColorContaPCASP(
                                      contaFilha?.conta[0]
                                    )}
                                    $borderRadius={
                                      indexContaFilha === arrayFilha.length - 1
                                        ? "0px 0px 10px 10px "
                                        : "0px"
                                    }
                                    $marginBottom={
                                      indexContaFilha === arrayFilha.length - 1
                                        ? "5px"
                                        : "0px"
                                    }
                                  >
                                    <ArrowDiv></ArrowDiv>
                                    {chavesDoObjeto.map((chave, indexChave) => (
                                      <p
                                        className={
                                          chave.expandido ? "grow" : ""
                                        }
                                        key={indexChave}
                                        style={
                                          chave.titulo === "contaCorrente"
                                            ? {
                                                flexBasis: "11rem",
                                                flexShrink: "0",
                                              }
                                            : null
                                        }
                                      >
                                        {trataValorDoItem(
                                          contaFilha[chave.titulo]
                                        )}
                                      </p>
                                    ))}
                                  </TableRow>
                                )
                              )}
                          </CollapseDiv>
                        </React.Fragment>
                      );
                    })}
                </TableContent>
              </ColTabela>
            </RowForm>
          )}

          {contasFiltradas.length > 0 && (
            <RowForm style={{ marginTop: "-14px" }}>
              <ColTabela
                ref={tabelaRef}
                onScroll={() => {
                  titulosRef.current.scrollLeft = tabelaRef.current.scrollLeft;
                }}
              >
                <TableContent $extendido={pcaspExtendido}>
                  {contasFiltradas
                    .filter((item) => {
                      if (apenasValorizaveis) {
                        return (
                          parseFloat(item.saldoAnterior) > 0 ||
                          parseFloat(item.debitoDaContaPcasp) > 0 ||
                          parseFloat(item.creditoDaContaPcasp) > 0 ||
                          parseFloat(item.saldoDaContaPcasp) > 0
                        );
                      }

                      return true;
                    })
                    .map((item, index) => (
                      <TableRow
                        key={index}
                        $bgColor={ReturnColorContaPCASP(item.conta[0])}
                        $borderRadius="10px"
                        $marginBottom={
                          index === indexContaExpandida ? "0px" : "5px"
                        }
                      >
                        <ArrowDiv>{""}</ArrowDiv>
                        {chavesDoObjeto.map((chave, indexChave) => (
                          <p
                            className={chave.expandido ? "grow" : ""}
                            key={indexChave}
                            style={
                              chave.titulo === "contaCorrente"
                                ? {
                                    flexBasis: "11rem",
                                    flexShrink: "0",
                                  }
                                : null
                            }
                          >
                            {trataValorDoItem(item[chave.titulo])}
                          </p>
                        ))}
                      </TableRow>
                    ))}
                </TableContent>
              </ColTabela>
            </RowForm>
          )}
        </>
      )}

      <div className="p-3"></div>
    </Container>
  );
};

export default ConsultaPCASP;
